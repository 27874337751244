import { Box } from '@mui/material';
import { defaultColors } from '../../../../styles/variables';
import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';
import React, { useEffect, useRef } from 'react';
import { IContractTerms, IContractTermForLumpSum } from '../../../../models';
import { DbgLinkButton } from '../../../components/buttons/DbgLinkButton';
import { useApplicationContextState } from '../../../../contexts/ApplicationContext';
import { ValidationIssue } from '../../../../classes/ValidationIssue';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { NumberToCurrencyString } from '../../../../utilities/NumberUtility';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, DataGridColDef, DataGridRenderBodyCellParams } from '@dierbergs-markets/react-component-library';
import { LumpSumDataGridFields } from '../../../../models/enums';
import { toDataGridCellMessage } from '../../../../utilities/DataGridUtilities';
import { useContractShowErrorsStore } from '../../../../store';

interface IProps {
  contractTerms: IContractTerms;
  canModify: boolean;
  onTermEdit: (term: IContractTermForLumpSum) => void;
  errors?: ValidationIssue[];
}

const LumpSumsDataGrid = (props: IProps) => {
  const mounted = useRef(true);
  const { referenceData } = useApplicationContextState();

  const { showErrors } = useContractShowErrorsStore();

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  if (!referenceData) return <></>;

  const invokeTermEdit = (term: IContractTermForLumpSum): void => {
    props.canModify && props.onTermEdit(term);
  };

  const containDigitalRewardFee = !!props.contractTerms.contractTermsForLumpSum.find((a) => a.termTypeId === referenceData?.digitalRewardTermTypeId);

  const digitalRewardFeeColumns: DataGridColDef[] = [
    {
      field: LumpSumDataGridFields.DigitalRewardsRewardPointMultiplier,
      headerName: 'Reward Points Mutiplier',
      type: 'string',
      width: 180,
      filterable: false,
      sortable: false,
      columnBodyCss: styles.dataGrid.centerBodyCell,
      columnHeaderCss: {
        root: styles.dataGrid.centerHeaderCell,
      },
      renderBodyCellContent(params: DataGridRenderBodyCellParams<string, IContractTermForLumpSum>) {
        return params.row.digitalRewards?.rewardPointsMultiplier ? `${params.row.digitalRewards?.rewardPointsMultiplier}X` : '';
      },
    },
    {
      field: LumpSumDataGridFields.DigitalRewards,
      headerName: 'Reward Points Start/End Date',
      type: 'string',
      width: 220,
      filterable: false,
      sortable: false,
      columnBodyCss: styles.dataGrid.centerBodyCell,
      columnHeaderCss: {
        root: styles.dataGrid.centerHeaderCell,
      },
      renderBodyCellContent(params: DataGridRenderBodyCellParams<string, IContractTermForLumpSum>) {
        let toReturn = '';
        if (params.row.digitalRewards?.startDate) toReturn += format(new Date(params.row.digitalRewards?.startDate), 'MM/dd/yy') + ' to ';
        if (params.row.digitalRewards?.endDate) toReturn += format(new Date(params.row.digitalRewards?.endDate), 'MM/dd/yy');
        return toReturn;
      },
    },
  ];

  const columns: DataGridColDef[] = [
    {
      field: LumpSumDataGridFields.TermTypeId,
      headerName: 'Vendor Funded Program',
      type: 'number',
      width: 230,
      columnBodyCss: styles.dataGrid.centerBodyCell,
      columnHeaderCss: {
        root: styles.dataGrid.centerHeaderCell,
      },
      renderBodyCellContent(params: DataGridRenderBodyCellParams<number, IContractTermForLumpSum>) {
        if (!params.value) return 'Unknown Vendor Funded Program';
        const termTypeName = referenceData.termTypes.byId[params.value].name;
        return props.canModify ? (
          <>
            <EditIcon
              className="edit-icon"
              sx={{ color: defaultColors.blue }}
              onClick={() => invokeTermEdit(params.row)}
              id={`EditLumpSumGridTermLinkIcon_${params.rowId}`}
            />
            <DbgLinkButton sx={styles.openModalBtn} onClick={() => invokeTermEdit(params.row)} id={`EditLumpSumGridTermLink_${params.rowId}`}>
              {termTypeName}
            </DbgLinkButton>
          </>
        ) : (
          <span>{termTypeName}</span>
        );
      },
    },
    {
      field: LumpSumDataGridFields.EffectiveDate,
      headerName: 'Date',
      type: 'string',
      sortable: false,
      width: 135,
      columnHeaderCss: {
        root: styles.dataGrid.centerHeaderCell,
      },
      columnBodyCss: styles.dataGrid.centerBodyCell,
      renderBodyCellContent(params: DataGridRenderBodyCellParams<number, IContractTermForLumpSum>) {
        if (params.value) return format(new Date(params.value), 'MM/dd/yy');
        else
          return (
            <DbgLinkButton id={'ContactMissingLumpSumDate'} sx={styles.missingInfoBtn} onClick={() => invokeTermEdit(params.row)}>
              <ErrorOutlineIcon sx={styles.missingInfoIcon} /> Missing
            </DbgLinkButton>
          );
      },
    },
    {
      field: LumpSumDataGridFields.DepartmentId,
      headerName: 'Department',
      type: 'string',
      sortable: false,
      flex: 1,
      minWidth: 150,
      columnBodyCss: styles.dataGrid.centerBodyCell,
      renderBodyCellContent(params: DataGridRenderBodyCellParams<number, IContractTermForLumpSum>) {
        let department;
        if (params.value) department = referenceData.departments.byId[params.value];

        if (!department || !department.isActive)
          return (
            <DbgLinkButton id={'ContactMissingLumpSumDepartment'} sx={styles.missingInfoBtn} onClick={() => invokeTermEdit(params.row)}>
              <ErrorOutlineIcon sx={styles.missingInfoIcon} /> Missing
            </DbgLinkButton>
          );
        else return department.name || 'Unknown Department';
      },
    },
    ...(containDigitalRewardFee ? digitalRewardFeeColumns : []),
    {
      field: LumpSumDataGridFields.Amount,
      headerName: 'Amount',
      type: 'string',
      sortable: false,
      width: 135,
      columnHeaderCss: {
        root: styles.dataGrid.rightAlign,
      },
      columnBodyCss: styles.dataGrid.centerBodyCell,
      renderBodyCellContent(params: DataGridRenderBodyCellParams<number, IContractTermForLumpSum>) {
        return (
          <Box sx={styles.amountCell}>
            <Box sx={styles.amountCell.prefix}>$</Box>
            <Box>{params.value && NumberToCurrencyString(params.value)}</Box>
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={styles.root}>
      <DataGrid
        id={'lump-sums-data-grid'}
        rows={props.contractTerms.contractTermsForLumpSum}
        getRowId={(r: IContractTermForLumpSum) => r.uniqueId}
        columns={columns}
        bodyRowHeight={52}
        headerRowHeight={36}
        messages={props.errors?.map(toDataGridCellMessage)}
        hideFooter
        showBodyCellRightBorder
        showInputValidationErrors={showErrors}
        cssOverrides={{
          root: containDigitalRewardFee ? styles.dataGridWithRewards.root : styles.dataGrid.root,
          header: containDigitalRewardFee ? styles.dataGridWithRewards.overrides.header : styles.dataGrid.overrides.header,
          headerCell: containDigitalRewardFee ? styles.dataGridWithRewards.overrides.headerCell : styles.dataGrid.overrides.headerCell,
          bodyRow: containDigitalRewardFee ? styles.dataGridWithRewards.overrides.bodyRow : styles.dataGrid.overrides.bodyRow,
          bodyCell: containDigitalRewardFee ? styles.dataGridWithRewards.overrides.cell : styles.dataGrid.overrides.cell,
        }}
      />
    </Box>
  );
};

const dataGridStyle = {
  root: {
    marginTop: '36px',
    width: '840px',
    borderRadius: '0px',
    border: '0px',
  },
  centerHeaderCell: {
    alignItems: 'center',
  },
  centerBodyCell: {
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  rightAlign: {
    alignItems: 'end',
    justifyContent: 'end',
  },
  cellValidationError: {
    boxShadow: `${defaultColors.red}  0px 0px 0px 2px inset`,
  },
  overrides: {
    header: {
      backgroundColor: 'unset',
      boxShadow: `${defaultColors.border} 1px 0px 0px 0px inset`,
    },
    headerCell: {
      borderTop: `1px solid ${defaultColors.border}`,
      borderRight: `1px solid ${defaultColors.border}`,
      borderBottom: `1px solid ${defaultColors.border}`,
      color: defaultColors.mediumGrey,
      fontWeight: 500,
    },
    bodyRow: {
      boxShadow: `${defaultColors.border} 1px 0px 0px 0px inset`,
      '& .edit-icon': {
        visibility: 'collapse',
      },
      '&:hover .edit-icon': {
        visibility: 'visible',
      },
    },
    cell: {
      borderRight: `1px solid ${defaultColors.border} !important`,
      flexDirection: 'row' as const,
      justifyContent: 'start',
      alignItems: 'center',
      fontSize: '15px',
      fontWeight: 500,
    },
  },
};
const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '500px',
  },
  deleteBtnIcon: {
    color: defaultColors.mediumGrey,
    fontSize: '18px',
    '&:hover': {
      color: defaultColors.darkGrey,
    },
  },
  dataGrid: dataGridStyle,
  dataGridWithRewards: {
    ...dataGridStyle,
    root: {
      ...dataGridStyle.root,
      width: '1050px',
    },
    overrides: {
      ...dataGridStyle.overrides,
      headerCell: {
        ...dataGridStyle.overrides.headerCell,
        borderRight: 'none',
        '&:nth-of-type(3), &:nth-of-type(5), &:nth-of-type(6)': {
          borderRight: '1px solid rgba(224, 224, 224, 1) !important',
        },
      },
      cell: {
        ...dataGridStyle.overrides.cell,
        borderRight: 'none',
        '&:nth-of-type(3), &:nth-of-type(5), &:nth-of-type(6)': {
          borderRight: '1px solid rgba(224, 224, 224, 1) !important',
        },
      },
    },
  },
  amountCell: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    prefix: {
      color: defaultColors.mediumGrey,
    },
  },
  openModalBtn: {
    textDecoration: 'underline',
    fontSize: '15px',
  },
  missingInfoBtn: {
    color: defaultColors.red,
    textDecoration: 'underline',
    fontSize: '15px',
    '&:hover': {
      color: defaultColors.red,
      textDecoration: 'underline',
    },
  },
  missingInfoIcon: {
    height: '19px',
  },
};

export default LumpSumsDataGrid;
