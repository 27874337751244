import { IAllContractsSearchRequest, IOrderSurveySearchRequest } from '../../models/requests';
import { IAllContractsSearchResponse } from '../../models/responses';
import { emptyToNull, fetchGetJson, HttpErrorResponse, toDateOnlyString } from '../contractHubApi';
import { IPagedResult } from '@dierbergs-markets/react-component-library';

export const contractSearchService = {
  searchAllContracts,
  orderSurveySearch,
};

function searchAllContracts(
  request: IAllContractsSearchRequest,
  signal: AbortSignal
): Promise<IPagedResult<IAllContractsSearchResponse> | HttpErrorResponse> {
  const q = {
    contractId: emptyToNull(request.contractIdFragment),
    customerId: emptyToNull(request.customerIdFragment),
    customerName: emptyToNull(request.customerNameFragment),
    supplierId: emptyToNull(request.supplierIdFragment),
    supplierName: emptyToNull(request.supplierNameFragment),
    categoryManagerName: emptyToNull(request.categoryManagerNameFragment),
    vendorContractNumber: emptyToNull(request.vendorContractNumberFragment),
    productLine: emptyToNull(request.productLineFragment),
    comments: emptyToNull(request.commentsFragment),
    brand: emptyToNull(request.brandFragment),
    description: emptyToNull(request.descriptionFragment),
    orderCode: emptyToNull(request.orderCode),
    upc: request.upc,
    startDate: toDateOnlyString(request.startDate),
    endDate: toDateOnlyString(request.endDate),
    page: request.pageNumber,
    pageSize: request.pageSize,
    sortConfig: request.sortConfig,
    stateText: emptyToNull(request.stateText),
    manufacturer: emptyToNull(request.manufacturer),
  };

  return fetchGetJson<IPagedResult<IAllContractsSearchResponse>>({ endpoint: 'contract', query: q, signal });
}

function orderSurveySearch(
  request: IOrderSurveySearchRequest,
  signal: AbortSignal
): Promise<IPagedResult<IAllContractsSearchResponse> | HttpErrorResponse> {
  const q = {
    startDate: toDateOnlyString(request.startDate),
    endDate: toDateOnlyString(request.endDate),
    page: request.pageNumber,
    pageSize: request.pageSize,
  };

  return fetchGetJson<IPagedResult<IAllContractsSearchResponse>>({ endpoint: 'contract/order-survey', query: q, signal });
}
