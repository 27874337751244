import React, { createContext, useContext, useEffect, useState } from 'react';
import environment from 'environment';
import {
  IUser,
  EntityById,
  IStore,
  ICategoryManager,
  ITermUnitOfMeasure,
  ITermType,
  IDepartment,
  IReferenceData,
  IStoreGroup,
  ITaskAssigneeDisplay,
  IContractNotification,
  IPriceType,
  IAdSite,
  IEntityCollection,
} from '../models';
import { HubConnectionBuilder, HubConnection, HubConnectionState } from '@microsoft/signalr';
import { useNavigate } from 'react-router-dom';
import { RouteEnum } from '../views/layout/PageRouter';

function createEntityCollection<T>(entities: T[], getKey: (entity: T) => number | string): IEntityCollection<T> {
  const byId = entities.reduce((acc, entity) => {
    acc[getKey(entity)] = entity;
    return acc;
  }, {} as EntityById<T>);

  return {
    byId,
    get all() {
      return Object.values(byId); // Computes the array lazily
    },
  };
}

export interface IReferenceDataState {
  stores: IEntityCollection<IStore>;
  storeGroups: IEntityCollection<IStoreGroup>;
  categoryManagers: IEntityCollection<ICategoryManager>;
  taskAssigneeDisplays: IEntityCollection<ITaskAssigneeDisplay>;
  termTypes: IEntityCollection<ITermType>;
  priceTypes: IEntityCollection<IPriceType>;
  digitalRewardTermTypeId?: number;
  termTypeUnitsOfMeasure: IEntityCollection<ITermUnitOfMeasure>;
  departments: IEntityCollection<IDepartment>;
  rewardPointsMultipliers: { value: any; text: string }[];
  pricingEnabled: boolean;
  adPlannerExportEnabled: boolean;
  adSites: IEntityCollection<IAdSite>;
}

export interface IApplicationContextState {
  user?: IUser;
  referenceData?: IReferenceDataState;
  contractNotifications: IContractNotification[];
  loginInProgress?: boolean;
}

export interface IApplicationContextProvider extends IApplicationContextState {
  setUser?: (user?: IUser) => void;
  setReferenceData?: (referenceData: IReferenceData) => void;
  setContractNotifications?: (notifications: IContractNotification[]) => void;
  setLoginInProgress?: (value: boolean) => void;
  setPricingEnabled?: (value: boolean) => void;
  setAdPlannerExportEnabled?: (value: boolean) => void;
}

const ApplicationContext = createContext<IApplicationContextProvider>({ contractNotifications: [] });

interface IUserHubUpdatePermissionsRequest {
  permissions: string[];
}

interface IProps {
  children?: React.ReactNode;
}
const ApplicationContextProvider = (props: IProps) => {
  const [state, setState] = useState<IApplicationContextState>({ contractNotifications: [] });
  const [userHubConnection, setUserHubConnection] = useState<HubConnection | null>(null);
  const [connectionUserId, setConnectionUserId] = useState<number | null>(null);
  const navigate = useNavigate();

  const provider: IApplicationContextProvider = {
    ...state,
    setUser: (user?: IUser) =>
      setState((s) => {
        return { ...s, user: user };
      }),
    setReferenceData: (referenceData?: IReferenceData) =>
      setState((s) => {
        const termTypes = createEntityCollection<ITermType>(referenceData?.termTypes || [], (x) => x.termTypeId);
        const adSites = createEntityCollection<IAdSite>(referenceData?.adSites || [], (x) => x.adSiteId);
        const departments = createEntityCollection<IDepartment>(referenceData?.departments || [], (x) => x.id);
        const termTypeUnitsOfMeasure = createEntityCollection<ITermUnitOfMeasure>(referenceData?.unitsOfMeasure || [], (x) => x.termUnitOfMeasureId);
        const categoryManagers = createEntityCollection<ICategoryManager>(referenceData?.categoryManagers || [], (x) => x.id);
        const priceTypes = createEntityCollection<IPriceType>(referenceData?.priceTypes || [], (x) => x.id);
        const stores = createEntityCollection<IStore>(referenceData?.stores || [], (x) => x.id);
        const storeGroups = createEntityCollection<IStoreGroup>(referenceData?.storeGroups || [], (x) => x.id);
        const taskAssigneeDisplays = createEntityCollection<ITaskAssigneeDisplay>(referenceData?.taskAssignees || [], (x) => x.taskAssigneeId);
        return {
          ...s,
          referenceData: {
            categoryManagers,
            departments,
            priceTypes,
            termTypes,
            termTypeUnitsOfMeasure,
            stores,
            storeGroups,
            taskAssigneeDisplays,
            adSites,
            rewardPointsMultipliers: referenceData?.rewardPointsMultipliers.map((a) => ({ value: a, text: `${a}X` })) ?? [],
            digitalRewardTermTypeId: referenceData?.termTypes.find((a) => a.validationTypeCode === 'DIGITAL_REWARDS')?.termTypeId,
            pricingEnabled: referenceData?.pricingEnabled ?? true,
            adPlannerExportEnabled: referenceData?.adPlannerExportEnabled ?? true,
          },
        };
      }),
    setContractNotifications: (notifications: IContractNotification[]) =>
      setState((s) => {
        return {
          ...s,
          contractNotifications: notifications,
        };
      }),
    setLoginInProgress: (value: boolean) =>
      setState((s) => {
        return { ...s, loginInProgress: value };
      }),
    setPricingEnabled: (value: boolean) =>
      setState((s) => {
        return { ...s, pricingEnabled: value };
      }),
    setAdPlannerExportEnabled: (value: boolean) =>
      setState((s) => {
        return { ...s, adPlannerExportEnabled: value };
      }),
  };

  useEffect(() => {
    if (userHubConnection && userHubConnection.state === HubConnectionState.Connected) return;
    const webSocketUrl = `${environment.baseApiUrl}/ws/user`;

    const connection = new HubConnectionBuilder().withUrl(webSocketUrl).withAutomaticReconnect().build();
    connection.on('Logout', handleSignalRLogoutRequest);
    connection.on('UpdatePermissions', handleSignalRUpdatePermissionsRequest);
    setUserHubConnection(connection);

    return function () {
      connection.stop();
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (!userHubConnection) return;

      const currentUserId = state.user?.id;
      if (!currentUserId || currentUserId !== connectionUserId) {
        if (userHubConnection.state != HubConnectionState.Disconnected) {
          await userHubConnection.stop();
        }
      }
      if (!currentUserId || currentUserId === connectionUserId) return;

      try {
        await userHubConnection.start();
      } catch (ex) {
        //console.log('user signalr start exception', ex);
      }
      setConnectionUserId(currentUserId);
    })();
  }, [userHubConnection, state.user?.id]);

  function handleSignalRLogoutRequest() {
    setState((s) => {
      return {
        ...s,
        user: undefined,
      };
    });
    navigate(RouteEnum.Dashboard);
  }

  function handleSignalRUpdatePermissionsRequest(request: IUserHubUpdatePermissionsRequest) {
    const updatedUser = state.user
      ? ({
          ...state.user,
          permissions: request.permissions,
        } as IUser)
      : undefined;

    setState((s) => {
      return {
        ...s,
        user: updatedUser,
      };
    });
  }

  return <ApplicationContext.Provider value={provider}>{props.children}</ApplicationContext.Provider>;
};

//Convience hook for retrieving ApplicationContext state. Elimates the need to import useContext and ApplicationContext in a component that needs the state.
const useApplicationContextState = () => {
  // get the context
  const context = useContext(ApplicationContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('ApplicationContext was used outside of its Provider');
  }

  return context;
};

export { ApplicationContext, ApplicationContextProvider, useApplicationContextState };
