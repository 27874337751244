import { IWorkflowLog } from '../../models';
import { fetchGetJson } from '../contractHubApi';

export const systemAlertService = {
  getSystemAlerts,
};

async function getSystemAlerts(): Promise<any[] | any> {
  const response = await fetchGetJson<IWorkflowLog>({ endpoint: `system-alerts` });
  return response;
}
