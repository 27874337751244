import { Box, SxProps } from '@mui/material';
import React from 'react';

interface IProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  sx?: SxProps;
}

export default function TabPanel(props: IProps) {
  const { children, value, index, sx, ...other } = props;

  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <Box sx={sxProps} hidden={value !== index} id={`tabPanel-${index}`} {...other}>
      {value === index && <>{children}</>}
    </Box>
  );
}
