import React from 'react';
import { DialogActions, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { defaultColors } from '../../../styles/variables';
import { getGreenButtonStyleProps, getWhiteButtonStyleProps } from '../../../styles/themes';
import { DbgButtonSize } from '../../../models/enums';
import { IDbgRoundedButtonStyleProps, DbgRoundedButton } from '../buttons/DbgRoundedButton';

interface IProps {
  id: string;
  open: boolean;
  title?: string;
  heading?: string;
  confirmText?: string;
  cancelText?: string;
  confirmButtonTheme?: IDbgRoundedButtonStyleProps;
  cancelButtonTheme?: IDbgRoundedButtonStyleProps;
  discardButtonTheme?: IDbgRoundedButtonStyleProps;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  hideFooterActions?: boolean;
  disabled?: boolean;
  sx?: SxProps;
  showCloseIcon?: boolean;
}

const styles = {
  paper: {
    [`& .${dialogClasses.paper}`]: {
      borderRadius: '10px',
      color: defaultColors.darkGrey,
      textAlign: 'center',
      minWidth: '480px',
      // minHeight: '460px',
    },
  },
  title: {
    marginLeft: '20px',
    marginRight: '20px',
    padding: '16px 0 16px 0',
    position: 'relative',
    letterSpacing: '0',
    lineHeight: '22px',
    borderBottom: (theme: Theme) => theme?.custom?.borders.default,
  },
  content: {
    padding: '8px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
    borderTop: (theme: Theme) => theme?.custom?.borders.default,
    marginLeft: '20px',
    marginRight: '20px',
  },
  confirmButton: {
    width: '250px',
    margin: '12px',
  },
  cancelButton: {
    width: '250px',
    margin: '12px',
    border: '0px',
  },
  closeIcon: {
    width: '16px',
    height: '16px',
    color: defaultColors.mediumGrey,
    position: 'absolute',
    right: -2,
    top: 12,
    cursor: 'pointer',
  },
} as const;

const DbgDialog = (props: React.PropsWithChildren<IProps>) => {
  const sxProps = Array.isArray(props.sx) ? props.sx : [props.sx];

  return (
    <Dialog id={props.id} sx={[styles.paper, ...sxProps]} open={props.open} disableEscapeKeyDown={true}>
      {/* If onClose is defined use onClose. If not use onCancel. 
          onCancel is meant to cancel the action. 
          onClose is meant to just close the modal.*/}
      {props.title && (
        <DialogTitle sx={styles.title}>
          {props.onClose && props.showCloseIcon && (
            <ClearIcon
              sx={styles.closeIcon}
              onClick={() => {
                if (props.onClose) props.onClose();
              }}
            />
          )}
          {props.onCancel && !props.onClose && props.showCloseIcon && (
            <ClearIcon
              sx={styles.closeIcon}
              onClick={() => {
                if (props.onCancel) props.onCancel();
              }}
            />
          )}
          {props.title}
        </DialogTitle>
      )}
      <DialogContent sx={styles.content}>{props.children}</DialogContent>

      {!props.hideFooterActions && (
        <DialogActions sx={styles.actions}>
          {props.onCancel && (
            <DbgRoundedButton
              dbgButtonSize={DbgButtonSize.Large}
              tabIndex={-1}
              onClick={props.onCancel}
              sx={styles.cancelButton}
              styleProps={props.cancelButtonTheme || getWhiteButtonStyleProps()}
              id={`Dialog${props.id}Cancel`}
            >
              {props.cancelText || 'Cancel'}
            </DbgRoundedButton>
          )}
          {props.onConfirm && (
            <DbgRoundedButton
              dbgButtonSize={DbgButtonSize.Large}
              disabled={props.disabled}
              onClick={props.onConfirm}
              sx={styles.confirmButton}
              styleProps={props.confirmButtonTheme || getGreenButtonStyleProps()}
              id={`Dialog${props.id}Confirm`}
            >
              {props.confirmText || 'OK'}
            </DbgRoundedButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DbgDialog;
