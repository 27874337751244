import { IBrand } from '../models';
import { HttpErrorResponse, fetchGetJson } from './contractHubApi';

export const brandService = {
  searchBrand,
};

function searchBrand(brandName: string): Promise<IBrand[] | HttpErrorResponse> {
  return fetchGetJson<IBrand[]>({ endpoint: 'brand', query: { q: brandName } });
}
