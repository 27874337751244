import { IReferenceData } from '../models';
import { HttpErrorResponse, fetchGetJson } from './contractHubApi';

export const referenceDataService = {
  getAll,
};

async function getAll(): Promise<IReferenceData | HttpErrorResponse> {
  return fetchGetJson<IReferenceData>({ endpoint: 'reference-data' });
}
