import { IPricingSearchRequest } from '../models/requests';
import { IPricingSearchResponse } from '../models/responses';
import { HttpErrorResponse, fetchPostJson } from './contractHubApi';

export const pricingService = {
  getImplications,
};

async function getImplications(request: IPricingSearchRequest): Promise<IPricingSearchResponse | HttpErrorResponse> {
  return fetchPostJson<IPricingSearchResponse>({ endpoint: 'pricing/implications', body: request });
}
