import { IContract } from '../../models';
import { ICreateProposalRequest, IKeepProposalRequest } from '../../models/requests';
import { fetchDeleteJson, fetchPostSuccessOrError, fetchPutJson, HttpErrorResponse } from '../contractHubApi';

export const contractProposalService = {
  createContractProposal,
  keepContractProposal,
  declineContractProposal,
};

function createContractProposal(contractId: number, request: ICreateProposalRequest): Promise<boolean | HttpErrorResponse> {
  return fetchPostSuccessOrError({ endpoint: `contract/${contractId}/proposal`, body: request });
}
function keepContractProposal(contractId: number, request: IKeepProposalRequest): Promise<IContract | HttpErrorResponse> {
  return fetchPutJson<IContract>({ endpoint: `contract/${contractId}/proposal`, body: request });
}
function declineContractProposal(contractId: number): Promise<IContract | HttpErrorResponse> {
  return fetchDeleteJson<IContract>({ endpoint: `contract/${contractId}/proposal` });
}
