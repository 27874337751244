import React, { useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SxProps,
  selectClasses,
  FormHelperText,
  formHelperTextClasses,
  formControlClasses,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { defaultColors, defaultBorders } from '../../../styles/variables';
import { textfieldStyles } from '../../../styles/shared/TextFieldStyles';
import isEqual from 'lodash.isequal';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

type IOption = { value: any; text: string; disabled?: boolean };

interface IProps {
  id: string;
  label: string;
  items: (string | IOption)[];
  value: any;
  onChange: (value: any) => void;
  required?: boolean;
  sx?: SxProps;
  disabled?: boolean;
  errormessage?: string;
}

const styles = {
  focus: {
    [`& .${outlinedInputClasses.root}`]: {
      '&.Mui-focused': {
        boxShadow: `0 0 0 2px ${defaultColors.blue} !important`,
        '& > fieldset': {
          border: 'none',
        },
      },
    },
  },
  [`& .${formControlClasses.root}`]: {
    border: `1px solid ${defaultColors.blueGrey}`,
    boxShadow: `0 1px 2px 0 ${defaultColors.transparentMediumGrey}`,
    borderRadius: '6px',
    [`& .${formHelperTextClasses.root}`]: {
      position: 'relative',
      height: '0px',
      top: '-2px',
    },
    '&:hover': {
      border: `1px solid ${defaultColors.darkBlueGrey}`,
      boxShadow: `0 1px 2px 0 ${defaultColors.transparentMediumGrey}`,
    },
    '& fieldset': {
      border: 0,
      boxShadow: 0,
      borderWidth: '0px !important',
      '&:hover': {
        border: 0,
        boxShadow: 0,
      },
    },
  },
  select: {
    height: '60px',
    minHeight: '60px',
    borderRadius: '6px',
    boxShadow: defaultBorders.boxShadow,
    '&.Mui-focused': {
      borderWidth: '0px !important',
    },
    [`& .${selectClasses.select}`]: {
      padding: '22px 0 0 0',
      textAlign: 'left',
      marginLeft: '11px',
      color: defaultColors.blue,
      fontSize: '15px',
      border: 0,
      boxShadow: 0,
    },
    [`& .${selectClasses.icon}`]: {
      marginTop: '10px',
      color: defaultColors.blue,
    },
    [`&. .${selectClasses.outlined}`]: {
      border: 0,
      boxShadow: 0,
      '& :focus': {
        border: 0,
        boxShadow: 0,
      },
    },
    '& :hover': {
      border: 0,
    },
  },
  label: {
    ...textfieldStyles.inputLabel,
  },
};

const DbgSelect = React.forwardRef<HTMLInputElement, IProps>((props: IProps, ref) => {
  const sxProps = Array.isArray(props.sx) ? props.sx : [props.sx];
  const [selectedOption, setSelectedOption] = useState<IOption | undefined>(undefined);
  const [options, setOptions] = useState<IOption[]>([]);

  useEffect(() => {
    const optionsList = props.items.map((i: string | IOption) => {
      const option = i as IOption;
      if (option.text != undefined && option.value != undefined) return option; // checking for undefined since valid value of 0 would be false
      if (typeof i === 'string') return { text: i, value: i, disabled: false };
      return { text: 'Unknown', value: undefined, disabled: false };
    });

    setOptions(optionsList);
    setSelectedOption(optionsList.find((o) => isEqual(o.value, props.value)));
  }, [props.items]);

  return (
    <FormControl
      fullWidth
      sx={[styles.root, props.errormessage && textfieldStyles.textFieldError, ...sxProps, styles.focus]}
      disabled={props.disabled}
    >
      <InputLabel id={`${props.id}_label`} sx={styles.label} required={props.required} shrink>
        {props.label}
      </InputLabel>
      <Select
        id={props.id}
        inputRef={ref}
        variant="outlined"
        sx={styles.select}
        value={selectedOption?.value === undefined ? 'Select' : selectedOption?.value} // again, checking for undefined since valid value of 0 would be false
        endAdornment={props.errormessage && <ErrorIcon sx={{ color: `${defaultColors.red}`, width: '18px' }} />}
        onChange={(ev) => props.onChange(ev.target.value as any)}
      >
        <MenuItem key={0} value={'Select'} disabled>
          Select
        </MenuItem>
        {options.map((i: IOption, idx: number) => (
          <MenuItem key={idx} value={i.value} disabled={i.disabled}>
            {i.text}
          </MenuItem>
        ))}
      </Select>
      {props.errormessage && <FormHelperText error>{props.errormessage}</FormHelperText>}
    </FormControl>
  );
});

export default DbgSelect;
