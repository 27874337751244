import React from 'react';
import PageCenter from '../../components/layout/PageCenter';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { RouteEnum } from '../../layout/PageRouter';

export default function NotFound() {
  return (
    <PageCenter>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ fontWeight: 500, fontSize: '16px', marginBottom: '15px' }}>The requested page could not be found.</Box>
        <Link to={RouteEnum.Dashboard}>Back to Dashboard</Link>
      </Box>
    </PageCenter>
  );
}
