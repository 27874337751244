import { useEffect, useState, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IAdPlannerCostBreakdown, IContractAdPlannerExport } from '../../../models';
import PageHeader from '../../layout/components/PageHeader';
import { DbgRoundedButton } from '../../components/buttons/DbgRoundedButton';
import PageBody from '../../layout/components/PageBody';
import { DbgButtonSize } from '../../../models/enums';
import { RouteEnum } from '../../layout/PageRouter';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useApplicationContextState } from '../../../contexts/ApplicationContext';
import { contractAdPlannerExportService } from '../../../services/contract/contractAdPlannerExportService';
import { Button, Checkbox, ConfirmationDialog, Select, Spinner, TextInput } from '@dierbergs-markets/react-component-library';
import { enqueueSnackbar } from 'notistack';
import { allowedKeys } from './components/dialogs/ContractTasksCommon';
import { addDays, format, startOfDay } from 'date-fns';
import SelectedStoresIconPopover from './components/SelectedStoresIconPopover';
import { HttpErrorResponse } from '../../../services/contractHubApi';
import { useAdPlannerExportStyles } from './styles/AdPlannerExportPageStyles';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

interface IAdStartDateOption {
  text: string;
  value: Date;
}

export default function AdPlannerExportPage() {
  //State
  const [contractExport, setContractExport] = useState<IContractAdPlannerExport | undefined>(undefined);
  const [selectedAdGroups, setSelectedAdGroups] = useState<string[]>([]);
  const [selectedModules, setSelectedModules] = useState<string[]>([]);
  const [adStartDateOptions, setAdStartDateOptions] = useState<IAdStartDateOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [exporting, setExporting] = useState(false);
  const [exported, setExported] = useState(false);
  const [exportDisabled, setExportDisabled] = useState(false);
  const [showUnsavedChanges, setShowUnsavedChanges] = useState<boolean>(false);

  //Variables
  const missingAdLocationMessage = 'No Ad Location selected';

  //Hooks
  const navigate = useNavigate();
  const { referenceData } = useApplicationContextState();
  const { id } = useParams();
  const { css, classes, cx } = useAdPlannerExportStyles();

  // Schema validation
  const schema = Joi.object({
    adGroups: Joi.array().items(
      Joi.object({
        adGroupName: Joi.string()
          .required()
          .max(50)
          .label('Ad Group Name')
          .messages({ 'string.empty': 'Ad Group Name is required.', 'string.max': 'Ad Group Name cannot be greater than 50 characters.' }),
        comments: Joi.string()
          .allow(null, '')
          .max(100)
          .label('Comment')
          .messages({ 'string.max': 'Comments cannot be greater than 100 characters.' }),
        adStartDate: Joi.date().required().label('Ad Start Date'),
      })
    ),
  });

  const resolver = joiResolver(schema, { abortEarly: false, allowUnknown: true }, { mode: 'async' });

  const { control, formState, getValues } = useForm<IContractAdPlannerExport>({
    defaultValues: { adGroups: [] },
    resolver,
    mode: 'all',
    shouldFocusError: false,
  });
  const { replace } = useFieldArray({
    control,
    name: 'adGroups',
  });

  //Effects
  useEffect(() => {
    if (id) loadContractExport(+id);
  }, [id]);

  // any time a field is updated, set exported flag to false
  useEffect(() => setExported(false), [contractExport, selectedAdGroups, selectedModules]);

  useEffect(() => {
    const adGroupsWithErrors = contractExport?.adGroups.filter((q, i) => (formState.errors.adGroups ? formState.errors.adGroups[i] : (null as any)));
    const isDisabled =
      (exporting ||
        exported ||
        selectedAdGroups.filter((q) => adGroupsWithErrors?.find((e) => e.uniqueId === q)).length > 0 ||
        (selectedAdGroups.length === 0 && selectedModules.length === 0) ||
        contractExport?.adGroups.every((ag) => ag.adSiteId === undefined)) ??
      false;

    setExportDisabled(isDisabled);
  }, [exporting, exported, formState, selectedAdGroups, selectedModules, contractExport]);

  useEffect(() => {
    const weeksBack = 84; // 12 * 7 Days
    const weeksOut = 112; // 16 * 7 Days
    const startDate = addDays(getAdStartDate(), -weeksBack);

    const optionList: IAdStartDateOption[] = [];
    for (let i = 0; i <= weeksBack + weeksOut - 1; i += 7) {
      const optionDate = addDays(startDate, i);
      optionList.push({ text: format(optionDate, 'M/d/yyyy'), value: optionDate });
    }

    setAdStartDateOptions(optionList);
  }, []);

  //Async Functions

  const handleExport = async () => {
    if (!contractExport) return;

    setExporting(true);

    // Get adGroups from JOI since it holds the updated values
    const { adGroups } = getValues();

    const exportObj = {
      contractId: contractExport.contractId,
      contractNumber: contractExport.contractNumber,
      stores: contractExport.stores,
      adGroups: adGroups.filter((q) => !!q.adSiteId && selectedAdGroups.findIndex((i) => i === q.uniqueId) > -1),
      modules: contractExport.modules.filter((q) => selectedModules.findIndex((i) => i === q.uniqueId) > -1),
    };

    const response = await contractAdPlannerExportService.submitAdPlannerExport(+id!, exportObj);

    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Error exporting contract to Ad Planner.', { variant: 'error' });
    } else {
      enqueueSnackbar('Successfully exported contract.', { variant: 'success' });
      setExported(true);
    }

    setExporting(false);
  };

  const loadContractExport = async (contractId: number) => {
    setLoading(true);
    setExported(false);
    const response = await contractAdPlannerExportService.getAdPlannerExport(contractId);
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Error retrieving Ad Planner Export.', { variant: 'error' });
    } else {
      const adPriceType = referenceData!.priceTypes.all.find((q) => q.name === 'Ad');
      setContractExport(response);
      setSelectedAdGroups(response.adGroups.filter((q) => q.pricingTypeId === adPriceType?.id && !!q.adSiteId).map((q) => q.uniqueId));
      replace(response.adGroups);
      setSelectedModules(response.modules.map((q) => q.uniqueId));
    }
    setLoading(false);
  };

  //Functions

  const getAdStartDate = () => {
    // converted from the VB script in AdPlannerInput.asp
    // try to keep the original logic intact.
    const curDate = startOfDay(new Date());
    const curDay = curDate.getDay(); // getDay() returns 0 for Sunday, 1 for Monday, etc.

    switch (curDay) {
      // Sun
      case 0:
        return addDays(curDate, -5); //javascript is 0 based, so the offset needs an extra -1
      // Mon, Tue, Wed, Thu, Fri, Sat
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return addDays(curDate, 2 - curDay);
      default:
        return curDate;
    }
  };

  const getUtcDate = (input: Date) => {
    return new Date(input.getTime() + input.getTimezoneOffset() * 60000);
  };

  const handleReturnToAllContracts = () => {
    if (!exported && contractExport && (contractExport.adGroups.length > 0 || contractExport.modules.length > 0)) {
      setShowUnsavedChanges(true);
    } else {
      navigate(RouteEnum.Dashboard);
    }
  };

  const renderContractLink = (contractId: number, contractNumber: string) => {
    return (
      <a
        className={classes.contractLink}
        onClick={(e) => {
          e.preventDefault();
          navigate(`${RouteEnum.Contract}/${contractId}`, { state: { tab: 'Pricing' } });
        }}
      >
        {contractId === contractExport?.contractId ? 'Current Contract' : contractNumber}
      </a>
    );
  };

  const renderContractLinkOrTotal = (contractId: number, contractNumber: string, breakdowns: IAdPlannerCostBreakdown[]) => {
    if (breakdowns.length > 1) return 'Total';
    return breakdowns.length > 1 ? 'Totals' : renderContractLink(contractId, contractNumber);
  };

  const setAdGroupSelected = (adGroupUniqueId: string, selected: boolean) => {
    if (selected) setSelectedAdGroups([...selectedAdGroups, adGroupUniqueId]);
    else setSelectedAdGroups(selectedAdGroups.filter((q) => q != adGroupUniqueId));
  };

  const setModuleSelected = (moduleUniqueId: string, selected: boolean) => {
    if (selected) setSelectedModules([...selectedModules, moduleUniqueId]);
    else setSelectedModules(selectedModules.filter((q) => q != moduleUniqueId));
  };

  if (!referenceData) return <></>;

  return (
    <>
      <PageHeader>
        <div className={classes.contractPageHeader}>
          <DbgRoundedButton
            id="BackToDashboard"
            onClick={handleReturnToAllContracts}
            className={classes.headerBtn}
            dbgButtonSize={DbgButtonSize.Large}
          >
            <ArrowBackIcon className={classes.headerBtnIcon} />
          </DbgRoundedButton>
        </div>
      </PageHeader>
      <PageBody>
        <div className={classes.root}>
          {loading && (
            <div className={classes.header}>
              <div className={classes.titleContainer}>
                <div className={classes.title}>Loading...</div>
              </div>
            </div>
          )}
          {!loading && contractExport && (
            <>
              <div className={classes.header}>
                <div className={classes.titleContainer}>
                  <div className={classes.title}>Ad Planner Export</div>
                  <div className={classes.subTitle}>{`${contractExport.contractNumber} (${contractExport.contractId})`}</div>
                </div>
              </div>
              <div className={classes.mainContent}>
                {contractExport.adGroups.length === 0 && contractExport.modules.length === 0 && (
                  <div className={cx(classes.subTitle, classes.sectionSpace)}>No Ad Groups or Modules to Export</div>
                )}
                {(contractExport.adGroups.length > 0 || contractExport.modules.length > 0) && (
                  <div className={classes.groupStoresContainer}>
                    <SelectedStoresIconPopover stores={{ storeGroupIds: [], storeIds: contractExport?.stores ?? [] }} />
                  </div>
                )}
                {contractExport.adGroups.length > 0 && (
                  <>
                    <div className={cx(classes.subTitle, classes.sectionSpace)}>Groups</div>
                    {contractExport?.adGroups.map((adGroup, idx) => {
                      return (
                        <div className={classes.subSectionSpaceWrapper}>
                          <div
                            className={classes.subSectionSpace}
                            id={`adGroup-${idx}`}
                            key={`group-${idx}`}
                            title={!adGroup.adSiteId ? `Unable to export: ${missingAdLocationMessage}` : ''}
                          >
                            <div className={classes.checkboxWrapperAdGroup}>
                              <Checkbox
                                id={`AdGroupSelected-${idx}`}
                                disabled={!adGroup.adSiteId}
                                checked={!!adGroup.adSiteId && selectedAdGroups.findIndex((q) => q === adGroup.uniqueId) > -1}
                                onChange={(checked: boolean) => {
                                  setAdGroupSelected(adGroup.uniqueId, checked);
                                }}
                              />
                            </div>
                            <div className={classes.propertyDetailsContainer}>
                              <div className={classes.propertyDetails}>
                                <div className={classes.groupNameAndCommentContainer}>
                                  <div className={classes.groupNameContainer}>
                                    <Controller
                                      control={control}
                                      name={`adGroups.${idx}.adGroupName`}
                                      render={({ field }) => (
                                        <TextInput
                                          {...field}
                                          id={`groups.${idx}.name`}
                                          label="Group Name"
                                          disabled={!adGroup.adSiteId}
                                          classes={{
                                            errorText: classes.groupNameErrorText,
                                          }}
                                          allowedkeys={allowedKeys}
                                          tabIndex={idx + 1}
                                          onChange={field.onChange}
                                          value={field.value}
                                          errorMessage={
                                            (formState.errors.adGroups ? formState.errors.adGroups[idx] : (null as any))?.adGroupName?.message
                                          }
                                          placeholder="Group Name"
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className={classes.groupAdSiteIdContainer}>
                                    <div className={classes.groupAdSiteIdLabel}>Ad Location</div>
                                    {adGroup.adSiteId ? (
                                      <div className={classes.groupAdSiteIdName}>{referenceData.adSites.byId[adGroup.adSiteId].name}</div>
                                    ) : (
                                      <div className={classes.groupAdSiteIdMissing}>
                                        {missingAdLocationMessage}
                                        <Button
                                          id={`contractLnkBtn_${adGroup.uniqueId}`}
                                          className={classes.groupAdSiteIdContractLink}
                                          variant="link"
                                          onClick={() => navigate(`${RouteEnum.Contract}/${id}`, { state: { tab: 'Pricing' } })}
                                        >
                                          View Contract
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                  <div className={classes.groupAdSiteStartDateContainer}>
                                    <Controller
                                      control={control}
                                      name={`adGroups.${idx}.adStartDate`}
                                      render={({ field }) => (
                                        <Select
                                          {...field}
                                          id={`groups.${idx}.adStartDate`}
                                          label="Ad Start Date"
                                          disabled={!adGroup.adSiteId}
                                          items={adStartDateOptions.map((i) => {
                                            return { text: i.text, value: i.value };
                                          })}
                                          onChange={field.onChange}
                                          value={field.value}
                                          classes={{
                                            inputContent: css(classes.groupSelect),
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className={classes.groupNameAndCommentContainer}>
                                  <div className={classes.groupCommentContainer}>
                                    <Controller
                                      control={control}
                                      name={`adGroups.${idx}.comments`}
                                      render={({ field }) => (
                                        <TextInput
                                          {...field}
                                          id={`groups.${idx}.comments`}
                                          label="Comment"
                                          disabled={!adGroup.adSiteId}
                                          classes={{
                                            errorText: classes.groupNameErrorText,
                                          }}
                                          allowedkeys={allowedKeys}
                                          tabIndex={idx + 1}
                                          onChange={field.onChange}
                                          value={field.value}
                                          errorMessage={
                                            (formState.errors.adGroups ? formState.errors.adGroups[idx] : (null as any))?.comments?.message
                                          }
                                          placeholder="Comments"
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={classes.adGroups}>
                                {adGroup.subGroups.map((subgroup, subGroupIdx) => {
                                  return (
                                    <table key={`AdGroup-${idx}-SubGroup-${subGroupIdx}`}>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <table className={classes.gridTable}>
                                              <thead>
                                                <tr>
                                                  <th style={{ width: '200px' }}>Contract</th>
                                                  <th>SRP</th>
                                                  <th>Case List Cost</th>
                                                  <th>OI Allow</th>
                                                  <th>Billback</th>
                                                  <th>CMAP</th>
                                                  <th>Scandown</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    {renderContractLinkOrTotal(
                                                      contractExport.contractId,
                                                      contractExport.contractNumber,
                                                      subgroup.breakdowns
                                                    )}
                                                  </td>
                                                  <td>
                                                    {subgroup.multiple}/{subgroup.srp}
                                                  </td>
                                                  <td>{subgroup.caseListCost.toFixed(2)}</td>
                                                  <td>{subgroup.oiAllow.toFixed(2)}</td>
                                                  <td>{subgroup.billback.toFixed(2)}</td>
                                                  <td>{subgroup.cmap.toFixed(2)}</td>
                                                  <td>{subgroup.scandown.toFixed(2)}</td>
                                                </tr>
                                                {subgroup.breakdowns.length > 1 &&
                                                  subgroup.breakdowns.map((breakdown, bdIdx) => (
                                                    <tr key={`breakdown-${breakdown.contractId}-${bdIdx}`}>
                                                      <td>{renderContractLink(breakdown.contractId, breakdown.contractNumber)}</td>
                                                      <td>{/* Multiple/SRP */}</td>
                                                      <td>{/* Case List Cost */}</td>
                                                      <td>{breakdown.oiAllow.toFixed(2)}</td>
                                                      <td>{breakdown.billback.toFixed(2)}</td>
                                                      <td>{breakdown.cmap.toFixed(2)}</td>
                                                      <td>{breakdown.scandown.toFixed(2)}</td>
                                                    </tr>
                                                  ))}
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            {subgroup.items.map((item, index) => (
                                              <div key={`upc-${item.upc}-${index}`} className={classes.propertyDetails}>
                                                <label>{item.upc}</label>
                                                <span>{item.description}</span>
                                              </div>
                                            ))}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
                {contractExport.modules.length > 0 && (
                  <>
                    <div className={cx(classes.subTitle, classes.sectionSpace)}>Modules</div>
                    {contractExport?.modules.map((module, mIdx) => {
                      return (
                        <div className={classes.subSectionSpaceWrapper}>
                          <div className={classes.subSectionSpace} key={`module-${mIdx}`}>
                            <div className={classes.checkboxWrapperModule}>
                              <Checkbox
                                id={`ModuleSelected-${mIdx}`}
                                checked={selectedModules.findIndex((q) => q === module.uniqueId) > -1}
                                onChange={(checked: boolean) => {
                                  setModuleSelected(module.uniqueId, checked);
                                }}
                              />
                            </div>
                            <div>
                              <div className={classes.propertyDetails}>
                                <div className={classes.smallTitle}>{module.moduleNumber}</div>
                              </div>
                              <div>
                                <div className={classes.propertyDetails}>
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <table>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <table className={classes.gridTable}>
                                                    <thead>
                                                      <tr>
                                                        <th>SRP</th>
                                                        <th>Case List Cost</th>
                                                        <th>OI Allow</th>
                                                        <th>Billback</th>
                                                        <th>CMAP</th>
                                                        <th>Scandown</th>
                                                        <th>Ad Start Date</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>
                                                          {module.multiple}/{module.srp}
                                                        </td>
                                                        <td>{module.caseListCost?.toFixed(2)}</td>
                                                        <td>{module.oiAllow?.toFixed(2)}</td>
                                                        <td>{module.billback?.toFixed(2)}</td>
                                                        <td>{module.cmap?.toFixed(2)}</td>
                                                        <td>{module.scandown?.toFixed(2)}</td>
                                                        <td>{format(getUtcDate(module.adStartDate), 'M/d/yyyy')}</td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <table className={classes.gridTable}>
                                            <thead>
                                              <tr>
                                                <th>Start</th>
                                                <th>Vendor</th>
                                                <th>Department</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>{format(getUtcDate(module.adStartDate), 'M/d/yyyy')}</td>
                                                <td>{module.supplierName}</td>
                                                <td>{module.department}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <table className={classes.gridTable}>
                                            <thead>
                                              <tr>
                                                <th>UPC</th>
                                                <th>Case</th>
                                                <th>Pack</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {module.items.map((item) => (
                                                <Fragment key={`ModuleItem-${mIdx}-${item.upc}`}>
                                                  <tr>
                                                    <td>{item.upc}</td>
                                                    <td>{item.cases.toFixed(2)}</td>
                                                    <td>{item.pack}</td>
                                                  </tr>
                                                  <tr>
                                                    <td colSpan={3}>{item.description}</td>
                                                  </tr>
                                                </Fragment>
                                              ))}
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
              <div className={classes.footerContainer}>
                <Button
                  id={`export-${id}-submit`}
                  variant="rounded-sides"
                  className={classes.exportButton}
                  text={exporting ? 'Exporting ...' : 'Export'}
                  onClick={handleExport}
                  disabled={exportDisabled}
                  startIcon={exporting && <Spinner id={'export'} size={20} />}
                ></Button>
              </div>
            </>
          )}
        </div>
      </PageBody>
      <ConfirmationDialog
        title={'Unsaved changes'}
        message={`Are you sure you want to continue?`}
        open={showUnsavedChanges}
        onAccept={() => navigate(RouteEnum.Dashboard)}
        onCancel={() => setShowUnsavedChanges(false)}
        onClose={() => setShowUnsavedChanges(false)}
        acceptLabel="Yes"
        cancelLabel="No"
      ></ConfirmationDialog>
    </>
  );
}
