/* eslint-disable no-console */
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Outlet } from 'react-router-dom';
import { useApplicationContextState } from '../../contexts/ApplicationContext';
import { RouteEnum } from './PageRouter';
import { defaultColors } from '../../styles/variables';
import { DIERBERGS_TENANT_ID, b2cPolicies, defaultLoginRequest } from '../../authConfig';
import {
  AccountInfo,
  AuthenticationResult,
  ClientConfigurationError,
  ClientConfigurationErrorCodes,
  EndSessionRequest,
  EventError,
  EventMessage,
  EventPayload,
  EventType,
  InteractionType,
  ServerError,
} from '@azure/msal-browser';
import { useCallback, useEffect, useMemo } from 'react';
import { authenticationService, referenceDataService } from '../../services';
import { HttpErrorResponse } from '../../services/contractHubApi';
import ErrorBox from '../components/shared/ErrorBox';
import { DbgLoadingSpinner } from '../components/shared/DbgLoadingSpinner';
import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react';
import { UserType } from '../../models/enums';

export default function AuthenticatedLayout() {
  const { enqueueSnackbar } = useSnackbar();
  const { accounts, instance } = useMsal();
  const { setUser, user } = useApplicationContextState();
  const { setReferenceData, referenceData, loginInProgress, setLoginInProgress } = useApplicationContextState();

  const isLoginCancellation = (error: EventError) => {
    // https://learn.microsoft.com/en-us/azure/active-directory-b2c/error-codes
    const b2cUserLoginCancellationErrorCode = 'AADB2C90091';
    return error instanceof ServerError && error.message.includes(b2cUserLoginCancellationErrorCode);
  };

  const isAuthenticationResult = (payload: EventPayload): payload is AuthenticationResult => {
    return (payload as AuthenticationResult) !== null && typeof payload === 'object';
  };

  async function logout(account: AccountInfo) {
    const isInternalUser = account.tenantId === DIERBERGS_TENANT_ID;
    const internalLogoutEndpoint = `https://login.microsoftonline.com/${DIERBERGS_TENANT_ID}/oauth2/v2.0/logout`;

    const postLogoutRedirectUri = isInternalUser ? internalLogoutEndpoint : RouteEnum.Dashboard;

    const request: EndSessionRequest = { account: account, postLogoutRedirectUri };
    await instance.logoutRedirect(request);
  }

  useEffect(() => {
    // This will be run on component mount
    instance.enableAccountStorageEvents();
    //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md
    const callbackId = instance.addEventCallback((message: EventMessage) => {
      // This will be run every time an event is emitted after registering this callback
      switch (message.eventType) {
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.LOGIN_SUCCESS:
          if (!user) {
            if (isAuthenticationResult(message.payload))
              logUserIntoApi(message.payload.tenantId, message.payload.accessToken).then((success: boolean) => {
                if (!success) {
                  //logout user out of B2C if they don't have access to the API.
                  console.error('Authenticated user unable to log into ContractHub api. See api logs for more details.');
                  const authResult = message.payload as AuthenticationResult;
                  logout(authResult.account);
                }
              });
          }
          break;
        case EventType.ACQUIRE_TOKEN_FAILURE:
          //send user back to sign-in screen
          instance.logout({
            ...defaultLoginRequest,
            authority: b2cPolicies.authorities.magicLinkSignInPolicy.authority,
            postLogoutRedirectUri: RouteEnum.Dashboard,
          });
          break;
        case EventType.LOGIN_FAILURE:
          //send user back to sign-in screen if 'cancel' is pressed.
          if (isLoginCancellation(message.error)) instance.loginRedirect(defaultLoginRequest);
          break;
        default:
          break;
      }
    });

    return () => {
      // This will be run on component unmount
      instance.disableAccountStorageEvents();
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (referenceData) return;

      const response = await referenceDataService.getAll();
      if (response instanceof HttpErrorResponse) {
        const errorText = 'Unable to load reference data.';

        console.log(errorText);
        enqueueSnackbar(errorText, { variant: 'error' });
        return;
      }
      setReferenceData?.(response);
    })();
  }, []);

  const logUserIntoApi = useCallback(async (accountTenantId: string, accessToken: string) => {
    let isApiLoginSuccess = false;

    setLoginInProgress?.(true);
    const authResponse = await authenticationService.login(accessToken);
    if (authResponse instanceof HttpErrorResponse) {
      console.log('Login unsuccessful or unauthorized.');
    } else if (setUser) {
      authResponse.userType = accountTenantId === DIERBERGS_TENANT_ID ? UserType.Internal : UserType.External;
      setUser(authResponse);
      isApiLoginSuccess = true;
    }
    setLoginInProgress?.(false);
    return isApiLoginSuccess;
  }, []);

  const authenticationRequest = useMemo(() => {
    if (accounts[0]) {
      const tokenRefreshEndpoint =
        accounts[0].tenantId === DIERBERGS_TENANT_ID ? defaultLoginRequest.authority : b2cPolicies.authorities.magicLinkSignInPolicy.authority;
      return { ...defaultLoginRequest, authority: tokenRefreshEndpoint };
    }
    return defaultLoginRequest;
  }, [accounts]);

  function LoginInProgressIndicator() {
    return (
      <Box sx={styles.loginInProgress}>
        <DbgLoadingSpinner id={'spinner'} sx={styles.loading.spinner} />
        <Box>Please wait while we log you in.</Box>
      </Box>
    );
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authenticationRequest}
      loadingComponent={() => (
        <Box sx={styles.loading}>
          <DbgLoadingSpinner sx={styles.loading.spinner} id={'spinner'} />
        </Box>
      )}
      errorComponent={(response) => {
        //Don't display error message. User will be redirected to login screen.
        if (isLoginCancellation(response.error)) return <></>;

        if (response?.error instanceof ClientConfigurationError && response?.error.errorCode === ClientConfigurationErrorCodes.authorityMismatch) {
          console.log(ClientConfigurationErrorCodes.authorityMismatch);
          //This type of error should not occur once we fully migrated to sign-in
          //via b2c tenant. This error occurs if a user had previously signed in
          //on the app pre-b2c implementation.
          instance.logout();
        }
        return <ErrorBox message={response?.error?.message ?? 'Unable to sign in.'} />;
      }}
    >
      <Box sx={styles.root}>{loginInProgress || !user ? <LoginInProgressIndicator /> : <Outlet />}</Box>
    </MsalAuthenticationTemplate>
  );
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    width: '100%',
    backgroundColor: defaultColors.white,
  },
  loading: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    spinner: { margin: '10px', color: defaultColors.blue },
  },
  loginInProgress: { display: 'flex', alignItems: 'center', marginTop: '300px', flexDirection: 'column' },
} as const;
