import { EmailInput, FormDialog, PhoneInput, TextInput, Select } from '@dierbergs-markets/react-component-library';
import { useStyles } from 'tss-react';
import { IExternalAccountUser, IUserGroup } from '../../../../../models';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Joi from 'joi';
import { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { accountUserService } from '../../../../../services/account/accountUserService';
import { HttpErrorResponse, SuccessOrErrorResponse } from '../../../../../services/contractHubApi';
import { useSnackbar } from 'notistack';
import { defaultColors } from '../../../../../styles/variables';

interface IProps {
  externalAccountId: number;
  initialUser: IExternalAccountUser;
  userGroups: IUserGroup[];
  onAccept: () => void;
  onCancel: () => void;
}

export default function EditExternalAccountUserModal(props: IProps) {
  const { css } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [saving, setSaving] = useState<boolean>(false);

  const canEditUser =
    props.initialUser.userId === 0 || (props.userGroups.find((q) => q.userGroupId === props.initialUser.userGroupId)?.isAuthorized ?? false);

  const schema = Joi.object({
    firstName: Joi.string().required().messages({ 'string.empty': 'First name is required.' }),
    lastName: Joi.string().required().messages({ 'string.empty': 'Last name is required.' }),
    emailAddress: Joi.string().required().messages({ 'string.empty': 'Email is required.' }),
    userGroupId: Joi.number().greater(0).message('User Group is required.'),
  });

  const resolver = joiResolver(schema, { abortEarly: false, allowUnknown: true }, { mode: 'async' });

  const isNewForm = useMemo(() => props.initialUser.userId === 0, [props.initialUser.userId]);

  const defaultValues: IExternalAccountUser = { ...props.initialUser };

  const onSubmit: SubmitHandler<IExternalAccountUser> = (data: IExternalAccountUser) => handleConfirm(data);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<IExternalAccountUser>({ defaultValues, resolver });

  const handleConfirm = async (data: IExternalAccountUser) => {
    setSaving(true);
    let response: SuccessOrErrorResponse;

    if (props.initialUser.userId > 0) {
      response = await accountUserService.updateAccountUser({
        userId: props.initialUser.userId,
        externalAccountId: props.externalAccountId,
        userGroupId: data.userGroupId,
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: props.initialUser.emailAddress, // cannot change on update
        phoneNumber: data.phoneNumber,
        phoneNumberExt: data.phoneNumberExt,
      });
    } else {
      response = await accountUserService.createAccountUser({
        userId: 0,
        externalAccountId: props.externalAccountId,
        userGroupId: data.userGroupId,
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.emailAddress,
        phoneNumber: data.phoneNumber,
        phoneNumberExt: data.phoneNumberExt,
      });
    }

    setSaving(false);
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to save user.', { variant: 'error' });
      setError('root', { message: response.message });
      return;
    } else {
      enqueueSnackbar('User saved.', { variant: 'success' });
      response && props.onAccept();
    }
  };

  return (
    <FormDialog
      title={`${isNewForm ? 'Create' : 'Edit'} Account User`}
      classes={{
        root: css({ width: '700px' }),
        content: css({ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }),
      }}
      acceptLabel={'Save'}
      cancelLabel={'Cancel'}
      onAccept={handleSubmit(onSubmit)}
      onClose={props.onCancel}
      onCancel={props.onCancel}
      open={true}
      acceptDisabled={!canEditUser}
      acceptLoading={saving}
    >
      <Box sx={styles.formRow}>
        <Box sx={styles.formCell}>
          <Controller
            control={control}
            name={'firstName'}
            render={({ field }) => (
              <TextInput
                id={'firstName'}
                label={'First Name'}
                value={field.value}
                onChange={field.onChange}
                errorMessage={errors.firstName?.message}
                width={'100%'}
                classes={{
                  root: css(styles.input),
                }}
                disabled={!canEditUser}
              />
            )}
          />
        </Box>
        <Box sx={styles.formCell}>
          <Controller
            control={control}
            name={'lastName'}
            render={({ field }) => (
              <TextInput
                id={'lastName'}
                label={'Last Name'}
                value={field.value}
                onChange={field.onChange}
                errorMessage={errors.lastName?.message}
                width={'100%'}
                classes={{
                  root: css(styles.input),
                }}
                disabled={!canEditUser}
              />
            )}
          />
        </Box>
      </Box>
      <Box sx={styles.formRow}>
        <Box sx={styles.formCell}>
          <Controller
            control={control}
            name={'emailAddress'}
            render={({ field }) => (
              <EmailInput
                disabled={props.initialUser.userId > 0 || !canEditUser}
                id={'emailAddress'}
                label={'Email Address'}
                value={field.value}
                onChange={field.onChange}
                errorMessage={errors.emailAddress?.message}
                title={props.initialUser.userId > 0 ? 'Email address may not be changed after creation.' : ''}
                classes={{
                  root: css(styles.input),
                }}
              />
            )}
          />
        </Box>
        <Box sx={styles.formCell}>
          <Controller
            control={control}
            name={'userGroupId'}
            render={({ field }) => (
              <Select
                id={''}
                label="User Group"
                items={Object.values(props.userGroups || []).map((i) => {
                  return { text: `${i.name}`, value: i.userGroupId, disabled: !i.isAuthorized };
                })}
                onChange={field.onChange}
                value={field.value || ''}
                errorMessage={errors.userGroupId?.message}
                classes={{
                  root: css(styles.input),
                  errorText: css({ bottom: '-12px !important' }),
                }}
                disabled={!canEditUser}
              />
            )}
          />
        </Box>
      </Box>
      <Box sx={styles.formRow}>
        <Box sx={[styles.formCell, { display: 'flex', justifyContent: 'center' }]}>
          <Controller
            control={control}
            name={'phoneNumber'}
            render={({ field }) => (
              <PhoneInput
                id={'phoneNumber'}
                label={'Phone Number'}
                value={field.value}
                onChange={field.onChange}
                errorMessage={errors.phoneNumber?.message}
                classes={{
                  root: css([styles.phone]),
                }}
                disabled={!canEditUser}
              />
            )}
          />
          <Controller
            control={control}
            name={'phoneNumberExt'}
            render={({ field }) => (
              <TextInput
                id={'phoneNumberExt'}
                label={'Extension'}
                value={field.value}
                onChange={field.onChange}
                errorMessage={errors.phoneNumberExt?.message}
                classes={{
                  root: css(styles.phoneExtension),
                }}
                disabled={!canEditUser}
              />
            )}
          />
        </Box>
      </Box>
      <Box sx={styles.error}>{errors.root?.message}</Box>
    </FormDialog>
  );
}
const styles = {
  formRow: {
    marginBottom: '30px',
    width: '100%',
    minHeight: '60px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  error: {
    color: defaultColors.red,
    height: '26px',
  },
  formCell: {
    padding: '10px',
    width: '100%',
  },
  accountName: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '24px',
  },
  input: {
    width: '100%',
    height: '60px',
  },
  phone: {
    height: '60px',
    borderTopRightRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
  },
  phoneExtension: {
    borderTopLeftRadius: '0px !important',
    borderBottomLeftRadius: '0px !important',
    width: '80px',
    height: '60px',
  },
};
