import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/index.css';
import reportWebVitals from './reportWebVitals';
import App from './views/App';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { mainTheme } from './styles/themes';

LicenseInfo.setLicenseKey('5363630e2c776ae64a739c92b3855ddfTz05MTYzMSxFPTE3NDg3MDQ4NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement /*document.getElementById("root") */ /*as HTMLElement*/);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
            <CssBaseline />
            <App pca={msalInstance} />
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
