import { ICustomer } from '../models';
import { HttpErrorResponse, fetchGetJson } from './contractHubApi';

export const customerService = {
  searchCustomerId,
  selectById,
};

function searchCustomerId(customerId: string): Promise<ICustomer[] | HttpErrorResponse> {
  return fetchGetJson<ICustomer[]>({ endpoint: 'customer', query: { q: customerId } });
}

function selectById(customerId: string): Promise<ICustomer | HttpErrorResponse> {
  return fetchGetJson<ICustomer>({ endpoint: `customer/${customerId}` });
}
