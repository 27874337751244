import { useCallback, useContext } from 'react';
import { IContractTask } from '../../../../../models';
import { TaskRootContext } from '../TasksButton';
import { ContractTaskItem } from './ContractTaskItem';

export function ContractTasks() {
  const taskRootContext = useContext(TaskRootContext);

  const filterTasks = useCallback((task: IContractTask) => (taskRootContext.showAll ? true : !!task.resolution?.user), [taskRootContext.showAll]);

  return (
    <>
      {taskRootContext.contractTasks.filter(filterTasks).map((task: IContractTask) => (
        <ContractTaskItem key={task.contractTaskId} task={task} />
      ))}
    </>
  );
}
