import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { IAllContractsSearchResponse } from '../../../../../models/responses';
import { DataGrid, DataGridFilterField, DataGridRowModel } from '@dierbergs-markets/react-component-library';
import { RouteEnum } from '../../../../layout/PageRouter';
import { useNavigate } from 'react-router-dom';
import AdvancedSearchCustomFilterFormComponent from '../components/AdvancedSearchCustomFilterFormComponent';
import { IAdvSearch, IContractTimeFrame } from '../../../../../models';
import { contractSearchService } from '../../../../../services';
import { useSnackbar } from 'notistack';
import { IAllContractsSearchRequest } from '../../../../../models/requests';
import { ContractsGridLocalState, useContractsGridLocalStore } from '../../../../../store';
import useCommonSearchGridConfig, { CommonSearchDataGridFields } from '../shared/useCommonSearchGridConfig';

interface IProps {
  timeFrame: IContractTimeFrame;
  setDateRangePickerDisabled: Dispatch<SetStateAction<boolean>>;
}

const AllContractsGrid = memo((props: IProps) => {
  //HOOKS
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { advSearchInputs, setAdvSearchInputs, dataGridFilters, setDataGridFilters }: ContractsGridLocalState = useContractsGridLocalStore();
  const { defaultDataGridProps, defaultPageSize, defaultSortConfig, setDataOnScroll, searchQuery, setSearchQuery } = useCommonSearchGridConfig<
    IAllContractsSearchRequest,
    IAllContractsSearchResponse
  >({
    gridId: 'contractsDataGrid',
    timeFrame: props.timeFrame,
    performSearch: performSearch,
    handleSearchError: (message) => enqueueSnackbar(message, { variant: 'error' }),
  });

  //USE EFFECTS
  useEffect(() => {
    props.setDateRangePickerDisabled(
      !!getFilterValue(CommonSearchDataGridFields.ContractId) || !!getFilterValue(CommonSearchDataGridFields.VendorContractNumber)
    );
  }, [props.setDateRangePickerDisabled, dataGridFilters]);

  // COMPUTED FIELDS
  const isSearchingForSpecificContract =
    !!getFilterValue(CommonSearchDataGridFields.ContractId) || !!getFilterValue(CommonSearchDataGridFields.VendorContractNumber);

  //ASYNC FUNCTIONS
  async function performSearch(currentQuery: IAllContractsSearchRequest, signal: AbortSignal) {
    const extendedQuery = searchQueryWithGridFilters(currentQuery);
    return await contractSearchService.searchAllContracts(extendedQuery, signal);
  }

  //FUNCTIONS

  function getFilterValue(name: string): string | undefined {
    const filter = dataGridFilters?.find((q) => q.field === name);
    if (filter) return filter.filter;

    return undefined;
  }

  function searchQueryWithGridFilters(currentQuery: IAllContractsSearchRequest): IAllContractsSearchRequest {
    const newSearchQuery = { ...currentQuery, pageSize: defaultPageSize } as IAllContractsSearchRequest;
    newSearchQuery.categoryManagerNameFragment = getFilterValue(CommonSearchDataGridFields.CategoryManagerName);
    newSearchQuery.customerIdFragment = getFilterValue(CommonSearchDataGridFields.CustomerId);
    newSearchQuery.customerNameFragment = getFilterValue(CommonSearchDataGridFields.CustomerName);
    newSearchQuery.supplierIdFragment = getFilterValue(CommonSearchDataGridFields.SupplierId);
    newSearchQuery.supplierNameFragment = getFilterValue(CommonSearchDataGridFields.SupplierName);
    newSearchQuery.vendorContractNumberFragment = getFilterValue(CommonSearchDataGridFields.VendorContractNumber);
    newSearchQuery.productLineFragment = getFilterValue(CommonSearchDataGridFields.ProductLine);
    newSearchQuery.commentsFragment = getFilterValue(CommonSearchDataGridFields.Comments);
    newSearchQuery.contractIdFragment = getFilterValue(CommonSearchDataGridFields.ContractId);
    newSearchQuery.manufacturer = getFilterValue(CommonSearchDataGridFields.Manufacturer);
    newSearchQuery.stateText = getFilterValue(CommonSearchDataGridFields.StateText);

    newSearchQuery.upc = null;
    newSearchQuery.orderCode = '';

    if (advSearchInputs.upc) {
      newSearchQuery.upc = isNaN(parseInt(advSearchInputs.upc)) ? null : parseInt(advSearchInputs.upc);
    } else if (advSearchInputs.orderCode) {
      newSearchQuery.orderCode = advSearchInputs.orderCode;
    }
    newSearchQuery.brandFragment = advSearchInputs?.brand?.name ?? '';
    newSearchQuery.descriptionFragment = advSearchInputs?.description ?? '';

    if (newSearchQuery.sortConfig?.length === 0) {
      newSearchQuery.sortConfig = defaultSortConfig;
    }

    if (isSearchingForSpecificContract) {
      newSearchQuery.startDate = null;
      newSearchQuery.endDate = null;
    }

    return newSearchQuery;
  }

  function handleFilterChange(filters: DataGridFilterField[], customModel: IAdvSearch) {
    setDataOnScroll([]); //reset grid data before triggering server call
    setDataGridFilters(filters);
    setAdvSearchInputs(customModel ?? { upc: '', brand: null, description: '', orderCode: '', isSearchExecuted: true });
    setSearchQuery({ ...searchQuery, pageNumber: 0 });
  }

  return (
    <DataGrid
      {...defaultDataGridProps}
      getRowId={(r: IAllContractsSearchResponse) => r?.contractId ?? uuid()}
      onRowClick={(row: DataGridRowModel) => navigate(`${RouteEnum.Contract}/${row.contractId}`)}
      preferences={{
        ...defaultDataGridProps.preferences,
        filters: dataGridFilters,
        customFilters: advSearchInputs,
      }}
      onFilterChange={handleFilterChange}
      CustomFilterFormComponent={AdvancedSearchCustomFilterFormComponent}
    />
  );
});

export default AllContractsGrid;
