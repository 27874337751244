import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro-v5';
import { AdapterDateFns } from '@mui/x-date-pickers-pro-v5/AdapterDateFns';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {
  TextFieldProps,
  TextField,
  OutlinedInputProps,
  SxProps,
  inputAdornmentClasses,
  iconButtonClasses,
  svgIconClasses,
  formHelperTextClasses,
  Box,
} from '@mui/material';
import { defaultColors } from '../../../styles/variables';
import { textfieldStyles } from '../../../styles/shared/TextFieldStyles';
import { useState } from 'react';
import { useMemoForAutoCompleteValue } from '@dierbergs-markets/react-component-library';

interface IProps {
  id: string;
  label: string;
  value: any;
  onChange: (value: any) => void;
  required?: boolean;
  sx?: SxProps;
  disabled?: boolean;
  errormessage?: string;
  gridPosition?: string;
  minimumDate?: Date;
  warningmessage?: string;
}

const styles = {
  root: {
    [`& .${inputAdornmentClasses.positionEnd}`]: {
      marginRight: '8px',
      marginTop: '8px',
      [`& .${iconButtonClasses.root}`]: {
        padding: '0px',
        margin: '0px',
        '&:hover': { backgroundColor: 'unset' },
      },
      [`& .${svgIconClasses.root}`]: {
        color: defaultColors.blue,
        width: '18px',
      },
    },
    [`& .${formHelperTextClasses.root}`]: {
      position: 'relative',
      height: '0px',
      top: '6px',
    },
  },
  error: {
    ...textfieldStyles.textFieldError,
  },
  warning: {
    ...textfieldStyles.textFieldWarning,
  },
};

const DbgDatePicker = (props: IProps) => {
  const sxProps = Array.isArray(props.sx) ? props.sx : [props.sx];
  const [focus, setFocus] = useState<boolean>(false);
  const autoCompleteValue = useMemoForAutoCompleteValue();

  const getGridPositionStyles = () => {
    if (props.gridPosition) {
      if (props.gridPosition === 'left') {
        return textfieldStyles.gridLeft;
      }

      if (props.gridPosition === 'right') {
        return textfieldStyles.gridRight;
      }
    }

    return null;
  };

  const minDate = props.minimumDate && new Date(props.minimumDate.getFullYear(), props.minimumDate.getMonth(), props.minimumDate.getDate());

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          inputFormat={focus ? 'MM/dd/yy' : 'MM/dd/yyyy'}
          label={props.label}
          disableHighlightToday
          shouldDisableDate={(day: Date) => {
            if (!minDate) return false;
            return minDate > new Date(day.getFullYear(), day.getMonth(), day.getDate());
          }}
          OpenPickerButtonProps={{
            tabIndex: -1,
          }}
          components={{
            OpenPickerIcon: CalendarMonthOutlinedIcon,
          }}
          renderInput={(txtProps: TextFieldProps) => (
            <TextField
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              id={`txt${props.id}`}
              {...txtProps}
              sx={[
                textfieldStyles.textField,
                ...sxProps,
                focus && textfieldStyles.textFieldFocus,
                getGridPositionStyles(),
                !!props.errormessage && styles.error,
                !!props.warningmessage && !props.errormessage && styles.warning,
                styles.root,
              ]}
              disabled={props.disabled}
              required={props.required}
              InputLabelProps={{ sx: textfieldStyles.inputLabel, shrink: true }}
              InputProps={
                {
                  sx: [textfieldStyles.input],
                  disableUnderline: true,
                  ...txtProps.InputProps,
                } as Partial<OutlinedInputProps>
              }
              error={Boolean(props.errormessage)}
              helperText={props.errormessage ?? props.warningmessage}
              autoComplete={autoCompleteValue}
            />
          )}
          onChange={(newValue) => {
            props.onChange(newValue);
          }}
          value={props.value}
        />
      </LocalizationProvider>
    </Box>
  );
};
export default DbgDatePicker;
