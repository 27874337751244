import { Box, Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, outlinedInputClasses } from '@mui/material';
import Select, { selectClasses } from '@mui/material/Select';
import React from 'react';
import { IStore, IStoreGroup, IStoreSelection } from '../../../../../models';
import { textfieldStyles } from '../../../../../styles/shared/TextFieldStyles';
import { defaultBorders, defaultColors } from '../../../../../styles/variables';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';

interface IProps {
  id: string;
  label: string;
  selections: IStoreSelection;
  options: { stores: IStore[]; storeGroups: IStoreGroup[] };
  onSelectStore: (selection: number) => void;
  onSelectStoreGroup: (selection: number) => void;
  errormessage?: string;
  tabIndex: number;
}

const styles = {
  focus: {
    [`& .${outlinedInputClasses.root}`]: {
      '&.Mui-focused': {
        boxShadow: `0 0 0 2px ${defaultColors.blue} !important`,
        '& > fieldset': {
          border: 'none',
        },
      },
    },
  },
  selectOverrides: {
    width: '242px',
    height: '60px',
    borderRadius: '6px',
    boxShadow: defaultBorders.boxShadow,
    [`& .${selectClasses.select}`]: {
      padding: 'unset',
      textAlign: 'left',
      marginLeft: '11px',
      marginTop: '22px',
    },
    [`& .${selectClasses.icon}`]: {
      marginTop: '10px',
      color: defaultColors.blue,
    },
    '& fieldset': {
      border: 0,
      boxShadow: 0,
      borderWidth: '0px !important',
      '&.Mui-focused': {
        // borderWidth: '0px !important',
      },
      '& :hover': {
        border: 0,
        boxShadow: 0,
      },
    },
  },
  label: {
    ...textfieldStyles.inputLabel,
  },
  selection: {
    color: defaultColors.blue,
    fontSize: '15px',
  },
  selectWrapper: {
    height: '60px',
    borderRadius: '6px',
    boxShadow: defaultBorders.boxShadow,
    border: `1px solid ${defaultColors.blueGrey}`,
    '&:hover': {
      border: `1px solid ${defaultColors.darkBlueGrey}`,
      boxShadow: `0 1px 2px 0 ${defaultColors.transparentMediumGrey}`,
    },
  },
};

const NewContractModalStoreSelections = React.forwardRef<HTMLInputElement, IProps>((props: IProps, ref) => {
  const { referenceData } = useApplicationContextState();

  return (
    <FormControl sx={[styles.selectWrapper, styles.focus]}>
      <InputLabel id={`${props.id}_label`} sx={styles.label} shrink={true}>
        {props.label}
      </InputLabel>
      <Select
        id={props.id}
        inputRef={ref}
        variant="outlined"
        tabIndex={props.tabIndex}
        sx={[styles.selectOverrides]}
        inputProps={{ tabIndex: 6 }}
        multiple
        renderValue={() => {
          let selection = 'All Stores';
          if (props.selections.storeIds.length === 1) {
            selection = props.options.stores.find((s) => s.id === props.selections.storeIds[0])?.displayName || '';
          } else if (props.selections.storeIds.length > 1) {
            selection = 'Multiple Stores';
          } else if (props.selections.storeGroupIds.length > 0) {
            selection = referenceData?.storeGroups.byId[props.selections.storeGroupIds[0]].displayName ?? '';
          } else if (props.selections.storeIds.length === 0) {
            selection = 'No Stores';
          }
          return <Box sx={styles.selection}>{selection}</Box>;
        }}
        value={[...props.selections.storeIds, ...props.selections.storeGroupIds]}
      >
        {props.options.storeGroups &&
          props.options.storeGroups.map((storeGroup) => (
            <MenuItem key={-1 * storeGroup.id - 1} value={-1 * storeGroup.id - 1} onClick={() => props.onSelectStoreGroup(storeGroup.id)}>
              <Checkbox checked={props.selections.storeGroupIds.includes(storeGroup.id)} />
              <ListItemText primary={storeGroup.displayName} />
            </MenuItem>
          ))}
        {!!props.options.storeGroups?.length && <hr />}
        {props.options.stores &&
          props.options.stores.map((store) => (
            <MenuItem key={store.id} value={store.id} onClick={() => props.onSelectStore(store.id)}>
              <Checkbox checked={props.selections.storeIds.includes(store.id)} />
              <ListItemText primary={store.displayName} />
            </MenuItem>
          ))}
      </Select>
      {props.errormessage && <FormHelperText error>{props.errormessage}</FormHelperText>}
    </FormControl>
  );
});

export default NewContractModalStoreSelections;
