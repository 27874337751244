import {
  ArrayUtils,
  ColumnOrderMap,
  DataGridColDef,
  DataGridColumnVisibilityMap,
  DataGridProps,
  DataGridRowModel,
  DataGridSortDirection,
  DataGridSortField,
  IPagedResult,
  ISortConfig,
} from '@dierbergs-markets/react-component-library';
import ContractActionMenuButton from '../components/ContractActionMenuButton';
import { tss } from 'tss-react';
import { useEffect, useRef, useState } from 'react';
import { SortDirection } from '../../../../../models/enums';
import { IContractTimeFrame } from '../../../../../models';
import { format } from 'date-fns';
import { searchGridStyles } from '../styles';
import { HttpErrorResponse } from '../../../../../services/contractHubApi';
import { ICommonSearchQueryParams } from '../../../../../models/requests';

export const CommonSearchDataGridFields = {
  CategoryManagerName: 'categoryManagerName',
  CustomerId: 'customerId',
  CustomerName: 'customerName',
  SupplierId: 'supplierId',
  SupplierName: 'supplierName',
  VendorContractNumber: 'vendorContractNumber',
  Manufacturer: 'manufacturer',
  ProductLine: 'productLine',
  Comments: 'comments',
  ContractId: 'contractId',
  StartDate: 'startDate',
  StateText: 'stateText',
  Actions: 'actions',
};

/**
 * A type representing an object where the keys are strings and the values are partial
 * configurations for DataGrid columns.
 *
 * @typedef {Object.<string, Partial<DataGridColDef>>} CommonSearchDataGridColDefOverride
 * @property {string} key - The name of the column, used as the key in the object.
 * @property {Partial<DataGridColDef>} value - Partial definition for the DataGrid column configuration.
 * See {@link DataGridColDef}
 */
export type CommonSearchDataGridColDefOverride = { [key: string]: Partial<DataGridColDef> };

interface IProps<TQueryParams, R> {
  gridId: string;
  timeFrame: IContractTimeFrame;
  performSearch: (searchQuery: TQueryParams, signal: AbortSignal) => Promise<IPagedResult<R> | HttpErrorResponse>;
  handleSearchError: (message: string) => void;
}
export default function useCommonSearchGridConfig<TQueryParams extends ICommonSearchQueryParams, R = DataGridRowModel>(
  props: IProps<TQueryParams, R>
) {
  //VARS
  const LOCALSTORAGEKEY_COLUMN_VISIBILITY = `${props.gridId}.preferences.colVisibility`;
  const LOCALSTORAGEKEY_COLUMN_ORDER = `${props.gridId}.preferences.colOrder`;
  const localStorageVisibilityMap = localStorage.getItem(LOCALSTORAGEKEY_COLUMN_VISIBILITY);
  const localStorageColumnOrder = localStorage.getItem(LOCALSTORAGEKEY_COLUMN_ORDER);
  const defaultPageSize = 150;
  const defaultSortColumn = 'contractId';
  const defaultSortDirection = SortDirection.DESC;
  const defaultSortConfig = [{ sortColumns: defaultSortColumn, sortDirections: defaultSortDirection }];
  const gridDefaultSort: DataGridSortField[] = [{ field: defaultSortColumn, direction: defaultSortDirection as DataGridSortDirection }];
  const commonSearchGridColumns: DataGridColDef[] = [
    {
      field: CommonSearchDataGridFields.CategoryManagerName,
      headerName: 'Category Manager',
      type: 'string',
      width: 200,
      filterable: true,
      sortable: true,
    },
    {
      field: CommonSearchDataGridFields.CustomerId,
      headerName: 'Bill to Account #',
      type: 'string',
      width: 150,
      filterable: true,
      sortable: true,
    },
    {
      field: CommonSearchDataGridFields.CustomerName,
      headerName: 'Bill to Acct Name',
      width: 150,
      type: 'string',
      filterable: true,
      sortable: true,
    },
    {
      field: CommonSearchDataGridFields.SupplierId,
      headerName: 'Supplier #',
      width: 100,
      type: 'string',
      sortable: false,
      filterable: true,
      renderBodyCellContent: (x) => {
        return <>{x.value || '-'}</>;
      },
    },
    {
      field: CommonSearchDataGridFields.SupplierName,
      headerName: 'Supplier Name',
      width: 200,
      type: 'string',
      sortable: false,
      filterable: true,
      renderBodyCellContent: (x) => {
        return <>{x.value || '-'}</>;
      },
    },
    {
      field: CommonSearchDataGridFields.VendorContractNumber,
      headerName: 'Contract #',
      width: 270,
      type: 'string',
      filterable: true,
      sortable: true,
      hideable: false,
    },
    {
      field: CommonSearchDataGridFields.Manufacturer,
      headerName: 'Manufacturer',
      width: 200,
      type: 'string',
      filterable: true,
      sortable: true,
    },
    {
      field: CommonSearchDataGridFields.ProductLine,
      headerName: 'Product Line',
      flex: 0.5,
      type: 'string',
      filterable: true,
      sortable: true,
      minWidth: 110,
    },
    {
      field: CommonSearchDataGridFields.Comments,
      headerName: 'Comments',
      type: 'string',
      filterable: true,
      sortable: true,
      width: 200,
      renderBodyCellContent: (x) => {
        return (
          <div className={classes.comments} title={x.value}>
            {x.value}
          </div>
        );
      },
    },
    {
      field: CommonSearchDataGridFields.ContractId,
      headerName: 'Internal Contract #',
      width: 175,
      type: 'number',
      filterable: true,
      sortable: true,
      hideable: false,
    },
    {
      field: CommonSearchDataGridFields.StartDate,
      headerName: 'Min. Start Date',
      width: 150,
      filterable: false,
      type: 'string',
      sortable: true,
      renderBodyCellContent: (x) => format(new Date(x.value), 'MM/dd/yy'),
    },
    {
      field: CommonSearchDataGridFields.StateText,
      headerName: 'Status',
      width: 150,
      type: 'string',
      filterable: true,
      sortable: true,
    },
    {
      field: CommonSearchDataGridFields.Actions,
      headerName: '',
      type: 'string',
      filterable: false,
      sortable: false,
      width: 65,
      fixedAlignPosition: 'right',
      hideable: false,
      renderBodyCellContent: (x) => <ContractActionMenuButton contractId={x.row.contractId} />,
    },
  ];

  //MISC HOOKS
  const { classes } = useStyles();

  //REFs
  const controllerRef = useRef<AbortController | null>(null);

  //STATE
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [gridColumns, setGridColumns] = useState<DataGridColDef[]>(commonSearchGridColumns);
  const [dataOnScroll, setDataOnScroll] = useState<R[]>([]);
  const [searchQuery, setSearchQuery] = useState<TQueryParams>({
    pageNumber: 0,
    pageSize: defaultPageSize,
    startDate: props.timeFrame.startDate,
    endDate: props.timeFrame.startDate ? (props.timeFrame.endDate ?? null) : null,
    sortConfig: defaultSortConfig,
  } as TQueryParams);
  const [visibilityMap, setVisibilityMap] = useState<DataGridColumnVisibilityMap>(
    localStorageVisibilityMap ? JSON.parse(localStorageVisibilityMap) : defaultVisiblityMap
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderMap>(localStorageColumnOrder ? JSON.parse(localStorageColumnOrder) : {});

  //USE EFFECTS
  useEffect(() => {
    setSearchQuery({
      ...searchQuery,
      pageNumber: 0,
      startDate: props.timeFrame.startDate ?? searchQuery.startDate ?? null,
      endDate: props.timeFrame.endDate ?? searchQuery.endDate ?? null,
    });

    setDataOnScroll([]);
  }, [props.timeFrame]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const previousControllerRef = controllerRef.current;
      if (previousControllerRef && !previousControllerRef.signal.aborted) {
        previousControllerRef.abort();
      }
      controllerRef.current = new AbortController();
      const response = await props.performSearch(searchQuery, controllerRef.current.signal);
      controllerRef.current = null;

      if (response instanceof HttpErrorResponse) {
        if (!response.isCanceled) {
          props.handleSearchError('Unable to retrieve data.');
          setLoading(false);
        }
      } else {
        setLoading(false);
        if (searchQuery.pageNumber === 0) {
          setDataOnScroll(response.results);
        } else {
          setDataOnScroll((prev) => ArrayUtils.distinct([...prev, ...response.results]));
        }
        setTotalCount(response.totalResults);
      }
    })();
  }, [searchQuery]);

  //FUNCTIONS
  function handleColumnVisibilityChange(map: DataGridColumnVisibilityMap) {
    setVisibilityMap(map);
    localStorage.setItem(LOCALSTORAGEKEY_COLUMN_VISIBILITY, JSON.stringify(map));
  }

  function handleColumnOrderChange(order: ColumnOrderMap) {
    setColumnOrder(order);
    localStorage.setItem(LOCALSTORAGEKEY_COLUMN_ORDER, JSON.stringify(order));
  }

  function defaultVisiblityMap(): DataGridColumnVisibilityMap {
    return gridColumns.reduce((acc, cur, index) => {
      const fieldName = cur.field || `column_${index}`;

      if (cur.hideable === false) return acc;

      return { ...acc, [fieldName]: true };
    }, {});
  }

  /**
   * Generates a list of DataGrid columns by merging default column configurations with overrides.
   * @param {CommonSearchDataGridColDefOverride} [overrides={}] - An object containing overrides for specific DataGrid columns. 
   * The keys are the column field names, and the values are partial configurations to override the default column settings.
   * @returns {DataGridColDef[]} A list of DataGrid column definitions with the applied overrides.
   * See {@link DataGridColDef}, {@link CommonSearchDataGridColDefOverride}
   * 
   * @example 
     //To edit the width of categoryManager and customerName columns:
    const columnOverrides: CommonSearchDataGridColDefOverride = {
        [CommonSearchDataGridFields.CustomerName]: {
          headerName: 'New Header Name', //set different header name
        },
        [CommonSearchDataGridFields.CategoryManagerName]: {
          width: 400, //set different width
        },
      };

      //Pull setGridColumns, getCommonSearchDataGridColumns out of useCommonSearchGridConfig hook  
      const { getCommonSearchDataGridColumns, setGridColumns } = useCommonSearchGridConfig();
      
      useEffect(() => {
      setGridColumns([...getCommonSearchDataGridColumns(columnOverrides)]);
      }, []);
 */
  const getCommonSearchDataGridColumns = (overrides: CommonSearchDataGridColDefOverride = {}) => {
    return commonSearchGridColumns.map((column) => ({
      ...column,
      ...(overrides[column.field] || {}),
    }));
  };

  function handleSortingColumnChange(fields: DataGridSortField[]) {
    setDataOnScroll([]);

    const sortConfig: ISortConfig[] = [];

    for (const field of fields) {
      sortConfig.push({ sortColumns: field.field, sortDirections: field.direction });
    }

    setSearchQuery({ ...searchQuery, sortConfig: sortConfig, pageNumber: 0 });
  }

  function handleOnRowsScrollEnd() {
    if (dataOnScroll.length < totalCount) {
      setSearchQuery({ ...searchQuery, pageNumber: searchQuery.pageNumber + 1 });
    }
  }

  const defaultDataGridProps: DataGridProps = {
    id: props.gridId,
    columnOrderable: true,
    rows: dataOnScroll,
    columns: gridColumns,
    bodyRowHeight: 52,
    headerRowHeight: 56,
    isLoading: loading,
    cssOverrides: {
      root: searchGridStyles.gridOverrideRoot,
      header: searchGridStyles.gridOverrideHeader,
    },
    preferences: {
      columns: { visibilityMap: visibilityMap },
      columnOrder: columnOrder,
    },
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onColumnOrderChange: handleColumnOrderChange,
    onColumnSortChange: handleSortingColumnChange,
    onRowsScrollEnd: handleOnRowsScrollEnd,
    defaultSort: gridDefaultSort,
  };

  return {
    defaultDataGridProps,
    gridColumns,
    setGridColumns,
    searchQuery,
    setSearchQuery,
    setDataOnScroll,
    getCommonSearchDataGridColumns,
    defaultPageSize,
    defaultSortConfig,
    visibilityMap,
    columnOrder,
  };
}

const useStyles = tss.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    zIndex: 10,
    width: '100%',
  },
  comments: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
