import { useCallback, useContext, useRef } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';
import { ITaskAssigneeDisplay } from '../../../../../models';
import { ArrayUtils } from '../../../../../utilities/ArrayUtility';
import { IContractTaskCreationRequest } from '../../../../../models/requests';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

import { AutoComplete, Button, TextAreaInput } from '@dierbergs-markets/react-component-library';
import { TaskRootContext } from '../TasksButton';
import { Box } from '@mui/material';
import { contractTaskService } from '../../../../../services';
import { useStyles } from 'tss-react/mui';
import { textfieldStyles } from '../../../../../styles/shared/TextFieldStyles';
import { allowedKeys } from './ContractTasksCommon';

interface IContractTaskCreationForm {
  messageText: string;
  taskAssignee: ITaskAssigneeDisplay;
}
export interface ICreateContractTaskProps {
  closePortalOrCollapseCreateTask: () => void;
}
export function CreateContractTask(props: ICreateContractTaskProps) {
  const { css } = useStyles();
  const { closePortalOrCollapseCreateTask } = props;
  const { referenceData } = useApplicationContextState();
  const taskRootContext = useContext(TaskRootContext);
  const assignTaskToRef = useRef<HTMLInputElement>(null);
  const messageTextRef = useRef<HTMLTextAreaElement>(null);
  const taskAssigneeDisplaysOptions = (referenceData && referenceData.taskAssigneeDisplays.all) || [];

  const SearchTaskAssignTo = useCallback(
    (query: string): ITaskAssigneeDisplay[] => {
      let result: ITaskAssigneeDisplay[] = [];
      if (!taskAssigneeDisplaysOptions) return result;
      result =
        query.length > 0
          ? taskAssigneeDisplaysOptions.filter((q) => q.assigneeName.toLowerCase().includes(query.toLowerCase()))
          : taskAssigneeDisplaysOptions;

      return ArrayUtils.orderBy(result, (x) => x.assigneeName);
    },
    [taskAssigneeDisplaysOptions]
  );

  const defaultValues: IContractTaskCreationRequest = {
    messageText: '',
    taskAssigneeId: 0,
  };

  const schema = Joi.object({
    messageText: Joi.string().required().messages({ 'string.empty': 'Message is required.' }),
    taskAssignee: Joi.required().messages({ 'any.required': 'Assignee is required.' }),
  });
  const resolver = joiResolver(schema, { abortEarly: false, allowUnknown: true }, { mode: 'async' });

  const onSubmit: SubmitHandler<IContractTaskCreationForm> = ({ messageText, taskAssignee: { taskAssigneeId } }: IContractTaskCreationForm) => {
    contractTaskService.createContractTask(taskRootContext.contractId ?? 0, { messageText, taskAssigneeId }).then(() => {
      reset();
      closePortalOrCollapseCreateTask();
      taskRootContext.refreshContractTasks();
    });
  };
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm<IContractTaskCreationForm>({ defaultValues, resolver });

  return (
    <Box sx={styles.container}>
      <Controller
        control={control}
        name={'taskAssignee'}
        render={({ field }) => (
          <AutoComplete
            id={'taskAssignee'}
            label={'Assign task to'}
            inputRef={assignTaskToRef}
            initialValue={getValues('taskAssignee')}
            onSelected={field.onChange}
            queryOptions={SearchTaskAssignTo}
            valueExtractor={(option) => option?.taskAssigneeId ?? null}
            textExtractor={(option) => option?.assigneeName ?? ''}
            errormessage={errors.taskAssignee?.message}
            sx={styles.container.assignTo}
            optionRender={(option) => <Box>{option.assigneeName}</Box>}
            characterThreshold={1}
            textInputProps={{
              tabIndex: 1,
            }}
          />
        )}
      />
      <Controller
        control={control}
        name={'messageText'}
        render={({ field }) => (
          <TextAreaInput
            id="messageText"
            ref={messageTextRef}
            tabIndex={2}
            allowedkeys={allowedKeys}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && messageTextRef.current) {
                messageTextRef.current.focus();
              }
            }}
            placeholder={'Write a message...'}
            onChange={field.onChange}
            value={field.value || ''}
            errorMessage={errors.messageText?.message}
            rows={4}
            classes={{
              root: css({ ...(styles.container.message as any), ...(textfieldStyles.textInputHover as any) }),
              input: css(styles.container.message.input as any),
            }}
          />
        )}
      />
      <Box sx={styles.container.buttons}>
        <Button id="cancelCreateTask" text="Cancel" variant="link" color="grey" onClick={closePortalOrCollapseCreateTask}></Button>
        <Button id="cancelSubmitTask" text="Submit Task" variant="link" color="blue" onClick={handleSubmit(onSubmit)}></Button>
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    padding: '20px',
    assignTo: [
      {
        width: '100%',
        [`& .MuiFormControl-root.MuiFormControl-fullWidth`]: {
          height: 60,
        },
      },
    ],
    message: {
      marginTop: '25px',
      boxSizing: 'border-box',
      width: '100%',
      input: {
        ...textfieldStyles.textarea,
      },
    },
    buttons: {
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '0px 10px',
    },
  },
};
