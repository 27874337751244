import { IContractTask, IContractTaskComment } from '../../../../../models';
import { ContractTaskCommentItem } from './ContractTaskCommentItem';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useContext, useRef, useState } from 'react';
import { TaskRootContext } from '../TasksButton';
import { allowedKeys, commentResolver } from './ContractTasksCommon';
import { Button, TextInput } from '@dierbergs-markets/react-component-library';
import { Box } from '@mui/material';
import { defaultColors } from '../../../../../styles/variables';
import { contractTaskService } from '../../../../../services';
import { useStyles } from 'tss-react/mui';
import { textfieldStyles } from '../../../../../styles/shared/TextFieldStyles';

interface IContractTaskCommentCreationForm {
  comment: string;
}
const defaultValues: IContractTaskCommentCreationForm = {
  comment: '',
};
export interface IContractTaskItemProps {
  task: IContractTask;
}
export function ContractTaskItem(props: IContractTaskItemProps) {
  const { task } = props;
  const { css } = useStyles();
  const commentRef = useRef<HTMLInputElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const taskRootContext = useContext(TaskRootContext);

  const onSubmit: SubmitHandler<IContractTaskCommentCreationForm> = ({ comment }: IContractTaskCommentCreationForm) => {
    contractTaskService.createContractTaskComment(taskRootContext.contractId ?? 0, task.contractTaskId, comment).then(() => {
      reset();
      taskRootContext.refreshContractTasks();
    });
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IContractTaskCommentCreationForm>({ defaultValues, resolver: commentResolver });
  return (
    <Box sx={{ ...styles.container, ...(task.resolution ? {} : styles.container.blueBorder) }}>
      {task.comments.slice(0, isExpanded ? task.comments.length : 1).map((comment: IContractTaskComment, index: number) => (
        <ContractTaskCommentItem
          task={task}
          key={comment.contractTaskCommentId}
          isFirst={index === 0}
          comment={comment}
          setIsExpanded={setIsExpanded}
          isExpanded={isExpanded}
        />
      ))}
      {!task.resolution && isExpanded && (
        <Box sx={styles.container.addComment}>
          <Controller
            control={control}
            name={'comment'}
            render={({ field }) => (
              <TextInput
                id="comment"
                placeholder="Write a reply..."
                ref={commentRef}
                allowedkeys={allowedKeys}
                tabIndex={1}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && commentRef.current) {
                    commentRef.current.focus();
                  }
                }}
                onChange={field.onChange}
                value={field.value || ''}
                errorMessage={errors.comment?.message}
                classes={{
                  root: css({ ...(styles.container.addComment.comment as any), ...(textfieldStyles.textInputHover as any) }),
                  input: css(styles.container.addComment.comment.input as any),
                }}
              />
            )}
          />
          <Box sx={styles.container.addComment.buttons}>
            <Button id={`addComment-${task.contractTaskId}-clear`} text="Clear" variant="link" color="grey" onClick={() => reset()}></Button>
            <Button
              id={`addComment-${task.contractTaskId}-submit`}
              text="Submit"
              variant="link"
              color="blue"
              onClick={handleSubmit(onSubmit)}
            ></Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

const styles = {
  container: {
    '&:hover': {
      backgroundColor: defaultColors.altGrey,
    },
    blueBorder: { borderLeftColor: '#009BFF', borderLeftStyle: 'solid', borderLeftWidth: '5px' },
    addComment: {
      paddingLeft: '30px',
      paddingRight: '30px',
      paddingTop: '5px',
      paddingBottom: '5px',
      comment: {
        boxSizing: 'border-box',
        width: '100%',
        input: {
          ...textfieldStyles.textInputInput,
        },
      },
      buttons: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0px 10px',
      },
    },
  },
};
