import { Box } from '@mui/material';

import CircleLogo from '../../../images/d_circle_logo.png';
import { useApplicationContextState } from '../../../contexts/ApplicationContext';
import { StyledImg } from '../../../styles/styled/StyledWrappedCommonElements';
import { defaultColors } from '../../../styles/variables';
import ContractNotifications from '../components/ContractNotifications';
import { HTMLAttributes } from 'react';
import { EndSessionRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { DIERBERGS_TENANT_ID } from '../../../authConfig';
import { RouteEnum } from '../PageRouter';
import { Permission, UserType } from '../../../models/enums';
import PermissionFilter from '../../components/shared/PermissionFilter';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useNavigate } from 'react-router';
import { Button, IconButton } from '@dierbergs-markets/react-component-library';
import { useStyles } from 'tss-react';

interface PageHeaderProps extends HTMLAttributes<HTMLDivElement> {}
export default function PageHeader(props: React.PropsWithChildren<PageHeaderProps>) {
  const { children } = props;

  const navigate = useNavigate();
  const { user, loginInProgress } = useApplicationContextState();
  const { accounts, instance } = useMsal();

  const { css } = useStyles();

  function LogoutButton() {
    async function logout() {
      const isInternalUser = accounts[0].tenantId === DIERBERGS_TENANT_ID;
      const internalLogoutEndpoint = `https://login.microsoftonline.com/${DIERBERGS_TENANT_ID}/oauth2/v2.0/logout`;

      const postLogoutRedirectUri = isInternalUser ? internalLogoutEndpoint : RouteEnum.Dashboard;

      const request: EndSessionRequest = { account: accounts[0], postLogoutRedirectUri };
      await instance.logoutRedirect(request);
    }
    return user && !loginInProgress ? (
      <Button
        text={'Logout'}
        title={`Sign out ${user.userName}`}
        variant="link"
        className={css(styles.logoutBtn)}
        id={'lnkLogOut'}
        onClick={logout}
      />
    ) : (
      <></>
    );
  }

  function AdminButton() {
    return user && !loginInProgress ? (
      <PermissionFilter permission={Permission.UserManagementView}>
        <IconButton
          id={'NavigateToAdmin'}
          icon={<SupervisorAccountIcon />}
          onClick={navigateToAdminPage}
          classes={{ root: css(styles.adminBtn) }}
        ></IconButton>
      </PermissionFilter>
    ) : (
      <></>
    );
  }

  function navigateToAdminPage() {
    navigate(RouteEnum.Admin);
  }

  return (
    <Box sx={styles.root}>
      <StyledImg sx={styles.logo} src={CircleLogo} />
      <Box sx={styles.children}>{children}</Box>
      {user?.userType === UserType.Internal && <ContractNotifications />}
      <AdminButton />
      <LogoutButton />
    </Box>
  );
}
const styles = {
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 0,
    minHeight: '46px',
    maxHeight: '46px',
    paddingX: '10px',
    backgroundColor: defaultColors.white,
    borderBottom: `1px solid ${defaultColors.border}`,
  },
  children: {
    flexGrow: 1,
  },
  adminBtn: {
    '&&': {
      color: defaultColors.darkGrey,
      fontWeight: 500,
      fontSize: '13px',
      cursor: 'pointer',
      marginRight: '10px',
    },
  },
  logoutBtn: {
    '&&': {
      color: defaultColors.darkGrey,
      fontWeight: 500,
      fontSize: '13px',
      marginRight: '10px',
    },
  },
  logo: {
    width: '26px',
  },
} as const;
