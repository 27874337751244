import {
  dialogClasses,
  dialogTitleClasses,
  dialogContentClasses,
  dialogActionsClasses,
  inputAdornmentClasses,
  iconButtonClasses,
  svgIconClasses,
  formHelperTextClasses,
} from '@mui/material';
import { defaultColors, defaultBorders } from '../../../../../../styles/variables';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  termType: {
    marginBottom: '38px',
  },
  textFieldBorderOverride: {
    border: 'unset',
    borderRadius: 'unset',
    width: '50%',
    backgroundColor: 'unset',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formRow: {
    marginBottom: '35px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formRowWithMsg: {
    marginBottom: '130px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formRowSplit: {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  formRowMultifield: {
    border: `1px solid ${defaultColors.border}`,
    borderRadius: '6px',
    boxShadow: defaultBorders.boxShadow,
    display: 'flex',
    overflow: 'hidden',
  },
  dbgSelectOverrides: {
    width: '100%',
    height: '60px',
  },
  dbgDialogOverrides: {
    [`& .${dialogClasses.paper}`]: {
      minWidth: '550px',
      minHeight: '600px',
      paddingX: '85px',
    },
    [`& .${dialogTitleClasses.root}`]: {
      borderBottom: 'unset',
      marginTop: '45px',
      marginBottom: '25px',
      padding: '0px',
      fontSize: '25px',
    },
    [`& .${dialogContentClasses.root}`]: {
      padding: 'unset',
      overflow: 'visible', // required for input box shadow
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    [`& .${dialogActionsClasses.root}`]: {
      borderTop: 'unset',
      padding: 'unset',
      marginBottom: '0px',
      marginTop: '0px',
    },
  },
  datePicker: {
    [`& .${inputAdornmentClasses.positionEnd}`]: {
      marginRight: '8px',
      marginTop: '8px',
      [`& .${iconButtonClasses.root}`]: {
        padding: '0px',
        margin: '0px',
        '&:hover': { backgroundColor: 'unset' },
      },
      [`& .${svgIconClasses.root}`]: {
        color: defaultColors.blue,
        width: '18px',
      },
    },
    [`& .${formHelperTextClasses.root}`]: {
      position: 'relative',
      height: '0px',
      top: '6px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
    marginLeft: '20px',
    marginRight: '20px',
  },
  confirmButton: {
    width: '250px',
    margin: '12px',
  },
  cancelButton: {
    width: '250px',
    margin: '12px',
    border: '0px',
  },
};

export const ContractTermModalStyles = {
  styles,
};
