import { createPortal } from 'react-dom';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, CssPosition, ResizableDialog } from '@dierbergs-markets/react-component-library';
import { Box, Icon, Button as MuiButton } from '@mui/material';
import Tasks from '../../../../images/tasks.svg';

import { IContractTask } from '../../../../models';
import { HttpErrorResponse } from '../../../../services/contractHubApi';
import { defaultColors } from '../../../../styles/variables';
import { StyledImg } from '../../../components/shared/styled/StyledWrappedCommonElements';
import { enqueueSnackbar } from 'notistack';
import { contractTaskService } from '../../../../services';
import { CreateContractTask } from './dialogs/CreateContractTask';
import { useStyles } from 'tss-react';

const initialWindowSizeNoTasks = { height: 315, width: 375 };
const initialWindowSizeWithTasks = { height: 700, width: 375 };
const topAndBottomBarSize = 178;
const createTaskSize = 360;
const topBarSize = 40;
const initalBottonPosition = 150;
const initialRightPosition = 10;

function TasksDialog({ onClose, children, setShowAll, showAll }) {
  const taskRootContext = useContext(TaskRootContext);
  const [showCreateTask, setShowCreateTask] = useState(taskRootContext.contractTasks.length === 0);

  const hasTasks = taskRootContext.contractTasks.length > 0;
  const initialWindowSize = hasTasks ? initialWindowSizeWithTasks : initialWindowSizeNoTasks;
  const startPosition: CssPosition = {
    left: window.innerWidth - initialRightPosition - initialWindowSize.width,
    top: window.innerHeight - initalBottonPosition - initialWindowSize.height,
    right: initialRightPosition,
    bottom: initalBottonPosition,
  };

  const closePortalOrCollapseCreateTask = useCallback(() => {
    if (taskRootContext.contractTasks.length === 0) {
      taskRootContext.closePort();
    } else {
      setShowCreateTask(false);
    }
  }, [taskRootContext.contractTasks]);

  const hasAnyTasks = useMemo(() => taskRootContext.contractTasks.length > 0, [taskRootContext.contractTasks]);
  const contentHeight = useMemo(
    () => window.innerHeight - (hasAnyTasks ? (showCreateTask ? createTaskSize : topAndBottomBarSize) : topBarSize),
    [showCreateTask]
  );

  return (
    <ResizableDialog
      id={'tasksDialog'}
      open={true} // TasksButton handles the open flag so always using true here
      onClose={onClose}
      startPosition={startPosition}
      topBarSize={hasAnyTasks ? (showCreateTask ? createTaskSize : topAndBottomBarSize) : topBarSize}
    >
      {showCreateTask && <CreateContractTask closePortalOrCollapseCreateTask={closePortalOrCollapseCreateTask} />}
      {!showCreateTask && (
        <Box sx={styles.showCreateTask}>
          <MuiButton id="switchToCreateTask" onClick={() => setShowCreateTask(true)} sx={styles.showCreateTask.button}>
            Create a task...
          </MuiButton>
        </Box>
      )}
      <Box sx={{ ...styles.content, height: `${contentHeight}px` }}>{children}</Box>
      {hasAnyTasks && (
        <Box sx={styles.showBar}>
          <Box>Show:&nbsp;</Box>
          {showAll ? (
            <Button id="showAll" text="Resolved" variant="link" color="green" onClick={() => setShowAll(false)}></Button>
          ) : (
            <Button id="showResolved" text="All" variant="link" color="blue" onClick={() => setShowAll(true)}></Button>
          )}
        </Box>
      )}
    </ResizableDialog>
  );
}

export interface ITaskRootContextProvider {
  closePort: () => void;
  contractId?: number;
  contractTasks: IContractTask[];
  refreshContractTasks: () => void;
  showAll: boolean;
}

export const TaskRootContext = createContext<ITaskRootContextProvider>({
  closePort: () => void 0,
  contractId: undefined,
  contractTasks: [],
  showAll: true,
  refreshContractTasks: () => void 0,
});

interface IProps {
  children: any;
  contractId: number;
}

export default function TasksButton({ children, contractId }: IProps) {
  const { css } = useStyles();
  const [showTaskDialog, setShowTaskDialog] = useState(false);
  const [showAll, setShowAll] = useState(true);

  const closePort = useCallback(() => {
    setShowTaskDialog(false);
  }, []);
  const refreshContractTasks = async () => {
    const response = await contractTaskService.getContractTasks(contractId);
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to retrieve tasks.', { variant: 'error' });
    } else {
      setProvider({ ...provider, contractTasks: response });
    }
  };
  const [provider, setProvider] = useState<ITaskRootContextProvider>({
    closePort: closePort,
    contractId,
    contractTasks: [],
    refreshContractTasks,
    showAll: true,
  });

  useEffect(() => {
    (async () => {
      await refreshContractTasks();
    })();
  }, [contractId]);

  useEffect(() => {
    setProvider({ ...provider, contractId });
  }, [contractId]);
  useEffect(() => {
    setProvider({ ...provider, showAll });
  }, [showAll]);
  return (
    <>
      <Button
        id="tasksButton"
        variant={'rounded-sides'}
        startIcon={
          <Icon>
            <StyledImg src={Tasks} sx={{ width: '20px', height: '20px' }}></StyledImg>
          </Icon>
        }
        classes={{
          root: css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
        }}
        color={(provider?.contractTasks?.length ?? 0) > 0 ? 'blue' : 'black'}
        text="Tasks"
        onClick={() => setShowTaskDialog(true)}
      >
        Tasks
      </Button>
      {showTaskDialog &&
        createPortal(
          <TaskRootContext.Provider value={provider}>
            <TasksDialog onClose={closePort} setShowAll={setShowAll} showAll={showAll}>
              {children}
            </TasksDialog>
          </TaskRootContext.Provider>,
          document.body
        )}
    </>
  );
}

const styles = {
  showCreateTask: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '20px',
    button: {
      boxSizing: 'border-box',
      height: '44px',
      width: '100%',
      border: '1px solid #CCD4E5',
      borderRadius: '4px',
      backgroundColor: '#FFFFFF',
      justifyContent: 'flex-start',
    },
  },
  content: {
    overflow: 'auto',
  },
  showBar: {
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: defaultColors.grey,
    paddingLeft: '30px',
    paddingTop: '20px',
    paddingBottom: '15px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'white',
    fontSize: '14px',
  },
};
