export function getDaysDifference(startDate: Date, endDate: Date): number {
  // Reset the hours, minutes, seconds, and milliseconds for accurate day comparison
  const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

  // Calculate the difference in milliseconds
  const timeDifference: number = end.getTime() - start.getTime();

  // Convert the difference from milliseconds to days
  const daysDifference: number = timeDifference / (1000 * 60 * 60 * 24);

  return daysDifference;
}
