import React from 'react';
import { CircularProgress, CircularProgressProps } from '@mui/material';

interface IDbgLoadingSpinnerProps extends CircularProgressProps {
  id: string;
}

const DbgLoadingSpinner = (props: IDbgLoadingSpinnerProps) => {
  return <CircularProgress id={`spinner${props.id}`} sx={props.sx} color={props.color} size={props.size} />;
};

export { DbgLoadingSpinner };
