import React from 'react';
import { alpha, ExtendButtonBase, Theme } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import variables, { defaultColors } from '../../../styles/variables';
import { getWhiteButtonStyleProps } from '../../../styles/themes';
import { DbgButtonSize } from '../../../models/enums';

export interface IDbgRoundedButtonStyleProps {
  height?: string;
  minHeight?: string;
  lineHeight?: string;
  fontSize?: string;
  fontWeight?: string | number;
  width?: string;
  minWidth?: string;
  paddingLeft?: string;
  paddingRight?: string;
  backgroundColor: string;
  backgroundColorDisabled?: string;
  backgroundColorSelected?: string;
  backgroundColorActive?: string;
  color?: string;
  colorDisabled?: string;
  colorSelected?: string;
  colorActive?: string;
  borderColor?: string;
}

interface IDbgRoundedButtonProps extends ButtonProps, Omit<ExtendButtonBase<any>, 'classes' | 'color'> {
  dbgButtonSize?: DbgButtonSize;
  disabled?: boolean;
  selected?: boolean;
  styleProps?: IDbgRoundedButtonStyleProps;
  id: string;
}

const DbgRoundedButton = ({ dbgButtonSize, styleProps, id, sx = [], ...otherProps }: IDbgRoundedButtonProps) => {
  const defaultProps = getWhiteButtonStyleProps();
  const usedStyleProps = styleProps || defaultProps;
  const customCss = { ...usedStyleProps };

  switch (dbgButtonSize) {
    case DbgButtonSize.ExtraLarge:
      customCss.fontSize = '15px';
      customCss.height = '60px';
      customCss.lineHeight = '18px';
      customCss.paddingLeft = '26px';
      customCss.paddingRight = '26px';
      break;
    case DbgButtonSize.Large:
      customCss.height = '40px';
      customCss.lineHeight = '16px';
      customCss.paddingLeft = '16px';
      customCss.paddingRight = '16px';
      break;
    case DbgButtonSize.Medium:
      customCss.fontSize = '12px';
      customCss.height = '30px';
      customCss.lineHeight = '15px';
      customCss.paddingLeft = '14px';
      customCss.paddingRight = '14px';
      break;
    case DbgButtonSize.Small:
      customCss.fontSize = '12px';
      customCss.minHeight = '24px';
      customCss.paddingLeft = '10px';
      customCss.paddingRight = '10px';
      break;
    default:
  }

  const buttonStyles = [
    {
      height: (customCss && customCss.height) || 'unset',
      minHeight: customCss && customCss.minHeight,
      fontSize: (theme: Theme) => (customCss && customCss.fontSize) || theme.typography.fontSize,
      fontWeight: (customCss && customCss.fontWeight) || 'bold',
      minWidth: (customCss && customCss.minWidth) || '40px',
      width: (customCss && customCss.width) || '250px',
      lineHeight: (customCss && customCss.lineHeight) || '0px',
      backgroundColor: customCss && customCss.backgroundColor,
      color: (customCss && customCss.color) || variables.text.primary,
      border: customCss && customCss.borderColor ? `1px solid ${customCss.borderColor}` : '1px solid transparent',
      paddingLeft: (customCss && customCss.paddingLeft) || '15px',
      paddingRight: (customCss && customCss.paddingRight) || '15px',
      borderRadius: '30px',
      letterSpacing: 0,
      textAlign: 'center',
      textTransform: 'none',
      '&:active, &:hover': {
        // border: (theme: Theme) => theme.custom?.borders.default,
        color: (customCss && (customCss.colorActive || customCss.color)) || defaultColors.white,
        backgroundColor: (customCss && customCss.backgroundColorActive) || alpha(customCss.backgroundColor, 0.4),
      },
      '&.MuiButton-root:hover.Mui-disabled': {
        backgroundColor: customCss && alpha(customCss.backgroundColorDisabled || customCss.backgroundColor, 0.4),
      },
      '&.Mui-disabled': {
        color: (theme: Theme) => customCss && `${customCss.colorDisabled || theme.palette.primary.main} !important`,
        backgroundColor: customCss && alpha(customCss.backgroundColorDisabled || customCss.backgroundColor, 0.4),
        // border: (theme: Theme) => theme.custom?.borders.default,
        borderColor: customCss && customCss.borderColor ? customCss.borderColor : 'transparent !important',
      },
      label: {
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
    !!otherProps.selected && {
      // border: (theme: Theme) => theme.custom?.borders.default,
      color: (customCss && customCss.colorSelected) || defaultColors.white,
      backgroundColor: (customCss && customCss.backgroundColorSelected) || defaultColors.darkGrey,
    },
  ] as const;

  return (
    <Button
      sx={[
        ...buttonStyles,
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...otherProps}
      id={`btn${id}`}
    />
  );
};

export { DbgRoundedButton };
