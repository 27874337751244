import { Box, Popover } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IContract } from '../../../../models';
import { DbgButtonSize } from '../../../../models/enums';
import { getTransparentButtonStyleProps } from '../../../../styles/themes';
import { DbgRoundedButton } from '../../../components/buttons/DbgRoundedButton';
import { StyledImg } from '../../../../styles/styled/StyledWrappedCommonElements';
import { defaultColors } from '../../../../styles/variables';
import CopyIcon from '../../../../images/copy.svg';
import { IContractOverlapRequest } from '../../../../models/requests';
import { contractTermService } from '../../../../services';
import { IContractOverlapModel } from '../../../../models/responses';
import { HttpErrorResponse } from '../../../../services/contractHubApi';
import { Link } from 'react-router-dom';
import { RouteEnum } from '../../../layout/PageRouter';

interface IProps {
  contract: IContract;
}

const styles = {
  root: {},
  button: {
    width: '220px',
    height: '40px',
    fontWeight: 500,
    color: defaultColors.blue,
    padding: '0px',
    marginRight: '31px',
    border: 'none',
    '&:hover': {
      color: defaultColors.blue,
      textDecoration: 'underline',
      backgroundColor: defaultColors.tables.rowHover,
    },
  },
  arrow: {
    position: 'relative',
    margin: '10px auto 0 auto',
    width: '80%',
    '&::before': {
      backgroundColor: defaultColors.white,
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 12,
      height: 12,
      top: -6,
      transform: 'rotate(45deg)',
      left: 'calc(50% - 6px)',
      borderTop: `1px solid ${defaultColors.grey}`,
      borderLeft: `1px solid ${defaultColors.grey}`,
    },
  },
  popover: {
    padding: '16px',
    backgroundColor: defaultColors.white,
    borderRadius: '12px',
    border: `1px solid ${defaultColors.grey}`,
  },
  copyIcon: {
    width: '16px',
    height: '18px',
    marginRight: '5px',
  },
  popoverWrapper: {
    backgroundColor: defaultColors.transparent,
    borderRadius: '12px',
    boxShadow: `0 20px 20px -13px ${defaultColors.transparentMediumBlueGrey}`,
    width: '575px',
  },
  vendorContractId: {
    color: defaultColors.mediumGrey,
    fontFamily: 'Inter',
    fontSize: '13px',
    letterSpacing: 0,
    lineHeight: '19px',
    borderBottom: `1px solid ${defaultColors.grey}`,
    width: '100%',
    paddingBottom: '8px',
    overflow: 'hidden',
    marginBottom: '10px',
    fontWeight: 500,
  },
  contractIdWrapper: {
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: defaultColors.altGrey,
    },
    'a,a:hover,a:active': {
      color: defaultColors.darkGrey,
      padding: '9px 8px 9px 14px',
      lineHeight: '28px',
      fontWeight: 500,
      fontSize: '15px',
      height: '46px',
      display: 'block',
      textDecoration: 'none',
    },
  },
};

export default function OverlappingContractsButton(props: IProps) {
  const [overlappingContracts, setOverlappingContracts] = useState<IContractOverlapModel[]>([]);
  const [overlappingContractButton, setOverlappingContractButton] = useState<HTMLButtonElement | null>(null);
  const showOverlappingContractPopover = Boolean(overlappingContractButton);

  const handleOverlappingContractOpen = (event: any) => {
    setOverlappingContractButton(event.currentTarget);
  };

  useEffect(() => {
    (async () => {
      if (props.contract) {
        const contract = props.contract;
        const contractTerms = contract.terms;

        const skus = contractTerms.contractItems.map((q) => q.sku);
        const promotions = contractTerms.contractTermsForItem;

        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const startDates = promotions.filter((q) => q.startDate).map((q) => q.startDate!.getTime());
        const endDates = promotions.filter((q) => q.endDate).map((q) => q.endDate!.getTime());
        /* eslint-enable @typescript-eslint/no-non-null-assertion */

        if (startDates.length === 0 || endDates.length === 0) return;

        const startDate = new Date(Math.min(...startDates));
        const endDate = new Date(Math.max(...endDates));

        const request = {
          startDate: startDate,
          endDate: endDate,
          skus: skus,
        } as IContractOverlapRequest;

        const response = await contractTermService.getOverlap(request);
        if (response instanceof HttpErrorResponse) {
          // eslint-disable-next-line no-console
          console.log('Error getting overlaps.', response);
          return;
        }

        const otherOverlaps = response.filter((x) => x.contractId !== contract.contractId);
        setOverlappingContracts(otherOverlaps);
      }
    })();
  }, [props.contract]);

  if (overlappingContracts.length === 0) return <></>;

  return (
    <>
      <DbgRoundedButton
        id={'OverlappingContracts'}
        dbgButtonSize={DbgButtonSize.Large}
        styleProps={getTransparentButtonStyleProps()}
        sx={styles.button}
        onClick={handleOverlappingContractOpen}
      >
        <StyledImg src={CopyIcon} sx={styles.copyIcon} />
        {overlappingContracts.length} overlapping contract{overlappingContracts.length > 1 ? 's' : ''}
      </DbgRoundedButton>
      <Popover
        id={'OverlappingContractPopover'}
        open={showOverlappingContractPopover}
        anchorEl={overlappingContractButton}
        onClose={() => {
          setOverlappingContractButton(null);
        }}
        sx={styles.root}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -195,
        }}
        PaperProps={{
          style: styles.popoverWrapper,
        }}
      >
        <Box sx={styles.arrow} />
        <Box sx={styles.popover}>
          <Box sx={styles.vendorContractId}>Contract #</Box>
          {overlappingContracts.map((contract, idx) => {
            return (
              <Box sx={styles.contractIdWrapper} key={idx}>
                <Link to={`${RouteEnum.Contract}/${contract.contractId}`} target={`_blank`} id={`lnkOpenContractInNewWindow${contract.contractId}`}>
                  {contract.vendorContractNumber}
                </Link>
              </Box>
            );
          })}
        </Box>
      </Popover>
    </>
  );
}
