import { Configuration, LogLevel, RedirectRequest } from '@azure/msal-browser';

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

export const DIERBERGS_TENANT_ID = '24d16f55-96aa-496e-ae8d-b299b80c2581';

//B2C Tenants' App Registration Client Ids
const DEV_CONTRACT_HUB_UI_CLIENT_ID = 'ed072531-bd6d-4861-9538-92eb2571e656';
const TEST_CONTRACT_HUB_UI_CLIENT_ID = 'c74e40a4-9a51-4f4b-9c83-12029e2e54a5';
const STAGE_CONTRACT_HUB_UI_CLIENT_ID = 'e7b35d0d-0854-479d-9cc4-0d525062a559';
const PROD_CONTRACT_HUB_UI_CLIENT_ID = '9fbe7d61-137a-40d3-927d-6ffc3143b5e9';

const TENANT_NAME_BASE = 'contracthub';
let TENANT_NAME = '';
let CLIENT_ID = '';

const ENV_NAME = process.env.REACT_APP_ENV_NAME?.toLowerCase();

if (!ENV_NAME) throw new Error('REACT_APP_ENV_NAME is undefined.');

switch (ENV_NAME) {
  case 'local':
  case 'dev':
    CLIENT_ID = DEV_CONTRACT_HUB_UI_CLIENT_ID;
    TENANT_NAME = `dev${TENANT_NAME_BASE}`;
    break;
  case 'test':
    CLIENT_ID = TEST_CONTRACT_HUB_UI_CLIENT_ID;
    TENANT_NAME = `test${TENANT_NAME_BASE}`;
    break;
  case 'stage':
    CLIENT_ID = STAGE_CONTRACT_HUB_UI_CLIENT_ID;
    TENANT_NAME = `stage${TENANT_NAME_BASE}`;
    break;
  case 'prod':
    CLIENT_ID = PROD_CONTRACT_HUB_UI_CLIENT_ID;
    TENANT_NAME = TENANT_NAME_BASE;
    break;
  default:
    throw new Error(`Unexpected value for REACT_APP_ENV_NAME: ${ENV_NAME}`);
}
const AUTHORITY_DOMAIN = `${TENANT_NAME}.b2clogin.com`;
const ON_MICROSOFT_DOMAIN = `${TENANT_NAME}.onmicrosoft.com`;

export const DEFAULT_SCOPES = ['openid', 'profile', 'offline_access', CLIENT_ID];
const profileNames = {
  multiMethodSignInPolicy: 'B2C_1A_MULTI_METHOD_SIGNIN',
  magicLinkSignInPolicy: 'B2C_1A_SIGNIN_WITH_MAGIC_LINK',
};

export const b2cPolicies = {
  names: { ...profileNames },
  authorities: {
    multiMethodSignInPolicy: {
      authority: `https://${AUTHORITY_DOMAIN}/${ON_MICROSOFT_DOMAIN}/${profileNames.multiMethodSignInPolicy}`,
    },
    magicLinkSignInPolicy: {
      authority: `https://${AUTHORITY_DOMAIN}/${ON_MICROSOFT_DOMAIN}/${profileNames.magicLinkSignInPolicy}`,
    },
  },
  authorityDomain: AUTHORITY_DOMAIN,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.multiMethodSignInPolicy.authority, // MagicLink / Dierbergs SignIn Page set as default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: `${window.location.origin}`, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },

  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line no-console
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
        }
      },
    },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const defaultLoginRequest: RedirectRequest = {
  scopes: [...DEFAULT_SCOPES],
  extraQueryParameters: { targetRoute: `${window.location.pathname}${window.location.search}` },
  authority: b2cPolicies.authorities.multiMethodSignInPolicy.authority,
};
