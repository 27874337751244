import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';

import ErrorBox from './ErrorBox';

interface IProps extends FallbackProps {}

const ErrorFallback = (props: IProps) => {
  const { error } = props;

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(error);
  }, [error]);

  return <ErrorBox error={error} />;
};

export default ErrorFallback;
