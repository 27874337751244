import { defaultColors } from '../../../../../styles/variables';

export const searchGridStyles = {
  gridOverrideRoot: {
    border: '0',
    display: 'flex',
    flexDirection: 'column' as unknown as 'column',
    overflow: 'hidden',
    marginTop: '6px',
    maxHeight: 'calc(100vh - 280px)',
  },
  gridOverrideHeader: {
    backgroundColor: defaultColors.lightGrey,
    padding: '10px 0 18px 6px',
    maxHeight: '56px',
    minHeight: '56px',
  },
} as const;
