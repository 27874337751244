import { ISupplier } from '../models';
import { fetchGetJson, HttpErrorResponse } from './contractHubApi';

export const supplierService = {
  search,
  selectById,
};

function search(searchString: string): Promise<ISupplier[] | HttpErrorResponse> {
  return fetchGetJson<ISupplier[]>({ endpoint: 'supplier', query: { q: searchString } });
}

function selectById(id: number): Promise<ISupplier | null | HttpErrorResponse> {
  return fetchGetJson<ISupplier | null>({ endpoint: 'supplier/' + id });
}
