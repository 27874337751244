import { Box } from '@mui/material';
import { HTMLAttributes } from 'react';

interface PageBodyProps extends HTMLAttributes<HTMLDivElement> {}
export default function PageBody(props: React.PropsWithChildren<PageBodyProps>) {
  const { children } = props;
  return <Box sx={styles.root}>{children}</Box>;
}
const styles = {
  root: {
    width: '100%',
  },
};
