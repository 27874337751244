import environment from 'environment';
import { isValid } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { msalInstance } from '../index';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { IError } from '../models/responses';
import { defaultLoginRequest } from '../authConfig';
import { toQueryString } from '@dierbergs-markets/react-component-library';

interface PerformFetchParams {
  endpoint: string;
  query?: string | object;
  body?: string | object;
  signal?: AbortSignal;
}

export async function RefreshAccessToken(forceRefresh: boolean) {
  const accounts = msalInstance.getAllAccounts();

  if (!accounts) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  try {
    const response = await msalInstance.acquireTokenSilent({ ...defaultLoginRequest, account: accounts[0], forceRefresh });
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      const response = await msalInstance.acquireTokenPopup({ ...defaultLoginRequest, account: accounts[0] });
      return response.accessToken;
    } else {
      const response = await msalInstance.loginPopup(defaultLoginRequest);
      response.accessToken;
      // eslint-disable-next-line no-console
      console.error('Error retrieving AccessToken', error);
    }
  }
}

/**
 * Do not use yet. The API does not current handle bearer tokens.
 * @returns
 */
// async function GetAuthTokenHeader() {
//   const accessToken = await RefreshAccessToken(false);
//   return { Authorization: `Bearer ${accessToken}` };
// }

const baseRequestOptions: RequestInit = {
  cache: 'no-cache',
  mode: 'cors', //"no-cors",
  credentials: 'include', //"same-origin",
  //redirect: "manual",
};

/**
 *Use when you want complete control over the Response returned.
 * @param params PerformFetchParams
 * @returns Response
 */
export function fetchGet(params: Omit<PerformFetchParams, 'body'>): Promise<Response> {
  return performFetch(params, 'get');
}

/**
 * Use when you want the expected return type T. otherwise, return the error response.
 * @param params PerformFetchParams
 * @returns T or HttpErrorResponse
 */
export async function fetchGetJson<T>(params: Omit<PerformFetchParams, 'body'>): Promise<T | HttpErrorResponse> {
  try {
    const response = await fetchGet(params);
    return await parseJsonOrErrorResponse<T>(response);
  } catch (e) {
    if (e instanceof DOMException && e.name == 'AbortError') {
      const toReturn = new HttpErrorResponse();
      toReturn.isCanceled = true;
      return toReturn;
    }
    throw e;
  }
}
/**
 * Use when you want complete control over the Response returned.
 * @param endpoint
 * @param query
 * @returns Response
 */
export function fetchDelete(params: Omit<PerformFetchParams, 'body'>): Promise<Response> {
  return performFetch(params, 'delete');
}

/**
 * Use when you want the expected return type T. otherwise, return the error response.
 * @param params PerformFetchParams
 * @returns T or HttpErrorResponse
 */
export async function fetchDeleteJson<T>(params: PerformFetchParams): Promise<T | HttpErrorResponse> {
  const response = await fetchDelete(params);
  return await parseJsonOrErrorResponse<T>(response);
}

/**
 * Use when you only care if the request was successful. otherwise, return the error response.
 * @param params PerformFetchParams
 * @returns boolean or HttpErrorResponse
 */
export async function fetchDeleteSuccessOrError(params: Omit<PerformFetchParams, 'body'>): Promise<SuccessOrErrorResponse> {
  const response = await fetchDelete(params);
  return await successOrErrorResponse(response);
}

/**
 * Use when you want complete control over the Response returned.
 * @param params PerformFetchParams
 * @returns Response
 */
export function fetchPost(params: PerformFetchParams): Promise<Response> {
  return performFetch(params, 'post');
}

/**
 * Use when you only care if the request was successful. otherwise, return the error response.
 * @param params PerformFetchParams
 * @returns boolean or HttpErrorResponse
 */
export async function fetchPostSuccessOrError(params: PerformFetchParams): Promise<SuccessOrErrorResponse> {
  const response = await fetchPost(params);
  return await successOrErrorResponse(response);
}

/**
 * Use when you want the expected return type T. otherwise, return the error response.
 * @param params PerformFetchParams
 * @returns T or HttpErrorResponse
 */
export async function fetchPostJson<T>(params: PerformFetchParams): Promise<T | HttpErrorResponse> {
  const response = await fetchPost(params);
  return await parseJsonOrErrorResponse<T>(response);
}

/**
 * Use when you want complete control over the Response returned.
 * @param params PerformFetchParams
 * @returns Response
 */
export function fetchPut(params: PerformFetchParams): Promise<Response> {
  return performFetch(params, 'put');
}

/**
 * Use when you want the expected return type T. otherwise, return the error response.
 * @param params PerformFetchParams
 * @returns T or HttpErrorResponse
 */
export async function fetchPutJson<T>(params: PerformFetchParams): Promise<T | HttpErrorResponse> {
  const response = await fetchPut(params);
  return await parseJsonOrErrorResponse<T>(response);
}

/**
 * Use when you only care if the request was successful. otherwise, return the error response.
 * @param params PerformFetchParams
 * @returns boolean or HttpErrorResponse
 */
export async function fetchPutSuccessOrError(params: PerformFetchParams): Promise<SuccessOrErrorResponse> {
  const response = await fetchPut(params);
  return await successOrErrorResponse(response);
}

async function performFetch(params: PerformFetchParams, method: 'get' | 'post' | 'put' | 'delete'): Promise<Response> {
  const headers = {};
  if (params.body) headers['Content-Type'] = 'application/json';

  const response = await fetch(
    createEndpointUrl(params.endpoint, params.query),
    await createFetchOptions({
      method: method,
      headers: headers,
      body: params.body ? JSON.stringify(params.body) : undefined,
      signal: params.signal,
    })
  );
  return response;
}

function createEndpointUrl(endpoint: string, query?: object | string) {
  const queryString = toQueryString(query);
  const url = `${environment.baseApiUrl}/${endpoint}${queryString}`;
  return url;
}

async function createFetchOptions(overrides?: RequestInit) {
  const result = {
    ...baseRequestOptions,
    ...overrides,
    headers: {
      ...baseRequestOptions.headers,
      ...overrides?.headers,
    },
  };
  return result;
}

async function successOrErrorResponse(response: Response): Promise<boolean | HttpErrorResponse> {
  if (!response.ok) {
    return await parseHttpErrorResponse(response);
  }
  return true;
}

async function parseJsonOrErrorResponse<T>(response: Response): Promise<T | HttpErrorResponse> {
  if (!response.ok) {
    return await parseHttpErrorResponse(response);
  }

  const json = await response.text();
  const data: T = JSON.parse(json, dateReviver);
  return data;
}

function isValidDate(key: string, value: any) {
  if (value instanceof Date) return true;
  if (!isValid(parseISO(value))) return false;
  if (typeof value !== 'string') return false;
  const timestamp = Date.parse(value);
  if (isNaN(timestamp)) return false;

  for (const ending of ['At', 'Date', 'DateTime', 'Utc']) {
    if (key.endsWith(ending)) return true;
  }

  return false;
}

function dateReviver(key: string, value: any) {
  if (isValidDate(key, value)) {
    if (typeof value === 'string' && !value.includes('T')) {
      return new Date(`${value}T00:00:00`);
    }
    return new Date(value);
  }

  return value;
}

export type SuccessOrErrorResponse = boolean | HttpErrorResponse;

export class HttpErrorResponse {
  status = 0;
  message = '';
  url = '';
  isCanceled = false;
}

async function parseHttpErrorResponse(response: Response): Promise<HttpErrorResponse> {
  const text = await response.text();

  const result = new HttpErrorResponse();
  result.status = response.status;
  result.url = response.url;
  result.message = parseHttpErrorText(text);

  return result;
}

function parseHttpErrorText(text: string): string {
  try {
    const parsed = JSON.parse(text);
    return parsed.detail ?? text;
  } catch {
    return text;
  }
}

export async function parseHttpErrorArray(response: HttpErrorResponse): Promise<IError[]> {
  const errors: IError[] = JSON.parse(response.message);
  return errors;
}

export function toDateOnlyString(date: Date | undefined | null): string | null {
  if (!date) return null;
  return new Date(date).toISOString().split('T')[0];
}

export function emptyToNull(value: string | null | undefined): string | null {
  if (!value) return null;
  return value;
}
