import PageCenter from '../../components/layout/PageCenter';
import { RouteEnum } from '../../layout/PageRouter';
import ErrorBox from '../../components/shared/ErrorBox';
import { useLocation } from 'react-router-dom';

export default function SignInFailure() {
  const location = useLocation();

  // Get the message from the state object, if set.
  const message = location.state && location.state.message;

  return (
    <PageCenter>
      <ErrorBox
        message={message || 'Sign in failed.'}
        actionLabel="Please try again"
        action={() => (window.location.pathname = RouteEnum.Dashboard)}
      />
    </PageCenter>
  );
}
