import React, { useEffect } from 'react';
import { Theme, Box } from '@mui/material';
import logo from '../../../images/d_circle_logo.png';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { StyledSpan } from './styled/StyledWrappedCommonElements';
import { RouteEnum } from '../../layout/PageRouter';
import { DbgLinkButton } from '../buttons/DbgLinkButton';
import variables from '../../../styles/variables';

interface IProps {
  error?: Error;
  message?: string;
  action?: () => void;
  actionLabel?: string;
}

const centereredDivProps: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const classes = {
  root: {
    ...centereredDivProps,
    justifyContent: 'center',
    height: '100vh',
    fontSize: '16px',
    fontWeight: 500,
  },
  card: {
    ...centereredDivProps,
    padding: '14px',
    boxShadow: (theme: Theme) => theme.custom?.boxShadow.default,
    borderRadius: (theme: Theme) => theme.custom?.borderRadius.default,
    backgroundColor: () => variables.defaultColors.white,
    width: '500px',
  },
  errorContainer: {
    ...centereredDivProps,
    flexGrow: 1,
    justifyContent: 'space-evenly',
    overflowWrap: 'anywhere',
  },
  errorMessage: {
    color: variables.defaultColors.red,
  },
} as const;

const ErrorBox = (props: IProps) => {
  const { error, message, action, actionLabel } = props;

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(error);
  }, [error]);

  return (
    <Box sx={classes.root}>
      <Box sx={classes.card}>
        <DbgLinkButton id={'ErrorFallbackHome'} onClick={() => window.location.replace(RouteEnum.Dashboard)}>
          <img src={logo} />
        </DbgLinkButton>
        <Box sx={classes.errorContainer}>
          <div>Something went wrong.</div>
          {message ||
            (error && (
              <div>
                <span>Error: </span>
                <StyledSpan sx={classes.errorMessage}>{message ?? error?.message ?? error}</StyledSpan>
              </div>
            ))}
          <DbgLinkButton id={`ErrorFallbackReload`} onClick={() => (action && action()) || window.location.reload()}>
            {actionLabel ?? 'Reload the page'}
          </DbgLinkButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorBox;
