import {
  Button,
  Checkbox,
  DataGridEditableCellOverrideParams,
  DataGridRenderBodyCellParams,
  DataGridValueGetterParams,
  DataGridValueSetterParams,
} from '@dierbergs-markets/react-component-library';
import { IReferenceDataState } from '../contexts/ApplicationContext';
import { IChildContractItem, IContract, IContractItem, IPricing, IPricingItem, IPricingItemTermItem } from '../models';
import { defaultColors } from '../styles/variables';
import * as PG from '@dierbergs-markets/react-feature-library';
import { format } from 'date-fns';

export interface PricingGridPricingItem extends PG.IPricingItem {
  suggestedRetailPrice?: number;
  suggestedRetailMultiple?: number;
}

/**
 * Maps Ad Location's startDateOffSet value to the day of the week
 * Note: The 0 Key is considered Tuesday, the start of Ad Week.
 */
export const startOffSetToDayOfWeek = { 0: 2, 1: 3, 2: 4, 3: 5, 4: 6, 5: 0, 6: 1 };

export function getCostImplicationType(unitOfMeasureId: number, referenceData?: IReferenceDataState) {
  const uom = referenceData?.termTypeUnitsOfMeasure.byId[unitOfMeasureId];

  switch (uom?.name.toUpperCase()) {
    case 'CASE':
      return PG.CostImplicationType.CASE;

    default:
      return PG.CostImplicationType.SINGLEUNIT;
  }
}

export function toPricingGridPricingItem(contract: IContract, contractItem: IContractItem, isChildItem?: boolean): PricingGridPricingItem {
  const result: PricingGridPricingItem = {
    rowType: isChildItem ? 1 : 0,
    item: toPricingGridItem(contract, contractItem),
    suggestedRetailPrice: contractItem.suggestedRetailPrice,
    suggestedRetailMultiple: contractItem.suggestedRetailMultiple,
    costImplications: createCostImplications(contractItem, contract),
    prices: extractPricesBySku(contractItem.sku, contract.pricings),
    currentRegPrice: contractItem.item ? { multiple: contractItem.item.regularMultiple, price: contractItem.item.regularPrice } : {},
    childData: isChildItem ? [] : contractItem.childData && contractItem.childData.map((ci) => toPricingGridPricingItem(contract, ci, true)),
  };
  return result;
}

function createCostImplications(contractItem: IContractItem | IChildContractItem, contract: IContract): PG.ICostImplication[] {
  if (!contract.pricings) return [];

  return contract.pricings
    .flatMap((x) => x.itemTerms)
    .reduce((acc: PG.ICostImplication[], itemTerm) => {
      //prevent duplicate cost implications from appearing in the grid.
      if (!acc.some((term) => term.uniqueId === itemTerm.itemTermUniqueId)) {
        acc.push(toPricingGridCostImplication(itemTerm.itemTermUniqueId, contractItem.sku, itemTerm.items, contract));
      }
      return acc;
    }, []);
}

function toPricingGridCostImplication(
  itemTermUniqueId: string,
  sku: number,
  itemTermItems: IPricingItemTermItem[],
  contract: IContract
): PG.ICostImplication {
  const item = itemTermItems.find((i) => i.sku === sku);

  if (item) return { uniqueId: itemTermUniqueId, amount: item?.amount ?? 0 };

  //check if the itemTermUniqueId is on this contract.
  const localContractTermIndex = contract.terms.contractTermsForItem.findIndex((cti) => cti.uniqueId === itemTermUniqueId);

  const isLocalContractTerm = localContractTermIndex !== -1;

  if (isLocalContractTerm) {
    //If the contractItem exists but has not been included in itemTermItems, get the amount from contractItem.amounts array
    //This happens when new UPCs are added to the same contract during the current session but not saved yet.
    if (isLocalContractTerm) {
      const localContractItem = contract.terms.contractItems.find((ci) => ci.sku === sku);
      if (localContractItem) return { uniqueId: itemTermUniqueId, amount: localContractItem.amounts[localContractTermIndex] ?? 0 };
    }
  }
  return { uniqueId: itemTermUniqueId, amount: 0 };
}

function toPricingGridItem(contract: IContract, contractItem: IContractItem): PG.IItem {
  if (!contractItem.item) {
    return {
      sku: contractItem.sku,
      upc: 0,
      description: 'Unknown Item',
      displaySize: '',
    };
  }

  return {
    packSize: contractItem.item.quantityPerPack,
    sku: contractItem.sku,
    upc: contractItem.item.upc,
    description: contractItem.item.description,
    displaySize: `${contractItem.item.size} ${contractItem.item.unitOfMeasure}`,
    priceAssociation: contractItem.item.suppliers?.find((s) => s.id === contract.terms.supplier?.id)?.priceAssociationCode,
    caseCost: contractItem.caseListCost,
  };
}

function extractPricesBySku(sku: number, pricings?: IPricing[]): (PG.IPrice | undefined)[] {
  if (!pricings) return [];
  const result: (PG.IPrice | undefined)[] = [];

  pricings.forEach((price) => {
    const itemPrice = price.items.find((i) => i.sku === sku);

    result.push(
      itemPrice
        ? { multiple: itemPrice.multiple, price: itemPrice.price, orderSurvey: itemPrice.orderSurvey }
        : { multiple: 1, price: 0, orderSurvey: false }
    );
  });
  return result;
}

export function getPricingName(pricing: IPricing) {
  return `pricing_${pricing.pricingId ?? pricing.uniqueId}`;
}

export function getPricings(
  contract: IContract,
  canModify: boolean,
  referenceData?: IReferenceDataState,
  classes?: Record<'headerContents' | 'headerEditBtn' | 'root' | 'headerComments' | 'suggestedRetail' | 'pricePrefix', string>,
  invokePricingEdit?: (pricing: IPricing) => void,
  updatePricingItems?: (pricingIndex: number, items: IPricingItem[]) => void
): PG.IPricingColumnConfiguration<PG.IPricingItem>[] {
  if (!referenceData || !contract.pricings) return [];

  return contract.pricings.map<PG.IPricingColumnConfiguration<PG.IPricingItem>>((p, index) => ({
    name: getPricingName(p),
    priceType: p.priceTypeId,
    startDate: p.startDate,
    endDate: p.endDate,
    comments: p.comments,
    additionalSubColumns: [
      {
        field: `orderSuvey${index}`,
        type: 'boolean',
        columnBodyCss: {
          alignItems: 'center',
        },
        width: 100,
        editable: true,
        hideable: false,
        editableCellOverride: (params: DataGridEditableCellOverrideParams<number, PG.IPricingItem>) => {
          if (params.row.rowType === 0) {
            return {
              isEditable: true,
              cellCss: {
                backgroundColor: defaultColors.editGreen,
                '& div[role=checkbox]': {
                  justifyContent: 'center',
                },
              },
            };
          } else {
            return {
              isEditable: false,
              cellCss: {
                backgroundColor: 'inherit',
                borderBottom: `1px solid ${defaultColors.grey}`,
              },
            };
          }
        },
        renderHeaderCellContent: () => {
          if (!contract.pricings) return;
          //Determine Select All checkbox intial checked state for this pricing.
          const checkBoxChecked = contract.pricings[index].items.every((i) => i.orderSurvey);

          return (
            <Checkbox
              id={`selectAll_pricing${index}`}
              label={'Order Survey'}
              initialValue={checkBoxChecked}
              checked={checkBoxChecked}
              disabled={!canModify}
              onChange={handleSelectionChange}
            />
          );

          function handleSelectionChange(checked: boolean) {
            updatePricingItems?.(
              index,
              p.items.map((item) => ({ ...item, orderSurvey: checked }))
            );
          }
        },
        valueGetter(params: DataGridValueGetterParams<PricingGridPricingItem>) {
          if (!params.row) return false;
          const price = params.row.prices[index];
          if (price == undefined) return false;
          return price.orderSurvey;
        },
        valueSetter(params: DataGridValueSetterParams<boolean | undefined, PricingGridPricingItem>) {
          let value: boolean | undefined = undefined;
          if (params.value != undefined) {
            value = params.value;
          }
          const prices = [...params.row.prices];
          let price = prices[index];

          if (price == undefined || !price) price = {};
          if (price.orderSurvey === value) return params.row;

          price.orderSurvey = value;
          prices[index] = price;
          return { ...params.row, prices };
        },
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<boolean, PricingGridPricingItem>) => {
          if (params.row.rowType === 0) return params.row.prices[index]?.orderSurvey?.toString() ?? 'false';
          else '';
        },
      },
    ],
    groupedHeaderCellCss: {
      root: {
        marginTop: '10px',
        height: '150px',
        backgroundColor: defaultColors.white,
        borderRadius: '16px 16px 0 0',
        outline: `1px solid ${defaultColors.grey}`,
        fontSize: '13px',
        color: defaultColors.mediumGrey,
      },
      subCell: {
        borderTop: 'unset',
      },
    },
    renderHeaderCellContent: () => {
      const adLocation = referenceData.adSites.all.find((a) => a.adSiteId === p.adSiteId);
      return (
        <div className={classes?.headerContents}>
          <Button classes={{ root: classes?.headerEditBtn }} id={`editPricing${p.uniqueId}`} onClick={() => invokePricingEdit?.(p)} variant={'link'}>
            {referenceData.priceTypes.byId[p.priceTypeId].name} {adLocation && ` - ${adLocation.name}`}
          </Button>
          <div>
            {format(p.startDate, 'M/d/yy')} - {format(p.endDate, 'M/d/yy')}
          </div>
          <div title={p.itemTerms.map((it) => referenceData.termTypes.byId[it.termTypeId].name).join(', ')}>
            <span style={{ fontWeight: 700, color: defaultColors.black }}>*WIP</span> Cost Implications: {p.itemTerms.length}
          </div>
          <div className={classes?.headerComments} title={p.comments}>
            Comments: {p.comments}
          </div>
        </div>
      );
    },
    costImplications: p.itemTerms
      .filter((it) => {
        //if same contract, and promotion doesn't exist (deleted during current session), don't render the column
        if (it.contract.contractId === contract.contractId) {
          const termExists = contract.terms.contractTermsForItem.some((cti) => cti.uniqueId === it.itemTermUniqueId);
          return termExists;
        }
        return true;
      })
      .map<PG.ICostImplicationConfiguration>((it) => ({
        uniqueId: it.itemTermUniqueId,
        name: referenceData.termTypes.byId[it.termTypeId].name,
        accountingType: PG.AccountingType.CREDIT,
        type: getCostImplicationType(it.termUnitOfMeasureId, referenceData),
        renderHeaderCellContent: () => {
          return (
            <>
              <span style={{ fontWeight: 700, color: defaultColors.black }}>*WIP </span>
              <span
                title={`Internal Contract # ${it.contract.contractId} - ${referenceData.termTypes.byId[it.termTypeId].name} / ${it.itemTermUniqueId}`}
              >
                {referenceData.termTypes.byId[it.termTypeId].name}
              </span>
            </>
          );
        },
      })),
  }));
}
