import * as React from 'react';
import { Box, OutlinedInput, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEffect, useImperativeHandle, useState } from 'react';
import { HttpErrorResponse } from '../../../../../services/contractHubApi';
import { UpcSelectionSearchType } from '../../../../../models/enums';
import { IAdvSearch, IBrand } from '../../../../../models';
import { AutoComplete, DataGridCustomFormProps, TextInput } from '@dierbergs-markets/react-component-library';
import { AdvSearchModalStyles } from '../../../Contract/components/dialogs/styles/AdvSearchModalStyles';
import { brandService, itemService } from '../../../../../services';
import { getErrorMessage, getErrorTitle } from '../../../../../utilities/ErrorUtils';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { IUpcSearchResponse } from '../../../../../models/responses';
import { useStyles } from 'tss-react/mui';
import { textfieldStyles } from '../../../../../styles/shared/TextFieldStyles';

const AdvancedSearchCustomFilterFormComponent = React.forwardRef<DataGridCustomFormProps<IAdvSearch>, IAdvSearch>(
  (props: IAdvSearch, ref: React.Ref<DataGridCustomFormProps<IAdvSearch>>) => {
    const { css } = useStyles();
    const getNewBlankBrand = { brandId: '', name: '', isActive: true };

    const [searchType, setSearchType] = useState<string>(props.upcCodeSearchType ?? UpcSelectionSearchType.Upc);
    const [upc, setUpc] = useState<string>(props.upc ?? '');
    const [orderCode, setOrderCode] = useState<string>(props.orderCode ?? '');
    const [brand, setBrand] = useState<IBrand | null | undefined>(props.brand ?? getNewBlankBrand);
    const [description, setDescription] = useState<string | undefined>(props.description ?? '');
    const [searchResponse, setSearchResponse] = useState<IUpcSearchResponse | undefined>(undefined);

    const { styles } = AdvSearchModalStyles;

    useEffect(() => {
      if (searchType === UpcSelectionSearchType.OrderCode) {
        setOrderCode(props.orderCode ?? '');
        setUpc('');
      } else if (searchType === UpcSelectionSearchType.Upc) {
        setUpc(props.upc ?? '');
        setOrderCode('');
      }
      setSearchResponse(undefined);
    }, [searchType]);

    useEffect(() => {
      const orderCode = props.upcCodeSearchType === UpcSelectionSearchType.OrderCode ? UpcSelectionSearchType.OrderCode : UpcSelectionSearchType.Upc;
      setSearchType(orderCode);
      setUpc(props.upc ?? '');
      setOrderCode(props.orderCode ?? '');
      if (!props.brand || props.brand.brandId === '') {
        setBrand({ ...getNewBlankBrand });
      } else {
        setBrand(props.brand);
      }
      setDescription(props.description ?? null);
    }, [props, props.upc, props.orderCode, props.brand, props.description, props.upcCodeSearchType]);

    function areAdvSearchFieldsBlank(data: IAdvSearch): boolean {
      if (data.brand?.brandId === '' && data.description === '' && data.orderCode === '' && Number(data.upc) === 0) {
        return true;
      }
      return false;
    }

    const handleSearch = async () => {
      //clear previous search attempts
      setSearchResponse(undefined);

      let response: IUpcSearchResponse | HttpErrorResponse | undefined;
      if (searchType === UpcSelectionSearchType.Upc && upc != '' && !Number.isNaN(upc)) {
        response = await itemService.searchByUpc(Number(upc));
      } else if (searchType === UpcSelectionSearchType.OrderCode && validString(orderCode)) {
        response = await itemService.searchByOrderCode(orderCode);
      }

      if (!(response instanceof HttpErrorResponse)) {
        response = response as unknown as IUpcSearchResponse;
        setSearchResponse(response);
      }
    };

    const SearchBrands = async (query: string): Promise<HttpErrorResponse | IBrand[]> => {
      const response = await brandService.searchBrand(query);
      if (!(response instanceof HttpErrorResponse)) {
        return response.filter((b) => b.isActive);
      }

      return response;
    };

    const inputStyle = searchResponse ? (searchResponse.error ? styles.form.input.error : styles.form.input.success) : styles.form.input;

    const sxStyles = [styles.form.input, inputStyle];

    const processValidation = (): boolean => {
      return searchResponse?.error == undefined;
    };

    const validString = (value: string | undefined | null) => value && value !== '';

    const getCustomFilters = (): IAdvSearch | null => {
      const response: IAdvSearch = {
        upcCodeSearchType: searchType,
        upc,
        orderCode,
        brand,
        description: description ?? '',
        isSearchExecuted: true,
        count: getCustomFilterCount(), //this is needed for persisting the count. The Datagrid looks for this for persisting..
      };
      if (areAdvSearchFieldsBlank(response)) {
        return null;
      }

      return response;
    };

    const getCustomFilterCount = (): number => {
      let count = 0;

      if (validString(upc)) count++;
      if (validString(orderCode)) count++;
      if (validString(brand?.brandId)) count++;
      if (validString(description)) count++;

      return count;
    };

    useImperativeHandle(ref, () => ({
      processValidation,
      getCustomFilters,
      reset: () => {
        setSearchType(UpcSelectionSearchType.Upc);
        setUpc('');
        setOrderCode('');
        setBrand(undefined);
        setDescription(undefined);
      },
      getCustomFilterCount,
    }));

    return (
      <Box sx={styles.modalBorder}>
        <Box sx={styles.instruction}>
          <h3>Search by UPC or order code</h3>
        </Box>
        <Box sx={styles.main}>
          <Box>
            <Box sx={styles.boxFlex}>
              <Box>
                <ToggleButtonGroup
                  sx={styles.form.toggle}
                  value={searchType}
                  exclusive
                  onChange={(event: any, value: any) => {
                    setSearchType(value);
                  }}
                >
                  <ToggleButton sx={styles.form.toggle.upc} value={UpcSelectionSearchType.Upc} disabled={searchType === UpcSelectionSearchType.Upc}>
                    UPC
                  </ToggleButton>
                  <ToggleButton
                    sx={styles.form.toggle.orderCode}
                    value={UpcSelectionSearchType.OrderCode}
                    disabled={searchType === UpcSelectionSearchType.OrderCode}
                  >
                    Order Code
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              {searchType === UpcSelectionSearchType.OrderCode && (
                <>
                  <OutlinedInput
                    type={'text'}
                    value={orderCode}
                    onChange={(event: any) => setOrderCode(event.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                    onBlur={() => handleSearch()}
                    sx={sxStyles}
                  />
                </>
              )}

              {searchType === UpcSelectionSearchType.Upc && (
                <>
                  <OutlinedInput
                    type={'number'}
                    value={upc}
                    onChange={(event: any) => setUpc(event.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                    onBlur={() => handleSearch()}
                    sx={sxStyles}
                  />
                </>
              )}
            </Box>
            {searchResponse && searchResponse.error && (
              <Box sx={styles.error}>
                <Box sx={styles.error.type}>
                  <ErrorOutlineIcon sx={{ marginRight: '6px' }} />
                  {getErrorTitle(searchResponse.error?.type)}
                </Box>
                <Box sx={styles.error.message}>{getErrorMessage(searchResponse?.error)}</Box>
              </Box>
            )}
          </Box>
          <Box sx={styles.instruction}>
            <h3>or by brand and/or description</h3>
          </Box>
          <Box>
            <AutoComplete
              id={'brand'}
              label={'Brand'}
              initialValue={brand === null ? undefined : brand}
              optionRender={(o) => <Box>{o.name}</Box>}
              inputPlaceholder="Search all brands..."
              onSelected={(b?: IBrand) => {
                setBrand(b ?? getNewBlankBrand);
              }}
              optionValueAsInputText
              queryOptionsAsync={SearchBrands}
              valueExtractor={(option) => option.brandId}
              textExtractor={(option) => option.name}
              // errormessage={errors.brand?.message}
              sx={styles.form.brand}
            />
          </Box>
          <br />
          <Box>
            <TextInput
              id="adv-search-description"
              label="Description"
              placeholder="Enter your description..."
              onChange={(value: string) => setDescription(value)}
              value={description}
              classes={{
                input: css(textfieldStyles.textInputInput as any),
                root: css({ ...(styles.form.description as any), ...(textfieldStyles.textInputHover as any) }),
              }}
            />
          </Box>
        </Box>
        <br />
      </Box>
    );
  }
);

export default AdvancedSearchCustomFilterFormComponent;
