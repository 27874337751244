import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

/**
 * Used to publish and subscribe to events across components.
 */
export const eventEmitterService = {
  /**
   * Only fires when the expected event first occurs.
   * @param eventName
   * @param fn - Function to execute by subscriber when event occurs.
   * @returns
   */
  on: (event, fn: (...args: any[]) => void) => eventEmitter.on(event, fn),
  /**
   * Fires whenever the expected event occurs.
   * @param eventName
   * @param fn - Function to execute by subscriber when event occurs.
   * @returns
   */
  once: (eventName: string, fn: (...args: any[]) => void) => eventEmitter.once(eventName, fn),
  /**
   * Called during unmounting in useEffect to clean up memory resources.
   * @param eventName
   * @returns
   */
  off: (eventName: string, fn?: (...args: any[]) => void) => eventEmitter.off(eventName, fn),
  /**
   *
   * @param eventName
   * @param optional payload to be retrieved by subscribers to event.
   * @returns
   */
  emit: (eventName: string, payload?: any) => eventEmitter.emit(eventName, payload),
};
