import { SnackbarProvider } from 'notistack';
import { ApplicationContextProvider } from '../contexts/ApplicationContext';
import { PageRouter } from './layout/PageRouter';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/shared/ErrorFallback';

interface IAppProps {
  pca: IPublicClientApplication;
}

export default function App(props: IAppProps) {
  return (
    <MsalProvider instance={props.pca}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider preventDuplicate maxSnack={6} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <ApplicationContextProvider>
              <PageRouter />
            </ApplicationContextProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ErrorBoundary>
    </MsalProvider>
  );
}
