import { defaultColors } from '../../../../../styles/variables';

const styles = {
  root: {
    height: 'calc(100vh - 290px)',
  },
  actionsContainer: {
    position: 'relative',
  },
  actions: {
    position: 'absolute',
    zIndex: 25,
    display: 'flex',
    paddingX: '16px',
    top: '72px',
    maxWidth: '650px',
  },
  buttons: {
    addUpc: {
      width: '150px',
      height: '40px',
      fontWeight: 500,
      color: defaultColors.blue,
      padding: '0px',
      marginRight: '31px',
      border: `2px solid ${defaultColors.blue}`,
      '&:hover': {
        color: defaultColors.blue,
      },
    },
    deleteContractItem: {
      display: 'none',
      border: 'none',
      borderRadius: 'unset',
      padding: '0px',
      backgroundColor: 'unset !important',
      width: '16px',
      minWidth: 'unset',
      height: '18px',
      justifyContent: 'center',
      alignItems: 'center',

      icon: {
        width: '16px',
        height: '18px',
        //https://codepen.io/sosuke/pen/Pjoqqp - change svg fill color using hex (#8696BB) to filter conversion tool
        filter: 'invert(66%) sepia(10%) saturate(1027%) hue-rotate(184deg) brightness(88%) contrast(88%)',
      },
    },
    promoModal: {
      whiteSpace: 'normal',
      fontWeight: 500,
      lineHeight: '19px',
      fontSize: '16px',
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginBottom: '10px',
      container: {
        display: 'inline-flex',
        alignItems: 'flex-start',
      },
      editable: {
        color: defaultColors.blue,
        '&:hover': {
          '& div[data-label="termType"]': {
            textDecoration: 'underline',
          },
          '& svg': {
            display: 'initial',
          },
          '& div[data-label="uom"]': {
            textDecoration: 'none',
          },
        },
      },
      editIcon: {
        fontSize: '14px',
        marginRight: '5px',
        display: 'none', //only on hover
      },
      uom: {
        color: defaultColors.mediumBlueGrey,
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '15px',
        marginTop: '3px',
      },
    },
  },

  rightAlign: {
    textAlign: 'right',
  },

  promoHeaderDates: {
    paddingRight: '8px',
    fontSize: '13px',
    lineHeight: '19px',
    fontWeight: 500,
  },
  promoInputAndLabelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridOverrides: {
    root: {
      borderTopColor: 'transparent',
    },
    header: {
      paddingTop: '10px',
      flexShrink: 0,
    },
    bodyRow: {
      backgroundColor: `${defaultColors.altGrey}`,
      '&:hover': {
        '& button[data-button-type="delete"]': {
          display: 'flex',
        },
        backgroundColor: `${defaultColors.rowHover}`,
      },
    },
    headerCell: {
      borderRight: 'none',
      color: defaultColors.mediumGrey,
    },
  },
  columnOverrides: {
    headerInputCell: {
      padding: '0px',
      width: '100%',
      backgroundColor: defaultColors.white,
      outline: `1px solid ${defaultColors.grey}`,
      borderRadius: '16px 16px 0 0',
      '& #iconMenu': { marginTop: '100px' },
    },
    headerInputCellMenuIcon: {
      paddingBottom: '11px',
      paddingLeft: '7px',
      paddingRight: '7px',
    },
    editableBodyCell: {
      backgroundColor: defaultColors.white,
      textAlign: 'right',
      alignItems: 'flex-end',
    },
    deleteBodyCell: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      trashIcon: {
        fontWeight: 'bold',
        cursor: 'pointer',
        width: '18px',
        filter: 'invert(66%) sepia(10%) saturate(1027%) hue-rotate(184deg) brightness(88%) contrast(88%)',
      },
    },
    itemBodyCell: {
      borderRightColor: 'transparent',
      fontWeight: 500,
    },
  },
  headerNumberInputRoot: {
    height: '32px',
    minHeight: '32px',
    maxHeight: '32px',
    marginBottom: '8px',
    marginLeft: '8px',
    marginRight: '8px',
    width: '100%',
    display: 'block',
  },
  headerNumberInputLabel: {
    display: 'block',
  },
  headerInputAndLabelContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    marginTop: '92px',
    paddingLeft: '6px',
  },
  promoInputCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingRight: '6px',
    startAdornment: {
      color: defaultColors.numberInputAdornment,
      fontWeight: 500,
    },
    endAdornment: {
      color: defaultColors.numberInputAdornment,
      fontWeight: 500,
    },
  },
  promoMissingDates: {
    color: defaultColors.red,
    textDecoration: 'underline',
    '&:hover': {
      color: defaultColors.red,
      textDecoration: 'underline',
    },
  },
  promoMissingDatesIcon: {
    height: '14px',
  },
} as const;

export const UpcDataGridStyles = {
  styles,
};
