import { IManufacturer } from '../models';
import { HttpErrorResponse, fetchGetJson } from './contractHubApi';

export const manufacturerService = {
  searchManufacturer,
  selectManufacturerById,
};

function searchManufacturer(searchString: string): Promise<IManufacturer[] | HttpErrorResponse> {
  return fetchGetJson<IManufacturer[]>({ endpoint: 'manufacturer', query: { q: searchString } });
}

function selectManufacturerById(manufacturerId: string): Promise<IManufacturer | HttpErrorResponse> {
  return fetchGetJson<IManufacturer>({ endpoint: `manufacturer/${encodeURIComponent(manufacturerId)}` });
}
