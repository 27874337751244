import { IUser } from '../models';
import { HttpErrorResponse, fetchPost, fetchPostJson } from './contractHubApi';

export const authenticationService = {
  login,
  logout,
};

function login(accessToken: string): Promise<IUser | HttpErrorResponse> {
  return fetchPostJson<IUser>({ endpoint: 'user/login', body: { accessToken } });
}

async function logout(): Promise<boolean> {
  const response = await fetchPost({ endpoint: 'user/logout' });
  return response.ok;
}
