import { InputAdornment, OutlinedInputProps, TextField, TextFieldProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { textfieldStyles } from '../../../styles/shared/TextFieldStyles';
import { defaultColors } from '../../../styles/variables';
import { NumericFormat } from 'react-number-format';

interface IProps {
  id: string;
  value?: number | undefined;
  onChange: (value: any) => void;
  prefix?: string;
  suffix?: string;
  decimals?: number;
  thousandSeparator?: boolean;
  allowNegative?: boolean;
  allowLeadingZeros?: boolean;
  triggerChangeOnEventOnly?: boolean;
  clearOnEnter?: boolean;
  clearOnBlur?: boolean;
  errormessage?: string;
  warningmessage?: string;
}

const styles = {
  startAdornment: {
    marginTop: '10px',
    color: defaultColors.mediumGrey,
    fontWeight: 500,
  },
  text: {
    minWidth: '50px',
  },
  error: {
    ...textfieldStyles.textFieldError,
  },
  warning: {
    ...textfieldStyles.textFieldWarning,
  },
};

type TextFieldType = IProps & TextFieldProps & Omit<TextFieldProps, 'value'> & Omit<TextFieldProps, 'onChange'>;

const DbgNumberField = React.forwardRef<HTMLInputElement, TextFieldType>((props: TextFieldType, ref) => {
  const { triggerChangeOnEventOnly, clearOnEnter, clearOnBlur, ...rest } = props;

  const [text, setText] = useState<string>(rest.value?.toString() ?? '');
  const [focus, setFocus] = useState<boolean>(false);

  const textToFloat = parseFloat(text.replaceAll(',', ''));

  useEffect(() => {
    if (triggerChangeOnEventOnly) return;
    rest.onChange(textToFloat);
  }, [text]);

  useEffect(() => {
    setText(rest.value?.toString() ?? '');
  }, [rest.value]);

  function handleBlur() {
    if (rest.onChange) rest.onChange(textToFloat);

    if (clearOnBlur) {
      setText('');
    }

    setFocus(false);
  }

  return (
    <NumericFormat
      id={`txt${rest.id}`}
      customInput={TextField}
      label={props.label}
      decimalScale={props.decimals}
      thousandSeparator={props.thousandSeparator ?? true}
      allowNegative={props.allowNegative}
      allowLeadingZeros={props.allowLeadingZeros}
      fixedDecimalScale
      sx={[
        textfieldStyles.textField,
        focus && textfieldStyles.textFieldFocus,
        ...(Array.isArray(rest.sx) ? rest.sx : [rest.sx]),
        !!props.errormessage && styles.error,
        !!props.warningmessage && !props.errormessage && styles.warning,
      ]}
      value={text}
      onChange={(event) => setText(event.target.value)}
      onBlur={handleBlur}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          if (clearOnEnter) {
            setText('');
          }
          handleBlur();
        }
      }}
      InputLabelProps={{ sx: textfieldStyles.inputLabel, shrink: true }}
      inputRef={ref}
      InputProps={
        {
          disableUnderline: true,
          sx: [textfieldStyles.input],
          startAdornment: rest.prefix !== undefined && (
            <InputAdornment position="start" sx={styles.startAdornment}>
              {rest.prefix}
            </InputAdornment>
          ),
          endAdornment: rest.suffix !== undefined && <InputAdornment position="end">{rest.suffix}</InputAdornment>,
          autoComplete: 'off',
        } as Partial<OutlinedInputProps>
      }
      error={!!rest.errormessage}
      helperText={rest.errormessage}
      onFocus={() => setFocus(true)}
    />
  );
});

export default DbgNumberField;
