import { IContractTask, IContractTaskComment } from '../../models';
import { IContractTaskCreationRequest } from '../../models/requests';
import { IValidatedResponse } from '../../models/responses';
import { fetchGetJson, fetchPostJson, fetchPutJson, HttpErrorResponse } from '../contractHubApi';
import { TaskResolutionType } from '../../models/enums';

export const contractTaskService = {
  getContractTasks,
  createContractTask,
  resolveContractTask,
  createContractTaskComment,
  updateContractTaskComment,
};

async function getContractTasks(contractId: number): Promise<IContractTask[] | HttpErrorResponse> {
  return await fetchGetJson<IContractTask[]>({ endpoint: `contract/${contractId}/task` });
}

async function createContractTask(
  contractId: number,
  contractTask: IContractTaskCreationRequest
): Promise<IValidatedResponse<IContractTask> | HttpErrorResponse> {
  return fetchPostJson<IValidatedResponse<IContractTask>>({ endpoint: `contract/${contractId}/task`, body: contractTask });
}

async function resolveContractTask(
  contractId: number,
  taskId: number,
  resolutionType: TaskResolutionType
): Promise<IValidatedResponse<IContractTask> | HttpErrorResponse> {
  return fetchPutJson<IValidatedResponse<IContractTask>>({ endpoint: `contract/${contractId}/task/${taskId}/resolve/${resolutionType}` });
}

async function createContractTaskComment(
  contractId: number,
  taskId: number,
  comment: string
): Promise<IValidatedResponse<IContractTaskComment> | HttpErrorResponse> {
  return fetchPostJson<IValidatedResponse<IContractTaskComment>>({ endpoint: `contract/${contractId}/task/${taskId}/comment`, body: comment });
}

async function updateContractTaskComment(
  contractId: number,
  taskId: number,
  commentId: number,
  comment: string
): Promise<IValidatedResponse<IContractTaskComment> | HttpErrorResponse> {
  return fetchPutJson<IValidatedResponse<IContractTaskComment>>({
    endpoint: `contract/${contractId}/task/${taskId}/comment/${commentId}`,
    body: comment,
  });
}
