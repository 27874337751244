import { createTheme, alpha } from '@mui/material/styles';
import variables, { defaultColors } from '../variables';
import '../../styles/index.css';
import { IDbgRoundedButtonStyleProps } from '../../views/components/buttons/DbgRoundedButton';

declare module '@mui/material/styles' {
  export interface Theme {
    custom?: {
      borders: {
        default: string;
      };
      boxShadow: {
        default: string;
      };
      borderRadius: {
        default: string;
        extraLarge: string;
        medium: string;
        small: string;
        chip: string;
      };
    };
  }

  // allow configuration using `createMuiTheme`
  export interface ThemeOptions {
    custom?: {
      borders: {
        default: string;
      };
      boxShadow: {
        default: string;
      };
      borderRadius: {
        default: string;
        extraLarge: string;
        medium: string;
        small: string;
        chip: string;
      };
    };
  }
}

export const mainTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  custom: {
    borders: {
      default: `1px solid ${defaultColors.grey}`,
    },
    borderRadius: {
      extraLarge: '30px',
      default: '20px',
      medium: '10px',
      chip: '20px',
      small: '5px',
    },
    boxShadow: {
      default: `0 20px 40px 0 ${alpha(defaultColors.altMediumGrey, 0.3)}`,
    },
  },
  palette: {
    primary: {
      main: variables.text.primary,
      contrastText: variables.text.contrast,
    },
    secondary: {
      main: variables.text.secondary,
    },
    error: {
      main: variables.text.error,
    },
    success: {
      main: variables.text.success,
    },
    info: {
      main: variables.text.info,
    },
    text: {
      primary: variables.text.primary,
      secondary: variables.text.secondary,
      disabled: variables.text.secondary,
      // hint: alpha(variables.text.primary, 0.4),
    },
    background: {
      default: defaultColors.white,
      paper: defaultColors.white,
    },
  },
  typography: {
    fontSize: 13,
    fontFamily: ['Inter', 'Poppins', 'sans-serif'].join(','),
    // allVariants: {
    //   fontWeight: 500,
    // },
    h1: {
      fontSize: 40,
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    caption: {
      fontSize: 11,
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.8125rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
        [`
          input:-webkit-autofill, 
          input:-webkit-autofill:hover, 
          input:-webkit-autofill:focus, 
          input:-webkit-autofill:active
        `]: {
          boxShadow: `0 0 0 100px ${defaultColors.white} inset !important`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

export const getWhiteButtonStyleProps = (): IDbgRoundedButtonStyleProps => {
  return {
    color: variables.text.primary,
    colorActive: variables.text.primary,
    colorDisabled: variables.text.secondary,
    backgroundColor: defaultColors.white,
    backgroundColorDisabled: alpha(defaultColors.grey, 0.4),
    backgroundColorSelected: alpha(defaultColors.grey, 0.4),
    backgroundColorActive: alpha(defaultColors.grey, 0.4),
    fontWeight: 500,
  };
};

export const getGreenButtonStyleProps = (): IDbgRoundedButtonStyleProps => {
  return {
    color: variables.text.contrast,
    colorActive: variables.text.contrast,
    colorDisabled: variables.text.contrast,
    backgroundColor: defaultColors.green,
    backgroundColorDisabled: alpha(defaultColors.green, 0.4),
    backgroundColorSelected: alpha(defaultColors.green, 0.8),
    backgroundColorActive: alpha(defaultColors.green, 0.8),
    fontWeight: 500,
  };
};

export const getRedButtonStyleProps = (): IDbgRoundedButtonStyleProps => {
  return {
    color: variables.text.contrast,
    colorActive: variables.text.contrast,
    colorDisabled: variables.text.contrast,
    backgroundColor: defaultColors.red,
    backgroundColorDisabled: alpha(defaultColors.red, 0.4),
    backgroundColorSelected: alpha(defaultColors.red, 0.8),
    backgroundColorActive: alpha(defaultColors.red, 0.8),
    fontWeight: 500,
  };
};

export const getBlueButtonStyleProps = (): IDbgRoundedButtonStyleProps => {
  return {
    color: variables.text.contrast,
    colorActive: variables.text.contrast,
    colorDisabled: variables.text.contrast,
    backgroundColor: defaultColors.blue,
    backgroundColorDisabled: alpha(defaultColors.blue, 0.4),
    backgroundColorSelected: alpha(defaultColors.blue, 0.8),
    backgroundColorActive: alpha(defaultColors.blue, 0.8),
    fontWeight: 500,
  };
};

export const getLightBlueButtonStyleProps = (): IDbgRoundedButtonStyleProps => {
  return {
    color: defaultColors.blue,
    colorActive: defaultColors.blue,
    colorDisabled: defaultColors.blue,
    backgroundColor: alpha(defaultColors.lightBlue, 0.15),
    backgroundColorDisabled: alpha(defaultColors.lightBlue, 0.05),
    backgroundColorSelected: alpha(defaultColors.lightBlue, 0.05),
    backgroundColorActive: alpha(defaultColors.lightBlue, 0.1),
  };
};

export const getYellowButtonStyleProps = (): IDbgRoundedButtonStyleProps => {
  return {
    color: variables.text.primary,
    colorActive: variables.text.primary,
    colorDisabled: variables.text.secondary,
    backgroundColor: defaultColors.yellow,
    backgroundColorDisabled: alpha(defaultColors.yellow, 0.4),
    backgroundColorSelected: alpha(defaultColors.yellow, 0.8),
    backgroundColorActive: alpha(defaultColors.yellow, 0.8),
    fontWeight: 500,
  };
};

export const getDarkGreyButtonStyleProps = (): IDbgRoundedButtonStyleProps => {
  return {
    color: variables.text.contrast,
    colorActive: variables.text.contrast,
    colorDisabled: variables.text.secondary,
    backgroundColor: defaultColors.darkGrey,
    backgroundColorDisabled: defaultColors.grey,
    backgroundColorSelected: alpha(defaultColors.darkGrey, 0.8),
    backgroundColorActive: alpha(defaultColors.darkGrey, 0.8),
    fontWeight: 500,
  };
};

export const getLightGreyButtonStyleProps = (): IDbgRoundedButtonStyleProps => {
  return {
    color: variables.text.primary,
    colorActive: variables.text.primary,
    colorDisabled: variables.text.secondary,
    backgroundColor: defaultColors.lightGrey,
    backgroundColorDisabled: alpha(defaultColors.grey, 0.4),
    backgroundColorSelected: alpha(defaultColors.lightGrey, 0.8),
    backgroundColorActive: alpha(defaultColors.lightGrey, 0.8),
    fontWeight: 500,
  };
};

export const getTransparentButtonStyleProps = (): IDbgRoundedButtonStyleProps => {
  return {
    color: variables.text.primary,
    colorActive: variables.text.primary,
    colorDisabled: variables.text.secondary,
    backgroundColor: defaultColors.transparent,
    backgroundColorDisabled: defaultColors.transparent,
    backgroundColorSelected: alpha(defaultColors.white, 0.8),
    backgroundColorActive: alpha(defaultColors.white, 0.8),
    fontWeight: 500,
  };
};

export const getOutlinedTransparentButtonStyleProps = (): IDbgRoundedButtonStyleProps => {
  return {
    color: variables.text.primary,
    colorActive: variables.text.primary,
    colorDisabled: variables.text.secondary,
    backgroundColor: defaultColors.transparent,
    backgroundColorDisabled: defaultColors.transparent,
    backgroundColorSelected: alpha(defaultColors.white, 0.8),
    backgroundColorActive: alpha(defaultColors.white, 0.8),
    fontWeight: 500,
    borderColor: defaultColors.darkGrey,
  };
};

export const getBlueTextButtonStyleProps = (): IDbgRoundedButtonStyleProps => {
  return {
    color: defaultColors.blue,
    backgroundColor: defaultColors.grey,
    fontWeight: 500,
  };
};

export const getRedTextButtonStyleProps = (): IDbgRoundedButtonStyleProps => {
  return {
    color: defaultColors.red,
    colorActive: defaultColors.red,
    colorDisabled: defaultColors.red,
    backgroundColor: defaultColors.white,
    backgroundColorDisabled: alpha(defaultColors.grey, 0.4),
    backgroundColorSelected: alpha(defaultColors.grey, 0.4),
    backgroundColorActive: alpha(defaultColors.grey, 0.4),
    fontWeight: 500,
  };
};
