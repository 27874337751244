import { IExternalAccountUser, IUserGroup } from '../../models';
import {
  HttpErrorResponse,
  SuccessOrErrorResponse,
  fetchDeleteSuccessOrError,
  fetchGetJson,
  fetchPostSuccessOrError,
  fetchPutSuccessOrError,
} from '../contractHubApi';

export const accountUserService = {
  getAccountUsers,
  searchAccountUsers,
  getAccountUserGroups,
  createAccountUser,
  updateAccountUser,
  deleteAccountUser,
};

function getAccountUsers(externalAccountId: number): Promise<IExternalAccountUser[] | HttpErrorResponse> {
  return fetchGetJson<IExternalAccountUser[]>({ endpoint: 'user', query: { externalAccountId } });
}

function searchAccountUsers(
  externalAccountId: number,
  name?: string,
  groupName?: string,
  emailAddress?: string,
  phoneNumber?: string
): Promise<IExternalAccountUser[] | HttpErrorResponse> {
  return fetchGetJson<IExternalAccountUser[]>({ endpoint: 'user/search', query: { externalAccountId, name, groupName, emailAddress, phoneNumber } });
}

function getAccountUserGroups(): Promise<IUserGroup[] | HttpErrorResponse> {
  return fetchGetJson<IUserGroup[]>({ endpoint: `user-group` });
}

async function createAccountUser(externalAccountUser: IExternalAccountUser): Promise<SuccessOrErrorResponse> {
  return await fetchPostSuccessOrError({ endpoint: 'user', body: externalAccountUser });
}

async function updateAccountUser(externalAccountUser: IExternalAccountUser): Promise<SuccessOrErrorResponse> {
  return await fetchPutSuccessOrError({ endpoint: `user/${externalAccountUser.userId}`, body: externalAccountUser });
}

async function deleteAccountUser(externalAccountId: number, userId: number): Promise<SuccessOrErrorResponse> {
  return await fetchDeleteSuccessOrError({ endpoint: `user/${userId}`, query: { externalAccountId } });
}
