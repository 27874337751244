import { IContractAdPlannerExport } from '../../models';
import { fetchGetJson, fetchPostSuccessOrError, HttpErrorResponse, SuccessOrErrorResponse } from '../contractHubApi';

export const contractAdPlannerExportService = {
  getAdPlannerExport,
  submitAdPlannerExport,
};

async function getAdPlannerExport(contractId: number): Promise<IContractAdPlannerExport | HttpErrorResponse> {
  const response = await fetchGetJson<IContractAdPlannerExport>({
    endpoint: `contract/${contractId}/ap`,
  });
  return response;
}

function submitAdPlannerExport(contractId: number, request: IContractAdPlannerExport): Promise<SuccessOrErrorResponse> {
  return fetchPostSuccessOrError({ endpoint: `contract/${contractId}/ap`, body: request });
}
