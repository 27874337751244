import { memo, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { IAllContractsSearchResponse } from '../../../../../models/responses';
import { DataGrid, DataGridRowModel } from '@dierbergs-markets/react-component-library';
import { RouteEnum } from '../../../../layout/PageRouter';
import { useNavigate } from 'react-router-dom';
import { IContractTimeFrame } from '../../../../../models';
import { contractSearchService } from '../../../../../services';
import { useSnackbar } from 'notistack';
import useCommonSearchGridConfig from '../shared/useCommonSearchGridConfig';
import { ICommonSearchQueryParams } from '../../../../../models/requests';
import { ContractPageTabs } from '../../../../../models/enums';

interface IProps {
  timeFrame: IContractTimeFrame;
}

const OrderSurveyGrid = memo((props: IProps) => {
  //HOOKS
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { defaultDataGridProps, setGridColumns } = useCommonSearchGridConfig<ICommonSearchQueryParams, IAllContractsSearchResponse>({
    gridId: 'orderSurveyDataGrid',
    timeFrame: props.timeFrame,
    performSearch: contractSearchService.orderSurveySearch,
    handleSearchError: (message) => enqueueSnackbar(message, { variant: 'error' }),
  });

  //turn sorting off until implemented in API
  useEffect(() => setGridColumns((prev) => prev.map((c) => ({ ...c, sortable: false }))), []);

  return (
    <DataGrid
      {...defaultDataGridProps}
      getRowId={(r: IAllContractsSearchResponse) => r?.contractId ?? uuid()}
      onRowClick={(row: DataGridRowModel) => navigate(`${RouteEnum.Contract}/${row.contractId}`, { state: { tab: ContractPageTabs.Pricing } })}
      disableFiltering
    />
  );
});

export default OrderSurveyGrid;
