import { v4 } from 'uuid';
import { IContract, ICreateContractResponse } from '../../models';
import { IContractOverlapRequest, ICreateContractRequest, IUpdateContractRequest } from '../../models/requests';
import { IContractOverlapModel, IVendorContractNumberCheck } from '../../models/responses';
import { fetchDelete, fetchGetJson, fetchPostJson, fetchPutSuccessOrError, HttpErrorResponse, toDateOnlyString } from '../contractHubApi';

export const contractTermService = {
  getContract,
  createContract,
  updateContract,
  deleteContract,
  getOverlap,
  cloneContract,
  vendorContractNumberIsValid,
};

const mapUniqueIds = (contract: IContract | HttpErrorResponse) => {
  if (!(contract instanceof HttpErrorResponse)) {
    contract.terms.contractTermsForLumpSum = contract.terms.contractTermsForLumpSum.map((q) => {
      return {
        ...q,
        uniqueId: q.uniqueId ?? v4(),
      };
    });
    contract.terms.contractTermsForItem = contract.terms.contractTermsForItem.map((q) => {
      return {
        ...q,
        uniqueId: q.uniqueId ?? v4(),
      };
    });
    contract.terms.contractItems = contract.terms.contractItems.map((q) => {
      return {
        ...q,
        uniqueId: q.uniqueId ?? v4(),
      };
    });
  }
};

async function getContract(contractId: number): Promise<IContract | HttpErrorResponse> {
  const response = await fetchGetJson<IContract>({ endpoint: `contract/${contractId}` });
  mapUniqueIds(response);
  return response;
}

function createContract(request: ICreateContractRequest): Promise<ICreateContractResponse | HttpErrorResponse> {
  return fetchPostJson<ICreateContractResponse>({ endpoint: 'contract', body: request });
}

function updateContract(contractId: number, request: IUpdateContractRequest): Promise<boolean | HttpErrorResponse> {
  return fetchPutSuccessOrError({ endpoint: `contract/${contractId}`, body: request });
}
async function deleteContract(contractId: number): Promise<boolean> {
  const response = await fetchDelete({ endpoint: `contract/${contractId}` });
  return response.ok;
}

function getOverlap(request: IContractOverlapRequest): Promise<IContractOverlapModel[] | HttpErrorResponse> {
  const q = {
    startDate: toDateOnlyString(request.startDate),
    endDate: toDateOnlyString(request.endDate),
    skus: request.skus,
  };
  return fetchGetJson<IContractOverlapModel[]>({ endpoint: 'contract/overlap', query: q });
}

async function vendorContractNumberIsValid(request: IVendorContractNumberCheck): Promise<boolean | HttpErrorResponse> {
  const response = await fetchGetJson<number>({
    endpoint: `customer/${request.customerId}/contract/${encodeURIComponent(request.vendorContractNumber)}`,
  });
  if (response instanceof HttpErrorResponse) {
    if (response.status === 404) return true;
    return response;
  }
  return response === request.contractId;
}

async function cloneContract(contractId: number): Promise<IContract | HttpErrorResponse> {
  const response = await fetchGetJson<IContract>({ endpoint: `contract/${contractId}/clone` });
  mapUniqueIds(response);
  return response;
}
