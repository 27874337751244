import { useRef, useContext, useState, useCallback, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IContractTask, IContractTaskComment } from '../../../../../models';
import { TaskRootContext } from '../TasksButton';
import { Button, IconButton, TextAreaInput } from '@dierbergs-markets/react-component-library';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';
import { StyledImg } from '../../../../components/shared/styled/StyledWrappedCommonElements';
import CircleLogo from '../../../../../images/d_circle_logo.png';
import { format } from 'date-fns';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material';
import { defaultColors } from '../../../../../styles/variables';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import MoreVert from '@mui/icons-material/MoreVert';
import Check from '@mui/icons-material/Check';
import Edit from '@mui/icons-material/Edit';
import CheckPng from '../../../../../images/check.svg';
import { allowedKeys, commentResolver } from './ContractTasksCommon';
import { TaskResolutionType } from '../../../../../models/enums';
import { contractTaskService } from '../../../../../services';
import { textfieldStyles } from '../../../../../styles/shared/TextFieldStyles';
import { useStyles } from 'tss-react/mui';

interface IActionsMenuProps {
  contractId: number;
  contractTaskId: number;
  isSelf: boolean;
  isResolved: boolean;
  isFirst: boolean;
  setInEditModel: (isExpaned: boolean) => void;
}
function ActionsMenu(props: IActionsMenuProps) {
  const { isFirst, isResolved, isSelf, setInEditModel, contractId, contractTaskId } = props;
  const taskRootContext = useContext(TaskRootContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showAcknowledgeMenu, setShowAcknowledgeMenu] = useState(false);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClickAck = useCallback(() => {
    setShowAcknowledgeMenu(true);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setTimeout(() => setShowAcknowledgeMenu(false), 1000);
  }, []);
  return (
    <>
      {(isFirst || (isSelf && !isResolved)) && <IconButton id="iconMenu" icon={<MoreVert />} onClick={handleClick}></IconButton>}
      <Menu
        id="commentMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {!showAcknowledgeMenu && (
          <MenuList>
            {isFirst && (
              <MenuItem onClick={handleClickAck}>
                <ListItemIcon>
                  <Check style={{ color: 'green' }} fontSize="small" />
                </ListItemIcon>
                <ListItemText>Acknowledge</ListItemText>
              </MenuItem>
            )}
            {isSelf && !isResolved && (
              <MenuItem onClick={() => setInEditModel(true)}>
                <ListItemIcon>
                  <Edit style={{ color: 'blue' }} fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
            )}
          </MenuList>
        )}
        {showAcknowledgeMenu && (
          <MenuList>
            <MenuItem
              onClick={() =>
                (async () => {
                  await contractTaskService.resolveContractTask(contractId, contractTaskId, TaskResolutionType.COMPLETED);
                  handleClose();
                  await taskRootContext.refreshContractTasks();
                })()
              }
            >
              <ListItemText>Resolve</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() =>
                (async () => {
                  await contractTaskService.resolveContractTask(contractId, contractTaskId, TaskResolutionType.DECLINED);
                  handleClose();
                  await taskRootContext.refreshContractTasks();
                })()
              }
            >
              <ListItemText>Decline</ListItemText>
            </MenuItem>
          </MenuList>
        )}
      </Menu>
    </>
  );
}

export interface IContractTaskItemCommentItemProps {
  task: IContractTask;
  comment: IContractTaskComment;
  isFirst: boolean;
  setIsExpanded: (isExpaned: boolean) => void;
  isExpanded: boolean;
}

interface IContractTaskCommentEditingForm {
  comment: string;
}
export function ContractTaskCommentItem(props: IContractTaskItemCommentItemProps) {
  const { css } = useStyles();
  const { task, comment, isFirst, setIsExpanded, isExpanded } = props;
  const commentRef = useRef<HTMLTextAreaElement>(null);
  const taskRootContext = useContext(TaskRootContext);
  const { user } = useApplicationContextState();
  const [inEditMode, setInEditMode] = useState(false);
  const contractTaskId = useMemo(() => task.contractTaskId, [task]);
  const numberOfReply = useMemo(() => task.comments.length - 1, [task.comments.length]);
  const resolution = useMemo(() => task.resolution?.type, [task.resolution?.type]);
  const onSubmit: SubmitHandler<IContractTaskCommentEditingForm> = (content: IContractTaskCommentEditingForm) => {
    contractTaskService
      .updateContractTaskComment(taskRootContext.contractId ?? 0, contractTaskId, comment.contractTaskCommentId, content.comment)
      .then(() => {
        taskRootContext.refreshContractTasks();
        setInEditMode(false);
      });
  };
  const defaultValues: IContractTaskCommentEditingForm = {
    comment: comment.messageText,
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IContractTaskCommentEditingForm>({ defaultValues, resolver: commentResolver });
  return (
    <Box sx={isFirst ? styles.container.firstBorderStyle : {}}>
      <Box sx={styles.container.header}>
        {comment.createdBy.isInternal ? (
          <StyledImg src={CircleLogo} sx={styles.container.header.circleLogo} />
        ) : (
          <Box sx={styles.container.header.greyCircle} />
        )}
        <Box sx={styles.container.header.nameAndDate}>
          <span>
            {comment.createdBy.displayName} {!comment.createdBy.isActive && ' (Inactive)'}
          </span>
          <span>{format(comment.createdAtUtc, 'MM/dd/yy h:mmaaa')} </span>
        </Box>
        {!inEditMode && !resolution && (
          <ActionsMenu
            isFirst={isFirst}
            contractId={taskRootContext.contractId ?? 0}
            contractTaskId={task.contractTaskId}
            isResolved={!!resolution}
            isSelf={comment.createdBy.userId === user?.id}
            setInEditModel={setInEditMode}
          />
        )}
        {resolution === TaskResolutionType.COMPLETED && isFirst && <StyledImg src={CheckPng} sx={styles.container.header.greenCheck} />}
      </Box>

      {!inEditMode && <Box sx={styles.container.text}>{comment.messageText}</Box>}
      {inEditMode && !resolution && (
        <Box sx={styles.container.header.formContainer}>
          <Controller
            control={control}
            name={'comment'}
            render={({ field }) => (
              <TextAreaInput
                id="comment"
                ref={commentRef}
                allowedkeys={allowedKeys}
                tabIndex={1}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && commentRef.current) {
                    commentRef.current.focus();
                  }
                }}
                placeholder={'Write a message...'}
                onChange={field.onChange}
                value={field.value || ''}
                errorMessage={errors.comment?.message}
                rows={4}
                classes={{
                  root: css({ ...(styles.container.header.formContainer.message as any), ...(textfieldStyles.textInputHover as any) }),
                  input: css(styles.container.header.formContainer.message.input as any),
                }}
              />
            )}
          />
          <Box sx={styles.container.header.formContainer.buttons}>
            <Button
              id={`editComment-${comment.contractTaskCommentId}-Cancel`}
              text="Cancel"
              variant="link"
              color="grey"
              onClick={() => setInEditMode(false)}
            ></Button>
            <Button
              id={`editComment-${comment.contractTaskCommentId}-Save`}
              text="Save"
              variant="link"
              color="blue"
              onClick={handleSubmit(onSubmit)}
            ></Button>
          </Box>
        </Box>
      )}

      {isFirst && (numberOfReply > 0 || !resolution) && (
        <Box sx={styles.container.numberOfReply}>
          {numberOfReply == 0 && <Box>No reply</Box>}
          {numberOfReply > 0 && <Box>{`${numberOfReply} Repl${numberOfReply > 1 ? 'ies' : 'y'}`}</Box>}
          {isExpanded ? (
            <IconButton
              id={`commentItem-${comment.contractTaskCommentId}-iconUp`}
              color="grey"
              icon={<KeyboardArrowUp />}
              onClick={() => setIsExpanded(false)}
            ></IconButton>
          ) : (
            <IconButton
              id={`commentItem-${comment.contractTaskCommentId}-iconDown`}
              color="grey"
              icon={<KeyboardArrowDown />}
              onClick={() => setIsExpanded(true)}
            ></IconButton>
          )}
        </Box>
      )}
    </Box>
  );
}

const styles = {
  container: {
    firstBorderStyle: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: defaultColors.grey,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '20px',
      paddingBottom: '10px',
      paddingLeft: '30px',
      paddingRight: '15px',
      flexGrow: 2,
      fontSize: '12px',
      color: defaultColors.mediumGrey,
      formContainer: {
        paddingLeft: '30px',
        paddingRight: '20px',
        message: {
          marginTop: '10px',
          boxSizing: 'border-box',
          width: '100%',
          input: {
            ...textfieldStyles.textarea,
          },
        },
        buttons: {
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '0px 10px',
        },
      },

      nameAndDate: {
        paddingLeft: '10px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
      },
      circleLogo: {
        minWidth: '48px',
        minHeight: '48px',
        maxWidth: '48px',
        maxHeight: '48px',
      },
      greyCircle: {
        minWidth: '48px',
        minHeight: '48px',
        maxWidth: '48px',
        maxHeight: '48px',
        borderRadius: '50%',
        backgroundColor: '#D8D8D8',
      },
      greenCheck: {
        with: '20px',
        height: '20px',
      },
    },
    text: {
      paddingLeft: '30px',
      paddingRight: '20px',
      paddingBottom: '10px',
      color: defaultColors.mediumGrey,
      fontSize: '14px',
    },
    numberOfReply: {
      paddingLeft: '30px',
      paddingRight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: defaultColors.mediumGrey,
      fontSize: '14px',
    },
  },
};
