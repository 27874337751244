import { Button, Chip, IconButton, Popover } from '@dierbergs-markets/react-component-library';
import { MdOutlineNotifications, MdNotificationsActive } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { HTMLAttributes, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { contractNotificationService } from '../../../services';
import { HttpErrorResponse } from '../../../services/contractHubApi';
import { defaultColors } from '../../../styles/variables';
import { useStyles } from 'tss-react';
import { RouteEnum } from '../PageRouter';
import { MdOutlineCheck } from 'react-icons/md';
import { useApplicationContextState } from '../../../contexts/ApplicationContext';
import { useRefreshNotificationCounterState } from '../../../store';

interface ContractNotificationProps extends HTMLAttributes<HTMLDivElement> {}
export default function ContractNotifications(props: ContractNotificationProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { css } = useStyles();
  const counter = useRefreshNotificationCounterState((state) => state.counter);
  const { contractNotifications, setContractNotifications, user } = useApplicationContextState();

  async function loadNotifications() {
    const response = await contractNotificationService.getNotifications();

    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to retrieve contract notifications.', { variant: 'error' });
      return;
    }
    setContractNotifications?.(response);
  }

  useEffect(() => {
    //get latest notifications whenever the route changes
    user && (async () => await loadNotifications())();
  }, [location, user, counter]);

  function Icon() {
    const iconToDisplay =
      contractNotifications.length > 0 ? <MdNotificationsActive style={styles.icon} /> : <MdOutlineNotifications style={styles.icon} />;

    return (
      <div className={css(styles.root)}>
        {iconToDisplay}
        {contractNotifications.length > 0 && (
          <div className={css(styles.countBadge)}>
            <div className={css(styles.count)}>{contractNotifications.length}</div>
          </div>
        )}
      </div>
    );
  }

  async function markRead(contractId: number) {
    const response = await contractNotificationService.acknowledgeNotifications(contractId);

    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to mark resolved.', { variant: 'error' });
      return;
    }
    await loadNotifications();
  }
  return (
    <div {...props}>
      <Popover
        id={'notifications'}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        buttonProps={{
          variant: 'icon',
          classes: {
            root: css({
              '&&:hover': { backgroundColor: 'unset' },
            }),
          },
          children: <Icon />,
        }}
        classes={{
          popoverContent: css(styles.popover.content),
        }}
      >
        <>
          <div className={css(styles.title)}>Contract Notifications</div>

          {contractNotifications.length === 0 && <div>No notifications.</div>}
          {contractNotifications.length > 0 &&
            contractNotifications.map((n, id) => {
              return (
                <div key={n.contractId} className={css(styles.popover.contract)}>
                  <div className={css(styles.popover.contractActions)}>
                    <div className={css(styles.popover.contractActionFields)}>
                      <div className={css(styles.popover.contractActionFieldsRow)}>
                        <div className={css(styles.popover.contractActionFieldsColumn)}>
                          <div className={css(styles.popover.contractActionFieldLabel)}>Bill-to</div>
                          <div className={css(styles.popover.contractActionFieldContent)} title={n.customerName}>
                            {n.customerName}
                          </div>
                        </div>
                        <div className={css(styles.popover.contractActionFieldsColumn)}>
                          <div className={css(styles.popover.contractActionFieldLabel)}>Contract #</div>
                          <Button
                            id={`btnContractLink_${id}`}
                            className={css({ ...styles.popover.contractLink, ...styles.popover.contractActionFieldContent })}
                            variant="link"
                            onClick={() => navigate(`${RouteEnum.Contract}/${n.contractId}`)}
                            title={n.vendorContractNumber}
                          >
                            <div className={css(styles.popover.contractActionFieldContent)}>{n.vendorContractNumber}</div>
                          </Button>
                        </div>
                      </div>
                      <div className={css(styles.popover.contractActionFieldsRow)}>
                        <div className={css(styles.popover.contractActionFieldsColumn)}>
                          <div className={css(styles.popover.contractActionFieldLabel)}>Supplier Name</div>
                          <div className={css(styles.popover.contractActionFieldContent)} title={n.supplierName}>
                            {n.supplierName}
                          </div>
                        </div>
                        <div className={css(styles.popover.contractActionFieldsColumn)}>
                          <div className={css(styles.popover.contractActionFieldLabel)}>Internal Contract #</div>
                          <div className={css(styles.popover.contractActionFieldContent)} title={`${n.contractId}`}>
                            {n.contractId}
                          </div>
                        </div>
                      </div>
                    </div>
                    <IconButton
                      id={`btnMarkRead_${id}`}
                      title={'Mark as read'}
                      onClick={() => markRead(n.contractId)}
                      icon={<MdOutlineCheck />}
                      color={defaultColors.grey}
                      classes={{
                        root: css(styles.popover.markReadBtn),
                      }}
                    />
                  </div>
                  <div className={css(styles.popover.notifcations)}>
                    {n.notificationTypes.map((value) => (
                      <Chip
                        key={value}
                        label={value.toLowerCase()}
                        variant="outlined"
                        classes={{
                          root: css(styles.popover.notification),
                        }}
                      />
                    ))}
                  </div>
                </div>
              );
            })}
        </>
      </Popover>
    </div>
  );
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  title: {
    textAlign: 'center',
    width: '100%',
    fontWeight: 500,
    marginTop: '10px',
    marginBottom: '10px',
  },
  popover: {
    root: {},
    content: {
      width: '400px',
    },
    contract: {
      '&:not(:last-child)': {
        paddingBottom: '10px',
        marginBottom: '10px',
        borderBottom: `1px dotted ${defaultColors.border}`,
      },
    },
    contractActions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    contractActionFields: {
      display: 'flex',
      flexFlow: 'column',
      gap: '15px',
      width: '95%',
      minWidth: '95%',
    },
    contractActionFieldsRow: {
      display: 'flex',
      alignItems: 'flexStart',
      width: '100%',
    },
    contractActionFieldsColumn: {
      flex: 1,
      display: 'flex',
      flexFlow: 'column',
      maxWidth: '50%',
    },
    contractActionFieldLabel: {
      textDecoration: 'none',
    },
    contractActionFieldContent: {
      textDecoration: 'none',
      fontSize: '15px',
      fontWeight: 600,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    notifcations: {
      paddingTop: '16px',
      display: 'flex',
    },
    notification: {
      marginRight: '4px',
      '&&': {
        borderColor: defaultColors.blue,
      },
    },
    markReadBtn: {
      '&&': {
        fontSize: '16px',
        color: defaultColors.darkGrey,
        backgroundColor: 'unset',
        '&:hover': {
          backgroundColor: defaultColors.green,
          color: defaultColors.white,
        },
      },
    },
    contractLink: {
      textDecoration: 'none',
      fontSize: '15px',
      fontWeight: 600,
      color: defaultColors.blue,
    },
  },
  count: {
    fontSize: '10px',
    fontWeight: 600,
    color: defaultColors.white,
    marginLeft: '6px',
    marginRight: '6px',
  },
  countBadge: {
    minWidth: '21px',
    maxWidth: '32px',
    backgroundColor: defaultColors.red,
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    border: `1px solid ${defaultColors.white}`,
    marginLeft: '-10px',
    marginTop: '-10px',
  },
  icon: {
    fontSize: '22px',
    color: defaultColors.darkGrey,
    cursor: 'pointer',
    '&&:hover': {
      backgroundColor: 'unset',
    },
  },
} as const;
