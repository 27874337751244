export interface IGrouping<TType, TKey> {
  key: TKey;
  items: TType[];
}

function equals<T>(a: T[], b: T[]): boolean {
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function distinct<T>(array: T[], equalFunc?: (a: T, b: T) => boolean): Array<T> {
  const itemsEqualFunc = equalFunc || ((a: T, b: T) => a === b);

  const result: Array<T> = [];
  for (const item of array) {
    const match = result.find((x) => itemsEqualFunc(item, x));
    if (!match) result.push(item);
  }
  return result;
}

function groupBy<T, TKey>(array: T[], keyFunc: (t: T) => TKey, keyEqualFunc?: (k1: TKey, k2: TKey) => boolean): Array<IGrouping<T, TKey>> {
  return arrayGroupBy<T, TKey>(array, keyFunc, keyEqualFunc);
}

function orderBy<T, TSort>(array: T[], sortPropFunc: (t: T) => TSort): Array<T> {
  const result = [...array];
  const compareFunc = (a: T, b: T) => {
    const aKey = sortPropFunc(a);
    const bKey = sortPropFunc(b);
    if (aKey < bKey) return -1;
    if (aKey > bKey) return 1;
    return 0;
  };
  return result.sort(compareFunc);
}

function orderByDescending<T, TSort>(array: T[], sortPropFunc: (t: T) => TSort): Array<T> {
  const result = [...array];
  const compareFunc = (a: T, b: T) => {
    const aKey = sortPropFunc(a);
    const bKey = sortPropFunc(b);
    if (aKey < bKey) return 1;
    if (aKey > bKey) return -1;
    return 0;
  };
  return result.sort(compareFunc);
}

function sum<T>(array: T[], numberPropFunc?: (t: T) => number): number {
  const numberProp = numberPropFunc || ((t) => (typeof t === 'number' ? t : 0));

  let result = 0;
  for (const item of array) {
    result += numberProp(item);
  }
  return result;
}

function arrayGroupBy<TType, TKey>(
  array: TType[],
  keyFunc: (t: TType) => TKey,
  keyEqualFunc?: (k1: TKey, k2: TKey) => boolean
): IGrouping<TType, TKey>[] {
  const groups: IGrouping<TType, TKey>[] = [];
  const keyEqual = keyEqualFunc || ((k1: TKey, k2: TKey) => k1 === k2);

  for (const item of array) {
    const key = keyFunc(item);
    let group = groups.find((g) => keyEqual(g.key, key));
    if (!group) {
      group = { key: key, items: [] };
      groups.push(group);
    }
    group.items.push(item);
  }

  return groups;
}

function arrayCopy<TType>(array: TType[]) {
  const result = [] as TType[];
  const keys = Object.keys(array);
  for (const key of keys) result[key] = array[key];
  return result;
}

export const ArrayUtils = {
  equals,
  distinct,
  groupBy,
  orderBy,
  orderByDescending,
  sum,
  arrayGroupBy,
  arrayCopy,
};
