import { useApplicationContextState } from '../../../contexts/ApplicationContext';
import { Permission } from '../../../models/enums';

interface IProps {
  permission: Permission;
  error?: string;
}

export default function PermissionFilter(props: React.PropsWithChildren<IProps>): JSX.Element {
  const { user } = useApplicationContextState();

  if (user?.permissions.includes(props.permission)) return <>{props.children}</>;
  return props.error ? <span>{props.error}</span> : <></>;
}
