import React from 'react';
import { Button, Theme, ButtonProps, ExtendButtonBase } from '@mui/material';

export interface IDbgLinkButtonStyleProps {
  lineHeight?: string;
  fontSize?: string;
  color?: string;
  colorDisabled?: string;
  colorSelected?: string;
  colorActive?: string;
}

interface IDbgLinkButtonProps extends ButtonProps, Omit<ExtendButtonBase<any>, 'classes' | 'color'> {
  disabled?: boolean;
  selected?: boolean;
  styleProps?: IDbgLinkButtonStyleProps;
  id: string;
}

const DbgLinkButton = ({ styleProps, id, sx = [], ...otherProps }: IDbgLinkButtonProps) => {
  const customCss: IDbgLinkButtonStyleProps = {
    ...styleProps,
  };

  const buttonStyles = [
    {
      fontSize: (theme: Theme) => (customCss && customCss.fontSize) || theme.typography.fontSize,
      backgroundColor: 'unset',
      color: (theme: Theme) => (customCss && customCss.color) || theme.palette.info.main,
      padding: '0px',
      minWidth: 'unset',
      borderRadius: 'unset',
      border: 'unset',
      fontWeight: 'normal',
      letterSpacing: 0,
      textAlign: 'center',
      textTransform: 'unset',
      '&:active, &:hover': {
        border: 'unset',
        color: (theme: Theme) => (customCss && customCss.colorActive) || theme.palette.info.light,
        backgroundColor: 'unset',
      },
      '&.Mui-disabled': {
        opacity: 0.5,
        color: (theme: Theme) => (customCss && customCss.colorDisabled) || theme.palette.primary.main,
        backgroundColor: 'unset',
      },
    },
    !!otherProps.selected && {
      border: (theme: Theme) => theme.custom?.borders.default,
      color: (theme: Theme) => (customCss && customCss.colorSelected) || theme.palette.info.main,
      backgroundColor: 'unset',
    },
  ];

  return (
    <Button
      sx={[
        ...buttonStyles,
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...otherProps}
      id={`lnkBtn${id}`}
    />
  );
};

export { DbgLinkButton };
