import { IContractNotification } from '../../models';
import { fetchGetJson, fetchPostSuccessOrError, HttpErrorResponse, SuccessOrErrorResponse } from '../contractHubApi';

export const contractNotificationService = {
  getNotifications,
  acknowledgeNotifications,
};

async function getNotifications(): Promise<IContractNotification[] | HttpErrorResponse> {
  return await fetchGetJson<IContractNotification[]>({ endpoint: 'contract/notification' });
}

async function acknowledgeNotifications(contractId: number): Promise<SuccessOrErrorResponse> {
  return await fetchPostSuccessOrError({ endpoint: `contract/${contractId}/notification` });
}
