import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';
import { ICategoryManager, IContract, ICustomer, IManufacturer, ISupplier } from '../../../../../models';
import DbgDialog from '../../../../components/dialogs/DbgDialog';
import { RouteEnum } from '../../../../layout/PageRouter';
import NewContractModalStoreSelections from './NewContractModalStoreSelections';
import { HttpErrorResponse } from '../../../../../services/contractHubApi';
import { ArrayUtils } from '../../../../../utilities/ArrayUtility';
import { NewContractModalStyles } from './styles/NewContractModalStyles';
import { contractTermService, manufacturerService, customerService } from '../../../../../services';
import { useSnackbar } from 'notistack';
import { useStyles } from 'tss-react/mui';
import { AutoComplete, TextInput } from '@dierbergs-markets/react-component-library';
import { textfieldStyles } from '../../../../../styles/shared/TextFieldStyles';
import { supplierService } from '../../../../../services/supplierService';

interface IProps {
  initialContract: IContract;
  onContinue: (editedContract: IContract) => void;
  onCancel: () => void;
}

const NewContractModal = (props: IProps) => {
  const { referenceData } = useApplicationContextState();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const { styles } = NewContractModalStyles;

  const contractNumberRef = useRef<HTMLInputElement>(null);
  const customerRef = useRef<HTMLInputElement>(null);
  const manufacturerRef = useRef<HTMLInputElement>(null);
  const supplierRef = useRef<HTMLInputElement>(null);
  const productLineRef = useRef<HTMLInputElement>(null);
  const commentsRef = useRef<HTMLInputElement>(null);
  const categoryManagerRef = useRef<HTMLInputElement>(null);
  const storeRef = useRef<HTMLInputElement>(null);
  const { css } = useStyles();
  const processValidation = useRef<boolean>(false);

  async function validateVendorContractNumberAsync() {
    const response = await contractTermService.vendorContractNumberIsValid({
      contractId: props.initialContract.contractId,
      customerId: getValues('terms.customer.customerId'),
      vendorContractNumber: getValues('terms.vendorContractNumber'),
    });

    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to validate vendor contract number. Please try again.', { variant: 'error' });
      return;
    }

    if (response === false) {
      const message = props.initialContract.originalContractId
        ? 'The contract # must be a unique value.'
        : 'This contract exists. Please start a new contract with a unique Contract #.';

      return message;
    }
  }

  const schema = Joi.object({
    terms: {
      vendorContractNumber: Joi.string().required().messages({ 'string.empty': 'Vendor Contract # is required.' }),
      customer: Joi.object<ICustomer>().required().messages({ 'any.required': 'Customer is required.' }),
      manufacturer: Joi.object<IManufacturer>().required().messages({ 'any.required': 'Manufacturer is required.' }),
      supplier: Joi.object<ISupplier>().required().messages({ 'any.required': 'Supplier is required.' }),
      categoryManager: Joi.object<ICategoryManager>().required().messages({ 'any.required': 'Category Manager is required.' }),
    },
  });

  const resolver = joiResolver(schema, { abortEarly: false, allowUnknown: true }, { mode: 'async' });

  const isNewForm = (): boolean => {
    let result = true;
    result = props.initialContract.contractId || location.pathname === RouteEnum.Contract ? false : true;
    return result;
  };

  const categoryManagerOptions = (referenceData && referenceData.categoryManagers.all.filter((q) => q.isActive)) || [];

  const defaultValues: IContract = {
    ...props.initialContract,
    terms: {
      ...props.initialContract.terms,
      categoryManager: categoryManagerOptions.find((cm) => cm.id === props.initialContract.terms.categoryManager?.id),
    },
  };

  const onSubmit: SubmitHandler<IContract> = (data: IContract) => handleConfirm(data);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
    setError,
    trigger,
    clearErrors,
    watch,
  } = useForm<IContract>({ defaultValues, resolver, criteriaMode: 'all' });

  const handleFormSubmit = handleSubmit(onSubmit);

  const handleConfirm = (data: IContract) => {
    props.onContinue({
      ...props.initialContract,
      terms: {
        ...props.initialContract.terms,
        categoryManager: data.terms.categoryManager,
        manufacturer: data.terms.manufacturer,
        supplier: data.terms.supplier,
        customer: data.terms.customer,
        stores: data.terms.stores,
        productLine: data.terms.productLine,
        comments: data.terms.comments,
        vendorContractNumber: data.terms.vendorContractNumber,
      },
    });
  };

  ///set a slight delay before setting focus on contract number field
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (contractNumberRef.current) {
        contractNumberRef.current.focus();

        if (!props.initialContract.contractId && props.initialContract.originalContractId) contractNumberRef.current.select();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const subscription = watch(() => {
      if (processValidation.current) handleCustomValidation();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const handleCancel = () => {
    props.onCancel();
  };

  const handleSelectStore = (selection: number) => {
    const result = [...getValues('terms.stores.storeIds')];

    const foundSelectionIndex = result.findIndex((x) => x === selection);
    if (foundSelectionIndex === -1) {
      if (!referenceData) return;

      const toAdd = referenceData.stores.byId[selection];
      toAdd && result.push(toAdd.id);
    } else if (foundSelectionIndex !== -1) {
      result.splice(foundSelectionIndex, 1);
    }
    setValue('terms.stores', { storeIds: [...result], storeGroupIds: [] });
  };

  const handleSelectStoreGroup = (selection: number) => {
    if (!referenceData) return;
    const toAdd = referenceData.storeGroups.all.find((sg) => sg.id === selection);
    toAdd && setValue('terms.stores', { storeIds: [], storeGroupIds: [toAdd.id] });
  };

  /**
   * Calls the provided search.
   * On success returning the results.
   * On error displays a snackbar error and returns an empty array.
   */
  async function performSearch<TResponse>(
    name: string,
    search: (query: string) => Promise<TResponse[] | HttpErrorResponse>,
    query: string
  ): Promise<TResponse[]> {
    const response = await search(query);
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar(name + ' search error.');
      return [];
    }
    return response;
  }

  const SearchCustomers = async (query: string): Promise<HttpErrorResponse | ICustomer[]> => {
    return (await performSearch('Customer', customerService.searchCustomerId, query)).filter((c) => c.isActive);
  };

  const SearchManufacturers = async (query: string): Promise<IManufacturer[]> => {
    return (await performSearch('Manufacturer', manufacturerService.searchManufacturer, query)).filter((m) => m.isActive);
  };

  const SearchSuppliers = async (query: string): Promise<ISupplier[]> => {
    return await performSearch('Supplier', supplierService.search, query);
  };

  const SearchCategoryManagers = (query: string): ICategoryManager[] => {
    let result: ICategoryManager[] = [];
    if (!categoryManagerOptions) return result;
    result = query.length > 0 ? categoryManagerOptions.filter((q) => q.name.toLowerCase().includes(query.toLowerCase())) : categoryManagerOptions;

    return ArrayUtils.orderBy(result, (x) => x.name);
  };

  const handleCustomValidation = async () => {
    // Clear JOI errors
    clearErrors();

    // Run JOI schema validation
    // There is sometimes a delay in updating "errors", so we need to use a flag
    let isValid = await trigger();

    // Next, run any custom validation, and add errors to joi
    if (getValues('terms.vendorContractNumber')) {
      const message = await validateVendorContractNumberAsync();
      if (message) {
        setError('terms.vendorContractNumber', { message });
        isValid = false;
      }
    }

    return isValid;
  };

  const customHandleSubmit = async () => {
    // Set processValidation to true so now validation will automatically run, since using trigger disables it by default
    processValidation.current = true;

    // If no JOI errors or custom errors, submit form
    const isValid = await handleCustomValidation();
    if (isValid) await handleFormSubmit();
  };

  return (
    <DbgDialog
      id="NewContractModal"
      open={true}
      sx={styles.dbgDialogOverrides}
      onConfirm={customHandleSubmit}
      onCancel={handleCancel}
      title={`${isNewForm() ? 'New' : 'Edit'} Contract`}
      confirmText="Continue"
      cancelText="Cancel"
      disabled={!referenceData || isSubmitting}
    >
      {referenceData && (
        <>
          <Box sx={[styles.formRow]}>
            <Controller
              control={control}
              name={'terms.vendorContractNumber'}
              render={({ field }) => (
                <TextInput
                  id="vendorContractNumberText"
                  label="Contract #"
                  ref={contractNumberRef}
                  classes={{
                    root: css({ ...(styles.formFields.vendorContractNumber as any), ...(textfieldStyles.textInputHover as any) }),
                    input: css(textfieldStyles.textInputInput as any),
                    errorText: css(textfieldStyles.textInputError as any),
                  }}
                  tabIndex={1}
                  maxLength={50}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && customerRef.current) {
                      customerRef.current.focus();
                    }
                  }}
                  onChange={field.onChange}
                  value={field.value || ''}
                  errorMessage={errors.terms?.vendorContractNumber?.message}
                />
              )}
            />
          </Box>
          <Box sx={[styles.formRow]}>
            <Controller
              control={control}
              name={'terms.productLine'}
              render={({ field }) => (
                <TextInput
                  id="productLineText"
                  label="Product Line"
                  ref={productLineRef}
                  classes={{
                    root: css({ ...(styles.formFields.productLine as any), ...(textfieldStyles.textInputHover as any) }),
                    input: css(textfieldStyles.textInputInput as any),
                  }}
                  onChange={field.onChange}
                  value={field.value}
                  errorMessage={errors.terms?.productLine?.message}
                  tabIndex={2}
                  maxLength={50}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && categoryManagerRef.current) {
                      categoryManagerRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Box>
          <Box sx={[styles.formRow]}>
            <Controller
              control={control}
              name={'terms.customer'}
              render={({ field }) => (
                <>
                  <AutoComplete
                    id={'customers'}
                    label={'Bill to Account #'}
                    inputRef={customerRef}
                    initialValue={getValues('terms.customer')}
                    onSelected={field.onChange}
                    onManualChangeFocus={(c) => {
                      if (c && manufacturerRef.current) manufacturerRef.current.focus();
                    }}
                    optionValueAsInputText
                    queryOptionsAsync={SearchCustomers}
                    valueExtractor={(option) => option.customerId}
                    textExtractor={(option) => option.name}
                    errormessage={errors.terms?.customer?.message}
                    sx={styles.formFields.customer}
                    textInputProps={{
                      tabIndex: 3,
                    }}
                  />
                  <TextInput
                    id="CustomerNameText"
                    disabled={true}
                    label="Bill to Acct Name"
                    classes={{
                      root: css({ ...(styles.formFields.customerName as any), ...(textfieldStyles.textInputHover as any) }),
                      input: css(textfieldStyles.textInputInput as any),
                    }}
                    onChange={() => {
                      /*unused*/
                    }}
                    value={getValues('terms.customer.name')}
                  />
                </>
              )}
            />
          </Box>
          <Box sx={[styles.formRow]}>
            <Controller
              control={control}
              name={'terms.manufacturer'}
              render={({ field }) => (
                <AutoComplete
                  id={'manufacturers'}
                  label={'Manufacturer'}
                  inputRef={manufacturerRef}
                  initialValue={getValues('terms.manufacturer')}
                  onSelected={field.onChange}
                  onManualChangeFocus={(m) => {
                    if (m && supplierRef.current) supplierRef.current.focus();
                  }}
                  characterThreshold={2}
                  queryOptionsAsync={SearchManufacturers}
                  valueExtractor={(option) => option.manufacturerId}
                  textExtractor={(option) => option.name}
                  errormessage={errors.terms?.manufacturer?.message}
                  sx={styles.formFields.manufacturer}
                  textInputProps={{
                    tabIndex: 4,
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name={'terms.supplier'}
              render={({ field }) => (
                <AutoComplete
                  id={'suppliers'}
                  label={'Supplier'}
                  inputRef={supplierRef}
                  initialValue={getValues('terms.supplier')}
                  onSelected={field.onChange}
                  onManualChangeFocus={(m) => {
                    if (m && categoryManagerRef.current) categoryManagerRef.current.focus();
                  }}
                  characterThreshold={1}
                  queryOptionsAsync={SearchSuppliers}
                  valueExtractor={(option) => option.id}
                  textExtractor={(option) => option.name}
                  errormessage={errors.terms?.supplier?.message}
                  sx={styles.formFields.supplier}
                  textInputProps={{
                    tabIndex: 5,
                  }}
                />
              )}
            />
          </Box>
          <Box sx={[styles.formRow]}>
            <Controller
              control={control}
              name={'terms.categoryManager'}
              render={({ field }) => (
                <AutoComplete
                  id="categoryManagerSelect"
                  label="Category Manager"
                  inputRef={categoryManagerRef}
                  queryOptions={SearchCategoryManagers}
                  initialValue={getValues('terms.categoryManager')}
                  onSelected={field.onChange}
                  onManualChangeFocus={(cm) => {
                    if (cm && storeRef.current) {
                      storeRef.current.focus();
                    }
                  }}
                  valueExtractor={(option) => option.id}
                  textExtractor={(option) => option.name}
                  errormessage={errors.terms?.categoryManager?.message}
                  sx={styles.formFields.categoryManager}
                  optionRender={(option) => <Box>{option.name}</Box>}
                  characterThreshold={1}
                  textInputProps={{
                    tabIndex: 6,
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name={'terms.stores'}
              render={() => {
                const storeIds = getValues('terms.stores.storeIds');
                const storeGroupIds = getValues('terms.stores.storeGroupIds');
                let storeGroupIdsOverride: number[] | null = null;
                // If there is no group or store selection. Default to 'All Stores' Group.
                if (storeGroupIds.length === 0 && storeIds.length === 0) {
                  const allStoresGroup = referenceData && referenceData?.storeGroups.all.find((sg) => sg.displayName === 'All Stores');
                  // If the 'All Stores' group is found, set its ID as the default value
                  if (allStoresGroup) {
                    handleSelectStoreGroup(allStoresGroup.id);
                    storeGroupIdsOverride = [allStoresGroup.id];
                  }
                }
                return (
                  <NewContractModalStoreSelections
                    id="selectedStoreIdsSelect"
                    label={getValues('terms.stores.storeGroupIds').length > 0 ? 'Store group included' : 'Stores included'}
                    ref={storeRef}
                    onSelectStore={handleSelectStore}
                    onSelectStoreGroup={handleSelectStoreGroup}
                    tabIndex={7}
                    options={{
                      stores: referenceData.stores.all,
                      storeGroups: referenceData.storeGroups.all.sort((a, b) => a.storeGroupName.localeCompare(b.storeGroupName)),
                    }}
                    selections={{ storeIds, storeGroupIds: storeGroupIdsOverride ?? storeGroupIds }}
                    errormessage={errors.terms?.stores?.message}
                  />
                );
              }}
            />
          </Box>
          <Box sx={[styles.formRow]}>
            <Controller
              control={control}
              name={'terms.comments'}
              render={({ field }) => (
                <TextInput
                  id="comments"
                  label="Comments"
                  ref={commentsRef}
                  classes={{
                    root: css({ ...(styles.formFields.comments as any), ...(textfieldStyles.textInputHover as any) }),
                    input: css(textfieldStyles.textInputInput as any),
                    errorText: css(textfieldStyles.textInputError as any),
                  }}
                  tabIndex={8}
                  maxLength={500}
                  onChange={field.onChange}
                  value={field.value || ''}
                  errorMessage={errors.terms?.comments?.message}
                />
              )}
            />
          </Box>
        </>
      )}
    </DbgDialog>
  );
};

export default NewContractModal;
