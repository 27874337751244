import { styled } from '@mui/material';

export const StyledButton = styled('button')``;
export const StyledImg = styled('img')``;
export const StyledSpan = styled('span')``;
export const StyledUl = styled('ul')``;
export const StyledLi = styled('li')``;
export const StyledA = styled('a')``;
export const StyledTable = styled('table')``;
export const StyledTD = styled('td')``;
export const StyledH2 = styled('h2')``;
export const StyledH5 = styled('h5')``;
export const StyledInput = styled('input')``;
