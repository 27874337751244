import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Permission } from '../../../models/enums';
import { RouteEnum } from '../../layout/PageRouter';
import { useApplicationContextState } from '../../../contexts/ApplicationContext';
import { SharedAdminStyles } from './styles/SharedAdminStyles';

export default function AdminPage(): JSX.Element {
  const navigate = useNavigate();
  const { user } = useApplicationContextState();

  const { styles } = SharedAdminStyles;

  useEffect(() => {
    if (!user) return;
    if (user.permissions.includes(Permission.UserManagementView)) {
      navigate(RouteEnum.AdminExternalAccounts);
    } else {
      navigate(RouteEnum.Dashboard);
    }
  }, [user]);

  return <Box sx={styles.pageBody}></Box>;
}
