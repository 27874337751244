import { dialogContentClasses, typographyClasses, paperClasses, dialogActionsClasses } from '@mui/material';

const styles = {
  dialogContent: {
    [`& .${dialogContentClasses.root}`]: {
      display: 'none',
    },
    [`& .${typographyClasses.h6}`]: {
      fontSize: '25px',
      marginLeft: '60px',
      marginRight: '60px',
      marginTop: '62px',
      lineHeight: '32px',
      borderBottom: 'none',
    },
    [`& .${paperClasses.root}`]: {
      width: '492px',
      minWidth: '492px',
      maxWidth: '492px',
      height: '290px',
      minHeight: '290px',
      maxHeight: '290px',
    },
    [`& .${dialogActionsClasses.root}`]: {
      borderTop: 'none',
      marginRight: '50px',
      marginLeft: '50px',
    },
  },
};

export const DialogSharedStyles = {
  styles,
};
