import { DataGridFilterField } from '@dierbergs-markets/react-component-library';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useEffectDebounced = function (effect: () => void, dependencies: any[], interval?: number) {
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const current = timeoutRef.current;
    if (current) clearTimeout(current);
    timeoutRef.current = setTimeout(effect, current ? interval || 500 : 0);
  }, dependencies);
};

const getValueByField = (path: string, obj: any): string | undefined => {
  // resolve will "resolve" an object's dot notation path, like "item.sku", in a nested object
  const properties: any[] = Array.isArray(path) ? path : path.split('.');
  return properties
    .reduce((prev, curr) => prev?.[curr], obj)
    ?.toString()
    .toLowerCase();
};

export const useDataGridFilters = function (gridId: string) {
  const [dataGridFilters, setDataGridFilters] = useState<Record<string, DataGridFilterField[]>>({});
  const filterCallback = useCallback(
    (q) => {
      for (const filter of dataGridFilters[gridId] ?? []) {
        if (filter.filter) {
          const field = filter.field;
          const resolvedValue = getValueByField(field, q);
          return resolvedValue ? resolvedValue.indexOf(filter.filter.toLowerCase()) > -1 : false;
        }
      }
      return true;
    },
    [dataGridFilters[gridId]]
  );
  return [filterCallback, (filters) => setDataGridFilters({ ...dataGridFilters, [gridId]: filters })];
};
