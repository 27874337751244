import React, { useState, useEffect } from 'react';

interface TimeAgoProps {
  createdAtUtc: string;
}

const TimeAgo: React.FC<TimeAgoProps> = ({ createdAtUtc }) => {
  const [timeAgo, setTimeAgo] = useState<string>('');

  useEffect(() => {
    const calculateTimeAgo = () => {
      const createdAt = new Date(createdAtUtc);
      const now = new Date();
      const timeDifference = now.getTime() - createdAt.getTime();
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (seconds < 60) {
        setTimeAgo(`${seconds} second${seconds === 1 ? '' : 's'} ago`);
      } else if (minutes < 60) {
        setTimeAgo(`${minutes} minute${minutes === 1 ? '' : 's'} ago`);
      } else if (hours < 24) {
        setTimeAgo(`${hours} hour${hours === 1 ? '' : 's'} ago`);
      } else {
        setTimeAgo(`${days} day${days === 1 ? '' : 's'} ago`);
      }
    };

    calculateTimeAgo();

    // Update time every minute for precision
    const interval = setInterval(() => {
      calculateTimeAgo();
    }, 60000);

    return () => clearInterval(interval);
  }, [createdAtUtc]);

  return <span>{timeAgo}</span>;
};

export default TimeAgo;
