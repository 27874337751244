import { IPricing } from '../../models';
import { fetchGetJson, HttpErrorResponse } from '../contractHubApi';

export const contractPricingService = {
  getPricing,
};

async function getPricing(contractId: number): Promise<IPricing[] | HttpErrorResponse> {
  const response = await fetchGetJson<IPricing[]>({ endpoint: `contract/${contractId}/pricing` });
  return response;
}
