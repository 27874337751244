import { textFieldClasses } from '@mui/material';
import { defaultBorders, defaultColors } from '../../../../styles/variables';

const styles = {
  pageBody: {
    width: '100%',
    paddingX: '5%',
    marginTop: '30px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  toolBar: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  row: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '5px',
    gap: '20px',
  },
  rowSpacing: {
    marginBottom: '20px',
  },
  cell: {
    minWidth: '100px', // for some reason, this forces the 50% width to work perfectly
    flexBasis: '0px',
    flexGrow: 1,
  },
  leftColumnFlexGrow: {
    flexGrow: 1.5,
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '5px',
  },
  addAccountBtn: {
    alignSelf: 'end',
    marginLeft: 'auto', // makes it right aligned
  },
  accountNameLabel: {
    display: 'flex',
    flex: 0.5,
    alignItems: 'center',
    fontSize: '20px',
    marginRight: '6px',
  },
  customerListWrapper: {
    flex: 0.5,
    textAlign: 'right',
  },
  findCustomers: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    autoComplete: {
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
    },
    autoCompleteInput: {
      width: '100%',
      [`& .${textFieldClasses.root}`]: {
        borderTopRightRadius: '0px !important',
        borderBottomRightRadius: '0px !important',
        height: '60px',
      },
    },
    addButton: {
      border: `1px solid ${defaultColors.borderAlt}`,
      borderTopLeftRadius: '0px !important',
      borderBottomLeftRadius: '0px !important',
      boxShadow: defaultBorders.boxShadow,
      borderLeft: '0px',
      padding: '10px 0px 10px 0px',
      borderRadius: '0px 6px 6px 0px',
      height: '60px',
      width: '50px',
      display: 'flex',
      justifyContent: 'center',
    },
    // broke this out to it's own class so it doesn't change colors when saving
    addButtonHover: {
      '&:hover': {
        backgroundColor: defaultColors.blue,
        cursor: 'pointer',
        '> button': {
          color: `${defaultColors.white} !important`,
        },
      },
    },
    addButtonLoading: {
      alignItems: 'center', // maybe move this to addButton?
    },
  },
};

export const SharedAdminStyles = {
  styles,
};
