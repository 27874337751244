import styled from '@emotion/styled';
import React from 'react';

interface IProps {}

const PageCenterContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
}));

export default function PageCenter(props: React.PropsWithChildren<IProps>) {
  return <PageCenterContainer>{props.children}</PageCenterContainer>;
}
