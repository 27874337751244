import { AuthenticationResult, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { defaultLoginRequest, b2cPolicies } from '../../../authConfig';
import { RouteEnum } from '../../layout/PageRouter';

export default function Redeem() {
  const location = useLocation();
  const { instance, inProgress, accounts } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        await instance.initialize();

        const response = await instance.handleRedirectPromise();
        response && handleResponse(response);
      } catch (err) {
        /* eslint-disable no-console */
        console.error('redeem error', err);

        navigate(RouteEnum.SignInFailure, { state: { message: 'Sign-in link is not valid. Please try signing in again.' } });
      }
    })();
  }, []);

  const handleResponse = useCallback((authResponse: AuthenticationResult) => {
    instance.setActiveAccount(authResponse.account);
    authResponse.state && navigate(decodeURIComponent(authResponse.state || RouteEnum.Dashboard));
  }, []);

  useEffect(() => {
    (async () => {
      if (inProgress === InteractionStatus.None) {
        const searchParams = new URLSearchParams(location.search);

        const id_token_hint = searchParams.get('id_token_hint');
        const targetRoute = searchParams.get('targetRoute') || RouteEnum.Dashboard;
        if (!id_token_hint) return;

        //f another account is currently signed in, log that user out first, then redirect back to this page to handle magic link login.
        if (accounts.length > 0) {
          const postLogoutUri = `${RouteEnum.Redeem}${location.search}`;
          await instance.logout({
            ...defaultLoginRequest,
            authority: b2cPolicies.authorities.magicLinkSignInPolicy.authority,
            postLogoutRedirectUri: postLogoutUri,
          });
        }

        const loginRequest: RedirectRequest = {
          ...defaultLoginRequest,
          authority: b2cPolicies.authorities.magicLinkSignInPolicy.authority,
          extraQueryParameters: { id_token_hint: id_token_hint },
          state: targetRoute && encodeURIComponent(targetRoute),
          redirectUri: RouteEnum.Redeem, //redirecting back to this route to execute handRedirectResponse and forward user to final destination
        };

        /* eslint-disable no-console */
        await instance.loginRedirect(loginRequest).catch((error) => console.log('Redeem loginRedirect error', error));
      }
    })();
  }, [inProgress, accounts]);

  return <div></div>;
}
